import React from "react";

const MySelect = ({
  name,
  label,
  options,
  color,
  returnsId,
  error,
  className,
  defaultFakeOption,
  ...rest
}) => {
  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <select
        name={name}
        id={name}
        {...rest}
        className={(color ? "text-" + color : "") + " " + className}
      >
        <option value="">{defaultFakeOption}</option>
        {options.map((option) => (
          <option
            key={option.id}
            value={returnsId ? option.id : option.name || option.item}
          >
            {option.name || option.item}
          </option>
        ))}
      </select>
      {error && <div className="alert-danger">{error}</div>}
    </div>
  );
};

export default MySelect;
