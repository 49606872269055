const apiUrl =
  process.env.NODE_ENV === "production"
    ? "https://dms.sysandgo.com/api"
    : // : "https://dms.sysandgo.com/api";
      "http://localhost:3001/api";
const wsUrl =
  process.env.NODE_ENV === "production"
    ? "wss://dms.sysandgo.com:8081"
    : // : "wss://dms.sysandgo.com:8081";
      "ws://localhost:8081";

module.exports.apiUrl = apiUrl;
module.exports.wsUrl = wsUrl;
