import {
  SELECTED_CHAT,
  SEARCH_USER,
  MSG_SUBMIT,
  MSG_RECEIVE,
  START_CHAT,
  REMOVE_CHAT,
  OFFLINE_CHAT,
} from "../constants/";

export const openChat = (id) => ({
  type: SELECTED_CHAT,
  id,
});

export const chatSearch = (searchTerm) => ({
  type: SEARCH_USER,
  searchTerm,
});

export const sendMsg = (id, chatMsg) => ({
  type: MSG_SUBMIT,
  id,
  chatMsg,
});

export const receiveMsg = (id, chatMsg) => ({
  type: MSG_RECEIVE,
  id,
  chatMsg,
});

export const startChat = (chatObject) => ({
  type: START_CHAT,
  chatObject,
});

export const removeChat = (id) => ({
  type: REMOVE_CHAT,
  id,
});

export const offlineChat = (id) => ({
  type: OFFLINE_CHAT,
  id,
});
