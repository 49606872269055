import React, { Component } from "react";
import loader from "../../utils/googleMapLoader";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

const mapStyles = {
  width: "100%",
  height: "100%",
};
let map;
class GoogleMap extends Component {
  componentDidMount() {
    const lng = parseFloat(this.props.dealership.websiteSettings.longitude);
    const lat = parseFloat(this.props.dealership.websiteSettings.latitude);
    loader.load().then(() => {
      map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat, lng },
        zoom: this.props.zoom || 15,
      });
      var marker = new window.google.maps.Marker({
        position: { lat, lng },
        title:
          this.props.dealershipName ||
          this.props.dealership.name ||
          "Dealership Location",
      });

      // To add the marker to the map, call setMap();
      marker.setMap(map);
    });
  }
  render() {
    return <div id="map" style={mapStyles}></div>;
  }
}

// export default GoogleMap;
export default connect(mapStateToProps)(GoogleMap);
