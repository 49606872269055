import http from "./httpService";
import { apiUrl } from "../config.js";
const apiEndpoint = apiUrl + "/listItems";

function theUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getListItems() {
  return http.get(apiEndpoint);
}

export function saveListItem(listItem) {
  if (listItem) return http.post(apiEndpoint, listItem);
}

export function updateListItem(listItem) {
  if (listItem) return http.put(apiEndpoint, listItem);
}

export function deleteListItem(listItemId) {
  if (listItemId) return http.delete(theUrl(listItemId));
}

export function getListItemById(listItemId) {
  if (listItemId) return http.get(theUrl(listItemId));
}

export function getListItemByName(listItemName) {
  if (listItemName) return http.get(apiEndpoint + "/names/" + listItemName);
}
