import http from "./httpService";
import { apiUrl } from "../config.js";
const apiEndpoint = apiUrl + "/websiteMessages";

export function saveWebsiteMessage(message) {
  return http.post(apiEndpoint, message);
}

export function saveFinanceApplication(data) {
  return http.post(apiEndpoint + "/financeApplication", data);
}
