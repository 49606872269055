import http from "./httpService";
import { apiUrl } from "../config.js";
const apiEndpoint = apiUrl + "/rentalCalendars/";

export function getRentalCalendarEvents(vehicleId) {
  return http.get(apiEndpoint + "vehicle/" + vehicleId);
}

export function getRentalCalendarEventById(id) {
  return http.get(apiEndpoint + id);
}

export function saveRentalCalendarEvent(data) {
  return http.post(apiEndpoint, data);
}
