import React from "react";
import { Input, Row, Col } from "reactstrap";

const MyInput = ({
  name,
  label,
  error,
  labelPosition,
  className,
  type,
  autoComplete,
  ...rest
}) => {
  return labelPosition === "left" ? (
    <div className="form-group">
      <Row>
        <Col xs="4">
          <label htmlFor={name}>{label}</label>
        </Col>
        <Col xs="8">
          <Input
            {...rest}
            name={name}
            id={name}
            className={"form-control " + className}
            type={type}
            autoComplete={
              autoComplete || (type === "password" ? "new-password" : "nope")
            }
          />
          {error && <div className="alert-danger">{error}</div>}
        </Col>
      </Row>
    </div>
  ) : (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Input
        {...rest}
        name={name}
        id={name}
        className={"form-control " + className}
        type={type}
        autoComplete={
          autoComplete || (type === "password" ? "new-password" : "nope")
        }
      />
      {error && <div className="pt-2 pb-1 pl-2 alert-danger">{error}</div>}
    </div>
  );
};

export default MyInput;
