import React, { Component } from "react";
import { Form, Input, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { sendMessage, closeChat } from "../../services/chatService";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import MyModal from "../common/modal";

class ChatMsgForm extends Component {
  state = {
    msg: "",
    modalOpen: false,
  };
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };
  handleChatMsgChange = (e) => {
    this.setState({
      msg: e.target.value,
    });
  };

  // handleCloseChat = () => {
  //   console.log("this.props.chatDetails:", this.props.chatDetails);
  //   closeChat(this.props.id, this.props.chatDetails);
  // };

  msgSubmit = () => {
    if (this.state.msg) {
      this.props.onClick(this.props.id, this.state.msg);
      sendMessage(this.props.id, this.state.msg);
      this.setState({ msg: "" });
    }
  };

  onChatMsgSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.msgSubmit();
  };

  render() {
    return (
      <div>
        <MyModal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          className="emoji-modal"
          component={Picker}
          props={{
            set: "apple",
            perLine: 12,
            onSelect: (emoji) => {
              this.setState({ msg: this.state.msg + emoji.native });
              this.toggleModal();
            },
          }}
        ></MyModal>

        <Form onSubmit={this.onChatMsgSubmit} className="card-body border-top">
          {/* <Modal
            isOpen={this.state.modalOpen} //{this.props.modalState02}
            toggle={this.toggleModal}
            size="md"
            className="emoji-modal"
          >
            <ModalHeader toggle={this.toggleModal}>Emoji</ModalHeader>
            <ModalBody>
              <Picker
                set="apple"
                perLine={12}
                onSelect={(emoji) => {
                  this.setState({ msg: this.state.msg + emoji.native });
                  this.toggleModal();
                }}
              />
            </ModalBody>
          </Modal> */}
          <div className="d-flex">
            <Input
              type="text"
              className="form-control mr-2"
              placeholder="Type your message"
              onChange={this.handleChatMsgChange}
              value={this.state.msg}
              disabled={
                this.props.chatDetails &&
                this.props.chatDetails.status === "offline"
              }
            />
            <button
              onClick={this.msgSubmit}
              // className="btn btn-outline-secondary"
              style={{
                padding: "0px 11px 0px 11px",
                marginBottom: 5,
                backgroundColor: "#0580FF",
              }}
              type="button"
              disabled={
                !this.state.msg ||
                (this.props.chatDetails &&
                  this.props.chatDetails.status === "offline")
              }
            >
              <i className="fas fa-paper-plane" />
            </button>
            <button
              onClick={this.toggleModal}
              // className="btn btn-outline-warning ml-1"
              style={{
                padding: "0px 10px 0px 10px",
                marginBottom: 5,
                marginLeft: 4,
                backgroundColor: "orange",
              }}
              type="button"
              disabled={
                this.props.chatDetails &&
                this.props.chatDetails.status === "offline"
              }
            >
              <i className="far fa-smile" />
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

ChatMsgForm.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ChatMsgForm;
