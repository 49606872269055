import React from "react";
import { Input, Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";

const InputNumber = ({
  name,
  label,
  error,
  prefix,
  labelPosition,
  type,
  color,
  ...rest
}) => {
  return labelPosition === "left" ? (
    <div className="form-group">
      <Row>
        <Col xs="7" className="text-right p-0">
          <label htmlFor={name} className="pt-2">
            {label}
          </label>
        </Col>
        <Col xs="5">
          <NumberFormat
            customInput={Input}
            thousandSeparator={type === "text" ? true : false}
            prefix={prefix}
            allowLeadingZeros={true}
            isNumericString={false}
            name={name}
            id={name}
            className={
              "form-control text-monospace pl-1 pr-1 " + color
                ? color
                : "text-black-50"
            }
            format={type === "tel" ? "(###) ###-####" : null}
            mask={type === "tel" ? "_" : null}
            {...rest}
          />
          {error && <div className="alert-danger">{error}</div>}
        </Col>
      </Row>
    </div>
  ) : (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <NumberFormat
        customInput={Input}
        thousandSeparator={type === "text" ? true : false}
        prefix={prefix}
        isNumericString={false}
        allowLeadingZeros={true}
        name={name}
        id={name}
        className="form-control text-monospace text-black-50"
        format={type === "tel" ? "(###) ###-####" : null}
        mask={type === "tel" ? "_" : null}
        {...rest}
      />
      {error && <div className="alert-danger">{error}</div>}
    </div>
  );
};

export default InputNumber;
