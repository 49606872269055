import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import {
//   ToastsContainer,
//   ToastsStore,
//   ToastsContainerPosition,
// } from "react-toasts";
import { useToasts } from "react-toast-notifications";

const ButtonWithConfirm = (props) => {
  const {
    label,
    type,
    color,
    size,
    buttonClassName,
    block,
    modalClassName,
    messageBody,
    handleYes,
    disabled,
    dataToggle,
    title,
  } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const { addToast } = useToasts();

  return (
    // <div>
    <React.Fragment>
      <Button
        disabled={disabled}
        // type={type === "cancel" || type === "submit" ? "button" : type}
        type="button"
        color={color}
        size={size}
        className={buttonClassName}
        block={block}
        onClick={toggle}
        data-toggle={dataToggle}
        title={title}
      >
        {label}
      </Button>
      {/* <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      /> */}
      <Modal isOpen={modal} toggle={toggle} className={modalClassName}>
        <ModalHeader toggle={toggle}>Please, confirm ...</ModalHeader>
        <ModalBody>{messageBody}</ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={async () => {
              toggle();
              if (handleYes) {
                const h = await handleYes();
                // //console.log("---------- h ---------> ", h);
                if (h) {
                  if (type === "submit")
                    // ToastsStore.info("Record saved successfully.", 5000);
                    addToast(
                      <h5 className="pt-2 pb-1">Record saved successfully</h5>,
                      {
                        appearance: "info",
                        autoDismiss: true,
                      }
                    );
                  else if (type === "delete")
                    // ToastsStore.info("Record deleted successfully.", 5000);
                    addToast(
                      <h5 className="pt-2 pb-1">
                        Record deleted successfully
                      </h5>,
                      {
                        appearance: "info",
                        autoDismiss: true,
                      }
                    );
                  // } else ToastsStore.error("Operation can not be done.", 5000);
                } else
                  addToast(
                    <h5 className="pt-2 pb-1">Operation can not be done</h5>,
                    {
                      appearance: "error",
                      autoDismiss: true,
                    }
                  );
              }
            }}
          >
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
    // </div>
  );
};

export default ButtonWithConfirm;
