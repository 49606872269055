import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import myForm from "../common/form";
import { getModels, getModelsAsListItems } from "../../services/modelService";
import { getBodyTypes } from "../../services/vehicleService";
import { getMakesAsListItems } from "../../services/makeService";
import { setFilteredVehicles } from "../../redux/vehicles/action";

const mapStateToProps = (state) => ({
  ...state,
  vehicles: state.vehiclesReducer.vehicles,
});

const mapDispatchToProps = (dispatch) => ({
  setFilteredVehicles: (vehicles) => dispatch(setFilteredVehicles(vehicles)),
});

class BriefSearch extends myForm {
  state = {
    errors: this.props.errors || {},
    loading: false,
    filteredVehicles: [],
    data: {
      makeName: null,
      modelName: null,
      bodyType: null,
      minYear: null,
      maxYear: null,
      minPrice: null,
      maxPrice: null,
      minMileage: null,
      maxMileage: null,
    },
    lists: {
      makes: [],
      models: [],
      // vehicles: [],
      bodyTypes: [],
      // years: [
      //   { id: 1, name: "Before 1990" },
      //   { id: 2, name: "1990-1995" },
      //   { id: 3, name: "1995-2000" },
      //   { id: 4, name: "2000-2005" },
      //   { id: 5, name: "2005-2010" },
      //   { id: 6, name: "2010-2015" },
      //   { id: 7, name: "After 2015" },
      // ],
    },
  };

  doSubmit = async () => {
    const filteredVehicles = this.props.vehicles.filter((vehicle) => {
      const {
        makeName,
        modelName,
        bodyType,
        minYear,
        maxYear,
        minPrice,
        maxPrice,
        minMileage,
        maxMileage,
      } = this.state.data;
      return (
        (makeName ? vehicle.makeName === makeName : true) &&
        (modelName ? vehicle.modelName === modelName : true) &&
        (bodyType ? vehicle.bodyType === bodyType : true) &&
        (minYear ? vehicle.productionYear >= minYear : true) &&
        (maxYear ? vehicle.productionYear <= maxYear : true) &&
        (minPrice ? vehicle.askingPrice >= minPrice : true) &&
        (maxPrice ? vehicle.askingPrice <= maxPrice : true) &&
        (minMileage ? vehicle.mileage >= minMileage : true) &&
        (maxMileage ? vehicle.mileage <= maxMileage : true)
      );
    });
    if (filteredVehicles && filteredVehicles.length > 0) {
      // localStorage.setItem("vehicles", JSON.stringify(searchedVehicles));
      // filteredVehicles.splice(0, filteredVehicles.length);
      // searchedVehicles.forEach((s) => filteredVehicles.push(s));
      this.setState({ filteredVehicles });
      this.props.setFilteredVehicles(filteredVehicles);
      console.log(filteredVehicles);
      // filteredVehicles = [...searchedVehicles];
      // window.location.href = "inventory";
      // <Redirect to="/somewhere/else" />
    }
  };
  // this.setState({ lists: { ...this.state.lists, vehicles: data } });

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      // this.initialData = { ...this.state.data };
      // await login("guest", window.location.href);
      const { data: makes } = await getMakesAsListItems();
      const { data: models } = this.state.data.makeName
        ? await getModelsAsListItems(this.state.data.makeName)
        : { data: [] };
      const { data: bodyTypes } = await getBodyTypes();
      // const { data: vehicles } = await getVehicles({});
      // const vehicles = [...inventory];
      const lists = {
        makes,
        models,
        bodyTypes,
        // vehicles,
      };
      this.setState({
        lists: { ...this.state.lists, ...lists },
        loading: false,
      });
      this.setState({});
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.serverError = ex.response.data;
        this.setState({ errors });
      }
      console.error(ex);
      const generalError = ex.response
        ? ex.response.status + " : " + ex.response.data
        : ex.name + " : " + ex.message;
      this.setState({ generalError });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.data.makeName !== this.state.data.makeName)
      try {
        const { data } = await getModels(this.state.data.makeName);
        this.setState({
          lists: { ...this.state.lists, models: [...data] },
        });
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.serverError = ex.response.data;
          this.setState({ errors });
        }
        console.error(ex);
        const generalError = ex.response
          ? ex.response.status + " : " + ex.response.data
          : ex.name + " : " + ex.message;
        this.setState({ generalError });
      }
  }

  render() {
    // console.log("vehicles:", this.state);
    return (
      <div className="search-form margin-top-20">
        <div>
          {this.state.generalError && (
            <Row>
              <Col xs="12">
                <div className="mt-3 text-danger">
                  {this.state.generalError}
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-2">
            <Col xs="12" sm="6">
              {this.renderSelect(
                "makeName",
                "",
                this.state.lists.makes,
                "Any Make"
              )}
            </Col>
            <Col xs="12" sm="6">
              {this.renderSelect(
                "modelName",
                "",
                this.state.lists.models,
                "Any Model"
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: "20px !important" }}>
            <Col xs="12" sm="6">
              {this.renderSelect(
                "bodyType",
                "",
                this.state.lists.bodyTypes,
                "Body Style"
              )}
            </Col>
            <Col xs="12" sm="6">
              <Row className="m-0">
                <Col xs="6" className="p-0">
                  <select
                    value={
                      this.state.data.minPrice === null
                        ? ""
                        : this.state.data.minPrice
                    }
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          minPrice:
                            e.currentTarget.value &&
                            parseInt(e.currentTarget.value),
                        },
                      });
                    }}
                  >
                    <option value="">Min Price</option>
                    {(() => {
                      const list = [];
                      for (let i = 0; i <= 20000; i += 1000)
                        list.push(<option>{i}</option>);
                      return list;
                    })()}
                  </select>
                </Col>
                <Col xs="6" className="p-0">
                  <select
                    value={
                      this.state.data.maxPrice === null
                        ? ""
                        : this.state.data.maxPrice
                    }
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          maxPrice: e.currentTarget.value,
                        },
                      });
                    }}
                  >
                    <option value="">Max Price</option>
                    {(() => {
                      const list = [];
                      if (this.state.data.minPrice)
                        for (
                          let i = this.state.data.minPrice;
                          i <= 20000;
                          i += 1000
                        )
                          list.push(<option>{i}</option>);
                      else
                        for (let i = 0; i <= 20000; i += 1000)
                          list.push(<option>{i}</option>);
                      return list;
                    })()}
                  </select>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col xs="12" sm="6">
              <Row className="m-0">
                <Col xs="6" className="p-0">
                  <select
                    value={
                      this.state.data.minYear === null
                        ? ""
                        : this.state.data.minYear
                    }
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          minYear: e.currentTarget.value,
                        },
                      });
                    }}
                  >
                    <option value="">Min Year</option>
                    {(() => {
                      const year = new Date().getFullYear();
                      const yearsList = [];
                      for (let i = year; i >= 1980; i--)
                        yearsList.push(<option>{i}</option>);
                      return yearsList;
                    })()}
                  </select>
                </Col>
                <Col xs="6" className="p-0">
                  <select
                    value={
                      this.state.data.maxYear === null
                        ? ""
                        : this.state.data.maxYear
                    }
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          maxYear: e.currentTarget.value,
                        },
                      });
                    }}
                  >
                    <option value="">Max Year</option>
                    {(() => {
                      const year = new Date().getFullYear();
                      const yearsList = [];
                      if (this.state.data.minYear)
                        for (let i = year; i >= this.state.data.minYear; i--)
                          yearsList.push(<option>{i}</option>);
                      else
                        for (let i = year; i >= 1980; i--)
                          yearsList.push(<option>{i}</option>);
                      return yearsList;
                    })()}
                  </select>
                </Col>
              </Row>
            </Col>
            <Col xs="12" sm="6">
              <Row className="m-0">
                <Col xs="6" className="p-0">
                  <select
                    value={
                      this.state.data.minMileage === null
                        ? ""
                        : this.state.data.minMileage
                    }
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          minMileage:
                            e.currentTarget.value &&
                            parseInt(e.currentTarget.value),
                        },
                      });
                    }}
                  >
                    <option value="">Min kms</option>
                    {(() => {
                      const list = [];
                      for (let i = 0; i <= 200000; i += 10000)
                        list.push(<option>{i}</option>);
                      return list;
                    })()}
                  </select>
                </Col>
                <Col xs="6" className="p-0">
                  <select
                    value={
                      this.state.data.maxMileage === null
                        ? ""
                        : this.state.data.maxMileage
                    }
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          maxMileage: e.currentTarget.value,
                        },
                      });
                    }}
                  >
                    <option value="">Max kms</option>
                    {(() => {
                      const list = [];
                      if (this.state.data.minMileage)
                        for (
                          let i = this.state.data.minMileage;
                          i <= 200000;
                          i += 10000
                        )
                          list.push(<option>{i}</option>);
                      else
                        for (let i = 0; i <= 200000; i += 10000)
                          list.push(<option>{i}</option>);
                      return list;
                    })()}
                  </select>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-2">
            {/* <Col xs="6">
            </Col> */}
            <Col xs="12">
              <Link to="/inventory" onClick={this.doSubmit}>
                <button style={{ cursor: "pointer" }} className="btn-block">
                  Find My New Vehicle
                </button>
              </Link>
            </Col>
          </div>
          {/* <SearchResultDisplay data={this.state.lists.vehicles} /> */}
          {/* </FormGroup> */}
        </div>
      </div>
    );
  }
}

// export default BriefSearch;
export default connect(mapStateToProps, mapDispatchToProps)(BriefSearch);
