import React from "react";
import Joi from "joi";
import _ from "lodash";
import { CardBody, Row, Col, Form } from "reactstrap";
import myForm from "../../components/common/form";
import { saveWebsiteMessage } from "../../services/websiteMessageService";

class SendMessage extends myForm {
  state = {
    data: {
      name: null,
      phoneNumber: null,
      email: null,
      message: null,
    },
    status: "",
    generalError: "",
    loading: false,
    errors: "",
  };
  schemaObj = {
    name: Joi.string().required().label("Name"),
    message: Joi.string().required().label("Message"),
    phoneNumber: Joi.string().allow(null).allow("").label("Phone Number"),
    email: Joi.string()
      .email({ tlds: false })
      .allow(null)
      .allow("")
      .label("Email"),
  };
  schema = Joi.object(this.schemaObj).options({ stripUnknown: true });

  doSubmit = async () => {
    try {
      if (!this.state.data.email && !this.state.data.phoneNumber)
        throw new Error("Either email address or phone number is required.");
      this.setState({ loading: true });
      await saveWebsiteMessage(this.state.data);
      this.setState({
        status: "Message sent successfully.",
        loading: false,
        data: {
          name: "",
          email: "",
          message: "",
          phoneNumber: "",
        },
      });
    } catch (err) {
      this.setState({ generalError: err.message });
    }
  };

  render() {
    return (
      // <Card body outline color={"info"} className="border">
      // <Card body outline className="border">
      <div className="form_contact">
        {this.state.generalError && (
          <Row>
            <Col xs="12">
              <div className="mt-3 text-danger">{this.state.generalError}</div>
            </Col>
          </Row>
        )}
        {/* <h4 className="mb-4">Chat with a salesperson</h4> */}
        <Row>
          <Col md="12">
            {/* <div className="form-group"> */}
            {this.renderInput("name", "", "text", false, null, null, "Name")}
            {this.renderInputNumber(
              "phoneNumber",
              "",
              "tel",
              false,
              null,
              null,
              "Phone Number"
            )}
            {this.renderInput(
              "email",
              "",
              "text",
              false,
              null,
              null,
              "Email",
              null,
              null
            )}
            {this.renderInput(
              "message",
              "",
              "textarea",
              false,
              null,
              null,
              "Message",
              null,
              null,
              null,
              null,
              5
            )}
            {/* <button
              style={{ cursor: "pointer" }}
              className="md-button"
              onClick={this.doSubmit}
            >
              Send
            </button> */}
            {this.renderButton(
              this.state.loading ? "Sending..." : "Send",
              "button",
              null,
              null,
              "theme-bg-color",
              // null,
              false,
              this.handleSubmit,
              this.state.loading
            )}
            <span className="ml-3">{this.state.status}</span>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SendMessage;
// export default connect(mapStateToProps, mapDispatchToProps)(SendMessage);
