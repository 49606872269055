import { saveWebsiteVisit } from "../../services/vehicleService";
import {
  SET_VEHICLES,
  SET_FILTERED_VEHICLES,
  SET_SELECTED_VEHICLE,
  SET_SHOWING_VEHICLES,
} from "../constants/";

export const setVehicles = (vehicles) => ({
  type: SET_VEHICLES,
  vehicles,
});

export const setFilteredVehicles = (vehicles) => ({
  type: SET_FILTERED_VEHICLES,
  vehicles,
});

export const setShowingVehicles = (vehicles) => ({
  type: SET_SHOWING_VEHICLES,
  vehicles,
});

export const selectedVehicle = (vehicle) => ({
  type: SET_SELECTED_VEHICLE,
  vehicle,
});

export const setSelectedVehicle = (vehicle) => {
  return function (dispatch) {
    return saveWebsiteVisit(vehicle).then(() => {
      dispatch(selectedVehicle(vehicle));
    });
  };
};

// export const chatSearch = (searchTerm) => ({
//   type: SEARCH_USER,
//   searchTerm,
// });

// export const sendMsg = (id, chatMsg) => ({
//   type: MSG_SUBMIT,
//   id,
//   chatMsg,
// });

// export const receiveMsg = (id, chatMsg) => ({
//   type: MSG_RECEIVE,
//   id,
//   chatMsg,
// });

// export const startChat = (chatObject) => ({
//   type: START_CHAT,
//   chatObject,
// });

// export const removeChat = (id) => ({
//   type: REMOVE_CHAT,
//   id,
// });

// export const offlineChat = (id) => ({
//   type: OFFLINE_CHAT,
//   id,
// });
