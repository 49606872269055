import React from "react";
import { connect } from "react-redux";
import { saveWebsiteMessage } from "../../../services/websiteMessageService";
import _ from "lodash";
import { colorObjToString } from "../../../utils/utils";
import SendMessage from "../../../components/customer/sendMessage";

const mapStateToProps = (state) => ({
  ...state,
  dealership: state.settings.dealership,
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dealership: null,
      name: "",
      email: "",
      message: "",
      status: "",
    };
    this.doSubmit = this.doSubmit.bind(this);
  }

  async doSubmit() {
    try {
      await saveWebsiteMessage(
        _.pick(this.state, ["name", "email", "message"])
      );
      this.setState({
        status: "Message sent successfully.",
        name: "",
        email: "",
        message: "",
      });
    } catch (err) {
      this.setState({ status: err.message });
    }
  }

  render() {
    // console.log(this.state);
    // if (!this.props.dealership) return null;
    const {
      name,
      address,
      city,
      province,
      country,
      postalCode,
      phoneNumber1,
      url,
      email,
      workingHours,
      websiteSettings,
      facebookUrl,
      instagramUrl,
    } = this.props.dealership;
    switch (websiteSettings ? websiteSettings.footerNumber : 0) {
      case 1:
        return (
          <div>
            <footer
              className="design_2"
              style={{
                backgroundColor: colorObjToString(
                  websiteSettings.navbarBgColor
                ),
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding-left-none md-padding-left-none sm-padding-left-15 xs-padding-left-15">
                    <h4>Get in Touch</h4>
                    <SendMessage />
                    {/* <div className="form_contact">
                      <input
                        type="text"
                        value={this.state.name}
                        name="name"
                        placeholder="Name"
                        onChange={(e) => {
                          this.setState({ name: e.currentTarget.value });
                        }}
                      />
                      <input
                        type="text"
                        value={this.state.email}
                        name="email"
                        placeholder="Email"
                        onChange={(e) => {
                          this.setState({ email: e.currentTarget.value });
                        }}
                      />
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={this.state.message}
                        onChange={(e) => {
                          this.setState({ message: e.currentTarget.value });
                        }}
                      ></textarea>
                      <button
                        style={{ cursor: "pointer" }}
                        className="md-button"
                        onClick={this.doSubmit}
                      >
                        Send
                      </button>
                      <span className="ml-3">{this.state.status}</span>
                    </div> */}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 padding-right-none md-padding-right-none sm-padding-right-15 xs-padding-right-15">
                    <h4>Contact us</h4>
                    <div className="footer-contact">
                      <ul>
                        <li>
                          <i className="fa fa-map-marker"></i>
                          <strong>Address:</strong>{" "}
                          {address +
                            ", " +
                            city +
                            ", " +
                            province +
                            " " +
                            postalCode}
                        </li>
                        <li>
                          <i className="fa fa-phone"></i>
                          <strong>Phone:</strong>
                          {phoneNumber1}
                        </li>
                        <li>
                          <i className="fa fa-envelope-o"></i>{" "}
                          <strong>Email:</strong>
                          {/* <a href="#">sales@company.com</a> */}
                          {email}
                        </li>
                      </ul>

                      <i className="fa fa-location-arrow back_icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div className="clearfix"></div>
            <section
              className="copyright-wrap"
              style={{
                backgroundColor: colorObjToString(
                  websiteSettings.copyrightBgColor
                ),
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="pb-0 logo-footer margin-bottom-40 md-margin-bottom-40 sm-margin-bottom-10 xs-margin-bottom-20">
                      <span>Copyright &copy; 2021</span>{" "}
                      <a
                        href="https://www.sysandgo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SYS&GO
                      </a>{" "}
                      <a
                        href="https://www.sysandgo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Dealer Management System
                      </a>
                      <span>. All rights reserved.</span>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <ul className="social margin-bottom-25 md-margin-bottom-25 sm-margin-bottom-20 xs-margin-bottom-20 xs-padding-top-10 clearfix">
                      <li>
                        <a
                          className="sc-1"
                          target="_blank"
                          href={facebookUrl || "#"}
                          rel="noopener noreferrer"
                        ></a>
                      </li>
                      <li>
                        <a
                          className="sc-2"
                          target="_blank"
                          href={instagramUrl || "#"}
                          rel="noopener noreferrer"
                        ></a>
                      </li>
                      <li>
                        <a className="sc-3" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-4" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-5" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-6" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-7" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-8" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-9" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-10" href="#"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      case 2:
        return (
          <div>
            <footer>
              <div className="container">
                <div className="row">
                  <div className="car-rate-block row clearfix margin-top-30 padding-bottom-40">
                    <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none padding-left-none scroll_effect bounceInLeft">
                      <div className="small-block clearfix">
                        <h4 className="margin-bottom-25 margin-top-none">
                          Financing.
                        </h4>
                        <a href="#">
                          <span className="align-center">
                            <i className="fa fa-tag fa-7x"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none scroll_effect bounceInLeft"
                      data-wow-delay=".2s"
                    >
                      <div className="small-block clearfix">
                        <h4 className="margin-bottom-25 margin-top-none">
                          Warranty.
                        </h4>
                        <a href="#">
                          <span className="align-center">
                            <i className="fa fa-cogs fa-7x"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 padding-left-none padding-right-none hours_operation">
                      <div className="small-block clearfix">
                        <h4 className="margin-bottom-25 margin-top-none">
                          What are our Hours of Operation?
                        </h4>
                        <div className="row">
                          <div
                            className="col-lg-6 col-md-12 col-sm-12 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-40 md-margin-bottom-none scroll_effect bounceInUp"
                            data-wow-delay=".4s"
                          >
                            <table className="table table-bordered no-border font-13px margin-bottom-none">
                              <thead>
                                <tr>
                                  <td colspan="2">
                                    <strong>Sales Department</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Mon:</td>
                                  <td>8:00am - 5:00pm</td>
                                </tr>
                                <tr>
                                  <td>Tue:</td>
                                  <td>8:00am - 9:00pm</td>
                                </tr>
                                <tr>
                                  <td>Wed:</td>
                                  <td>8:00am - 5:00pm</td>
                                </tr>
                                <tr>
                                  <td>Thu:</td>
                                  <td>8:00am - 9:00pm</td>
                                </tr>
                                <tr>
                                  <td>Fri:</td>
                                  <td>8:00am - 6:00pm</td>
                                </tr>
                                <tr>
                                  <td>Sat:</td>
                                  <td>9:00am - 5:00pm</td>
                                </tr>
                                <tr>
                                  <td>Sun:</td>
                                  <td>Closed</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            className="col-lg-6 col-md-12 col-sm-12 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none scroll_effect bounceInUp"
                            data-wow-delay=".4s"
                          >
                            <table className="table table-bordered no-border font-13px margin-bottom-none">
                              <thead>
                                <tr>
                                  <td colspan="2">
                                    <strong>Service Department</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Mon:</td>
                                  <td>8:00am - 5:00pm</td>
                                </tr>
                                <tr>
                                  <td>Tue:</td>
                                  <td>8:00am - 9:00pm</td>
                                </tr>
                                <tr>
                                  <td>Wed:</td>
                                  <td>8:00am - 5:00pm</td>
                                </tr>
                                <tr>
                                  <td>Thu:</td>
                                  <td>8:00am - 9:00pm</td>
                                </tr>
                                <tr>
                                  <td>Fri:</td>
                                  <td>8:00am - 6:00pm</td>
                                </tr>
                                <tr>
                                  <td>Sat:</td>
                                  <td>9:00am - 5:00pm</td>
                                </tr>
                                <tr>
                                  <td>Sun:</td>
                                  <td>Closed</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none scroll_effect bounceInRight"
                      data-wow-delay=".2s"
                    >
                      <div className="small-block clearfix">
                        <h4 className="margin-bottom-25 margin-top-none">
                          About Us.
                        </h4>
                        <a href="#">
                          <span className="align-center">
                            <i className="fa fa-users fa-7x"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-40 sm-margin-bottom-none padding-right-none scroll_effect bounceInRight">
                      <div className="small-block clearfix">
                        <h4 className="margin-bottom-25 margin-top-none">
                          Find Us.
                        </h4>
                        <a href="#">
                          <span className="align-center">
                            <i className="fa fa-map-marker fa-7x"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div className="clearfix"></div>
            <section className="copyright-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="logo-footer margin-bottom-40 md-margin-bottom-40 sm-margin-bottom-10 xs-margin-bottom-20"></div>
                    <span>Copyright &copy; 2021</span>
                    <a href="https://www.sysandgo.com">SYS&GO</a>
                    <span>. All rights reserved.</span>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <ul className="social margin-bottom-25 md-margin-bottom-25 sm-margin-bottom-20 xs-margin-bottom-20 xs-padding-top-10 clearfix">
                      <li>
                        <a className="sc-1" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-2" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-3" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-4" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-5" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-6" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-7" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-8" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-9" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-10" href="#"></a>
                      </li>
                    </ul>
                    <ul className="f-nav">
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>
                        <a href="#">Pages</a>
                      </li>
                      <li>
                        <a href="demo.html">Inventory</a>
                      </li>
                      <li>
                        <a href="services.html">Services</a>
                      </li>
                      <li>
                        <a href="our-team.html">Our Team</a>
                      </li>
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="contact.html">Location</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      case 3:
        return (
          <div>
            <footer>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>About us</h4>
                    <img src="images/ceo.jpg" alt="" />
                    <p>
                      Sed ut perspiciatis unde om nis iste natus error sit volup
                      atem accusantium dolor que laudantium. Totam reillo
                      inventore veritatis et quasi architecto beatae vitae dicta
                      sunt explicabo. Nemo en im ipsam voluptatem quia voluptas
                      sit asper natur aut odit aut fugit.
                    </p>
                    <a href="#">
                      <em>Harold Ford, President / CEO</em>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Contact us</h4>
                    <div className="footer-contact">
                      <ul>
                        <li>
                          <strong>
                            <i className="fa fa-phone"></i>Phone:
                          </strong>
                          <span>1-800-123-4567</span>
                        </li>
                        <li>
                          <strong>
                            <i className="fa fa-map-marker"></i>Address:
                          </strong>
                          <span>
                            Company Name
                            <br />
                            1234 Street Name
                            <br />
                            City Name, AB 12345
                            <br />
                            United States
                          </span>
                        </li>
                        <li>
                          <strong>
                            <i className="fa fa-envelope-o"></i>Email:
                          </strong>
                          <span>
                            <a href="#">sales@company.com</a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Latest tweets</h4>
                    <div className="latest-tweet">
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>newsletter</h4>
                    <p>
                      By subscribing to our company newsletter you will always
                      be up-to-date on our latest promotions, deals and vehicle
                      inventory!
                    </p>
                    <form
                      method="post"
                      action="http://themesuite.us5.list-manage.com/subscribe/post"
                      className="form_contact"
                    >
                      <input
                        type="text"
                        value=""
                        name="MERGE0"
                        placeholder="Email Address"
                      />
                      <input
                        type="submit"
                        value="Subscribe"
                        className="md-button"
                      />
                      <input
                        type="hidden"
                        name="u"
                        value="5845bd11ac7043069150fb046"
                      />
                      <input type="hidden" name="id" value="1efe59e47d" />
                    </form>
                  </div>
                </div>
              </div>
            </footer>

            <div className="clearfix"></div>
            <section className="copyright-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="logo-footer margin-bottom-40 md-margin-bottom-40 sm-margin-bottom-10 xs-margin-bottom-20">
                      <span>Copyright &copy; 2021</span>
                      <a href="https://www.sysandgo.com">SYS&GO</a>
                      <span>. All rights reserved.</span>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <ul className="social margin-bottom-25 md-margin-bottom-25 sm-margin-bottom-20 xs-margin-bottom-20 xs-padding-top-10 clearfix">
                      <li>
                        <a className="sc-1" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-2" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-3" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-4" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-5" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-6" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-7" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-8" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-9" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-10" href="#"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      case 4:
        return (
          <div>
            <footer>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Recent Vehicles</h4>

                    <div className="car-block recent_car">
                      <div className="img-flex">
                        <a href="inventory-listing.html">
                          <span className="align-center">
                            <i className="fa fa-2x fa-plus-square-o"></i>
                          </span>
                        </a>
                        <img
                          src="images/c-car1.jpg"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="car-block-bottom">
                        <h6>
                          <strong>2012 Porsche Cayenne GTS</strong>
                        </h6>
                        <h6>1 Owner, 26,273 miles</h6>
                        <h5>$102,995</h5>
                      </div>
                    </div>

                    <div className="car-block recent_car">
                      <div className="img-flex">
                        <a href="inventory-listing.html">
                          <span className="align-center">
                            <i className="fa fa-2x fa-plus-square-o"></i>
                          </span>
                        </a>
                        <img
                          src="images/c-car2.jpg"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="car-block-bottom">
                        <h6>
                          <strong>2009 Porsche Boxster</strong>
                        </h6>
                        <h6>New Tires, 26,273 miles</h6>
                        <h5>$34,995</h5>
                      </div>
                    </div>

                    <div className="car-block recent_car">
                      <div className="img-flex">
                        <a href="inventory-listing.html">
                          <span className="align-center">
                            <i className="fa fa-2x fa-plus-square-o"></i>
                          </span>
                        </a>
                        <img
                          src="images/c-car3.jpg"
                          alt=""
                          className="img-responsive"
                        />
                      </div>
                      <div className="car-block-bottom">
                        <h6>
                          <strong>2019 Porsche Panamera S</strong>
                        </h6>
                        <h6>Demonstrator, 7,088 miles</h6>
                        <h5>$63,995</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Contact us</h4>
                    <div className="footer-contact">
                      <ul>
                        <li>
                          <strong>
                            <i className="fa fa-phone"></i>Phone:
                          </strong>
                          <span>1-800-123-4567</span>
                        </li>
                        <li>
                          <strong>
                            <i className="fa fa-map-marker"></i>Address:
                          </strong>
                          <span>
                            Company Name
                            <br />
                            1234 Street Name
                            <br />
                            City Name, AB 12345
                            <br />
                            United States
                          </span>
                        </li>
                        <li>
                          <strong>
                            <i className="fa fa-envelope-o"></i>Email:
                          </strong>
                          <span>
                            <a href="#">sales@company.com</a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Latest tweets</h4>
                    <div className="latest-tweet">
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>newsletter</h4>
                    <p>
                      By subscribing to our company newsletter you will always
                      be up-to-date on our latest promotions, deals and vehicle
                      inventory!
                    </p>
                    <form
                      method="post"
                      action="http://themesuite.us5.list-manage.com/subscribe/post"
                      className="form_contact"
                    >
                      <input
                        type="text"
                        value=""
                        name="MERGE0"
                        placeholder="Email Address"
                      />
                      <input
                        type="submit"
                        value="Subscribe"
                        className="md-button"
                      />
                      <input
                        type="hidden"
                        name="u"
                        value="5845bd11ac7043069150fb046"
                      />
                      <input type="hidden" name="id" value="1efe59e47d" />
                    </form>
                  </div>
                </div>
              </div>
            </footer>

            <div className="clearfix"></div>
            <section className="copyright-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="logo-footer margin-bottom-40 md-margin-bottom-40 sm-margin-bottom-10 xs-margin-bottom-20"></div>
                    <span>Copyright &copy; 2021</span>
                    <a href="https://www.sysandgo.com">SYS&GO</a>
                    <span>. All rights reserved.</span>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <ul className="social margin-bottom-25 md-margin-bottom-25 sm-margin-bottom-20 xs-margin-bottom-20 xs-padding-top-10 clearfix">
                      <li>
                        <a className="sc-1" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-2" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-3" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-4" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-5" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-6" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-7" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-8" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-9" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-10" href="#"></a>
                      </li>
                    </ul>
                    <ul className="f-nav">
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>
                        <a href="#">Pages</a>
                      </li>
                      <li>
                        <a href="demo.html">Inventory</a>
                      </li>
                      <li>
                        <a href="services.html">Services</a>
                      </li>
                      <li>
                        <a href="our-team.html">Our Team</a>
                      </li>
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="contact.html">Location</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      case 5:
        return (
          <div>
            <footer className="design_2">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 padding-left-none md-padding-left-none sm-padding-left-15 xs-padding-left-15">
                    <h4>newsletter</h4>
                    <p>
                      By subscribing to our company newsletter you will always
                      be up-to-date on our latest promotions, deals and vehicle
                      inventory!
                    </p>
                    <form method="post" action="#" className="form_contact">
                      <input
                        type="text"
                        value=""
                        name="MERGE0"
                        placeholder="Email Address"
                      />
                      <input
                        type="submit"
                        value="Subscribe"
                        className="md-button"
                      />
                      <input type="hidden" name="u" value="" />
                      <input type="hidden" name="id" value="" />
                    </form>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <h4>Latest tweets</h4>
                    <div className="latest-tweet">
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 padding-right-none md-padding-right-none sm-padding-right-15 xs-padding-right-15">
                    <h4>Contact us</h4>
                    <div className="footer-contact">
                      <ul>
                        <li>
                          <i className="fa fa-map-marker"></i>
                          <strong>Address:</strong> 1234 Street Name, City Name,
                          AB 12345
                        </li>
                        <li>
                          <i className="fa fa-phone"></i>
                          <strong>Phone:</strong>1-800-123-4567
                        </li>
                        <li>
                          <i className="fa fa-envelope-o"></i>{" "}
                          <strong>Email:</strong>
                          <a href="#">sales@company.com</a>
                        </li>
                      </ul>

                      <i className="fa fa-location-arrow back_icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div className="clearfix"></div>
            <section className="copyright-wrap padding-bottom-10">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="logo-footer margin-bottom-20 md-margin-bottom-20 sm-margin-bottom-10 xs-margin-bottom-20"></div>
                    <span>Copyright &copy; 2021</span>
                    <a href="https://www.sysandgo.com">SYS&GO</a>
                    <span>. All rights reserved.</span>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <ul className="social margin-bottom-25 md-margin-bottom-25 sm-margin-bottom-20 xs-margin-bottom-20 xs-padding-top-10 clearfix">
                      <li>
                        <a className="sc-1" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-2" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-3" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-4" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-5" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-6" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-7" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-8" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-9" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-10" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-11" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-12" href="#"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      case 6:
        return (
          <div>
            <footer>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Recent Posts</h4>

                    <div className="side-blog">
                      <a href="#">
                        <img
                          src="images/blog-thumb-1.jpg"
                          className="alignleft"
                          alt=""
                        />
                        Dodge Charger, F-250 popular with car thieves
                      </a>
                      <p>
                        The most theft-prone vehicle in America might be the
                        Dodge ...
                      </p>
                    </div>
                    <div className="side-blog">
                      <a href="#">
                        <img
                          src="images/blog-thumb-2.jpg"
                          className="alignleft"
                          alt=""
                        />
                        BMW extends global sales
                      </a>
                      <p>
                        Global sales of BMW Group's core brand rose faster in
                        ...
                      </p>
                    </div>
                    <div className="side-blog">
                      <a href="#">
                        <img
                          src="images/blog-thumb-3.jpg"
                          className="alignleft"
                          alt=""
                        />
                        Tesla to join NASDAQ 100
                      </a>
                      <p>
                        The world's best-performing automotive stock this year
                        ...
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Contact us</h4>
                    <div className="footer-contact">
                      <ul>
                        <li>
                          <strong>
                            <i className="fa fa-phone"></i>Phone:
                          </strong>
                          <span>1-800-123-4567</span>
                        </li>
                        <li>
                          <strong>
                            <i className="fa fa-map-marker"></i>Address:
                          </strong>
                          <span>
                            Company Name
                            <br />
                            1234 Street Name
                            <br />
                            City Name, AB 12345
                            <br />
                            United States
                          </span>
                        </li>
                        <li>
                          <strong>
                            <i className="fa fa-envelope-o"></i>Email:
                          </strong>
                          <span>
                            <a href="#">sales@company.com</a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>Latest tweets</h4>
                    <div className="latest-tweet">
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                      <div>
                        <i className="fa fa-twitter"></i>
                        <p>
                          Put your tweet message here. Make it compelling to
                          attract other <a href="#">@people</a> to read and
                          click on your
                          <a href="#">http://links</a> to your site.
                          <a href="#">#hashtag</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <h4>newsletter</h4>
                    <p>
                      By subscribing to our company newsletter you will always
                      be up-to-date on our latest promotions, deals and vehicle
                      inventory!
                    </p>
                    <form
                      method="post"
                      action="http://themesuite.us5.list-manage.com/subscribe/post"
                      className="form_contact"
                    >
                      <input
                        type="text"
                        value=""
                        name="MERGE0"
                        placeholder="Email Address"
                      />
                      <input
                        type="submit"
                        value="Subscribe"
                        className="md-button"
                      />
                      <input
                        type="hidden"
                        name="u"
                        value="5845bd11ac7043069150fb046"
                      />
                      <input type="hidden" name="id" value="1efe59e47d" />
                    </form>
                  </div>
                </div>
              </div>
            </footer>

            <div className="clearfix"></div>
            <section className="copyright-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div className="logo-footer margin-bottom-40 md-margin-bottom-40 sm-margin-bottom-10 xs-margin-bottom-20"></div>
                    <span>Copyright &copy; 2021</span>
                    <a href="https://www.sysandgo.com">SYS&GO</a>
                    <span>. All rights reserved.</span>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                    <ul className="social margin-bottom-25 md-margin-bottom-25 sm-margin-bottom-20 xs-margin-bottom-20 xs-padding-top-10 clearfix">
                      <li>
                        <a className="sc-1" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-2" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-3" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-4" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-5" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-6" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-7" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-8" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-9" href="#"></a>
                      </li>
                      <li>
                        <a className="sc-10" href="#"></a>
                      </li>
                    </ul>
                    <ul className="f-nav">
                      <li>
                        <a href="index.html">Home</a>
                      </li>
                      <li>
                        <a href="#">Pages</a>
                      </li>
                      <li>
                        <a href="demo.html">Inventory</a>
                      </li>
                      <li>
                        <a href="services.html">Services</a>
                      </li>
                      <li>
                        <a href="our-team.html">Our Team</a>
                      </li>
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="contact.html">Location</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      default:
        return <div></div>;
    }
  }
}

export default connect(mapStateToProps)(Footer);
