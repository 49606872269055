import React, { Component } from "react";

class MyModal extends Component {
  state = {};
  render() {
    const { component: Component, props, className } = this.props;
    return (
      <div
        className={className}
        style={{ display: this.props.isOpen ? "block" : "none" }}
      >
        {/* <span className="close-button">x</span> */}
        <button className="close-button" onClick={this.props.toggle}>
          X
        </button>
        {/* <div class="form-container"> */}
        {props ? <Component {...props} /> : <Component />}
        {/* </div> */}
      </div>
    );
  }
}

export default MyModal;
