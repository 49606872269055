import React from "react";
import Joi from "joi";
import { CardBody, Row, Col, Form } from "reactstrap";
import myForm from "../../components/common/form";

class Calculator extends myForm {
  state = {
    data: {
      askingPrice: null,
      downPayment: 0,
      interestRate: null,
      termYears: null,
      frequency: 1,
      // customerState: "Followup needed",
      // customerType: "Individual",
    },
    paymentsCount: "",
    paymentAmount: "",
    generalError: "",
    loading: false,
    errors: "",
  };
  schemaObj = {
    askingPrice: Joi.number().required().label("Cost of Vehicle"),
    downPayment: Joi.number().required().label("Down Payment"),
    interestRate: Joi.number().required().label("Annual Interest Rate"),
    termYears: Joi.number().required().label("Term of Loan in Years"),
    frequency: Joi.number().required().label("Frequency of Payments"),
  };
  schema = Joi.object(this.schemaObj).options({ stripUnknown: true });

  calculate(calcObject) {
    const { askingPrice, downPayment, interestRate, termYears, frequency } =
      calcObject;
    const PV = downPayment - askingPrice;
    const //Present Value
      FV = 0;
    const //Future Value
      IYR = interestRate;
    const //No. of advance payments
      TERM = termYears * 12;
    const //Interest Rate
      FREQ = TERM / frequency;
    const //Payment Frequency
      ADV = 0;
    const SIR = IYR / FREQ;
    const UIR = IYR / 100 / FREQ;
    const N = ADV + TERM;
    const SPPV = 1 / Math.pow(1 + SIR / 100, N);
    const USPV =
      (Math.pow(1 + UIR, TERM) - 1) / (UIR * Math.pow(1 + UIR, TERM));
    const PMT = Math.abs((PV - FV * SPPV) / (USPV + ADV));
    return Math.round(PMT * frequency);
  }

  doSubmit = async () => {
    try {
      const paymentAmount = this.calculate(this.state.data);
      const paymentsCount =
        (this.state.data.termYears * 12) / this.state.data.frequency;
      this.setState({ paymentAmount, paymentsCount });
      // this.props.toggleModal();
    } catch (ex) {
      if (ex.response /* && ex.response.status === 400*/) {
        const errors = { ...this.state.errors };
        errors.serverError = ex.response.data;
        this.setState({ errors });
      }
      return ex.response
        ? ex.response.status + " : " + ex.response.data
        : ex.name + " : " + ex.message;
    }
  };

  render() {
    return (
      <div>
        <Form className="mt-0 pt-0">
          {this.state.generalError && (
            <Row>
              <Col xs="12">
                <div className="mt-3 text-danger">
                  {this.state.generalError}
                </div>
              </Col>
            </Row>
          )}
          {/* <h4 className="mb-4">Chat with a salesperson</h4> */}
          <Row>
            <Col md="12">
              {/* <div className="form-group"> */}
              {this.renderInputNumber(
                "askingPrice",
                "Cost of Vehicle",
                "text",
                false,
                "$"
              )}
              {this.renderInputNumber(
                "downPayment",
                "Down Payment",
                "text",
                false,
                "$"
              )}
              {this.renderInputNumber(
                "interestRate",
                "Annual Interest Rate (%)",
                "text",
                false
              )}
              {this.renderInputNumber(
                "termYears",
                "Term of Loan in Years",
                "text",
                false
              )}
              {this.renderSelect(
                "frequency",
                "Frequency of Payments",
                [
                  { id: 1, name: "Monthly" },
                  { id: 0.5, name: "Bi-Weekly" },
                  { id: 0.25, name: "Weekly" },
                ],
                null,
                null,
                true
              )}
              {this.renderButton(
                "Calculate My Payment",
                "button",
                null,
                null,
                "theme-bg-color mt-5 mb-4",
                true,
                this.handleSubmit
              )}
              <div>
                <strong>
                  {"NUMBER OF PAYMENTS: " + this.state.paymentsCount}
                </strong>
              </div>
              <div className="mt-2">
                <strong>
                  {"PAYMENT AMOUNT: $" + this.state.paymentAmount}
                </strong>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Calculator;
// export default connect(mapStateToProps, mapDispatchToProps)(RequestInfo);
