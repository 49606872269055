import {
  SELECTED_CHAT,
  SEARCH_USER,
  MSG_SUBMIT,
  MSG_RECEIVE,
  START_CHAT,
  REMOVE_CHAT,
  OFFLINE_CHAT,
} from "../constants/";
// import chatData from "../../data/chat/chatData";
const chatData = [];

const INIT_STATE = {
  chatContent: 0,
  chatSearch: "",
  chats: chatData,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECTED_CHAT:
      return {
        ...state,
        chatContent: action.id,
      };
    case REMOVE_CHAT:
      return {
        ...state,
        chats: state.chats.filter((chat) => chat.id !== action.id),
        chatContent: 0,
      };
    case OFFLINE_CHAT:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.id === action.id
            ? Object.assign({}, chat, { status: "offline" })
            : chat;
        }),
      };
    case MSG_SUBMIT:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.id === action.id
            ? Object.assign(
                {},
                chat,
                chat.chatHistory[chat.chatHistory.length - 1]["to"].push(
                  action.chatMsg
                )
              )
            : chat;
        }),
      };
    case MSG_RECEIVE:
      return {
        ...state,
        chats: state.chats.map((chat) => {
          return chat.id === action.id
            ? Object.assign(
                {},
                chat,
                chat.chatHistory[chat.chatHistory.length - 1]["to"].length === 0
                  ? chat.chatHistory[chat.chatHistory.length - 1]["from"].push(
                      action.chatMsg
                    )
                  : chat.chatHistory.push({ from: [action.chatMsg], to: [] })
              )
            : chat;
        }),
      };
    case START_CHAT:
      return {
        ...state,
        chats: [...state.chats, action.chatObject],
      };
    case SEARCH_USER:
      return {
        ...state,
        chatSearch: action.searchTerm,
      };
    default:
      return state;
  }
};
