import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { setSelectedVehicle } from "../redux/vehicles/action";
import carfaxLogo from "../images/carfax.png";
import { numberWithCommas } from "../utils/utils";
import soldStamp from "../images/sold.png";
import comingSoonStamp from "../images/comingSoon.png";
import comingSoon from "../images/comingSoon.jpg";

const mapStateToProps = (state) => ({
  ...state,
  showingVehicles: state.vehiclesReducer.showingVehicles,
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedVehicle: (vehicle) => dispatch(setSelectedVehicle(vehicle)),
});

class SearchResult5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { vehicles: [] };
  }

  render() {
    return (
      <div className="row car-listings-row">
        {this.props.showingVehicles.map((vehicle, i1) => {
          // console.log(vehicle);
          const {
            askingPrice,
            bodyType,
            color,
            engine,
            id,
            makeName,
            mileage,
            modelName,
            productionYear,
            stockNumber,
            vehiclePictures,
            vin,
            drivetrainType,
            transmissionType,
            trim,
            marketingTrim,
            websiteBadge,
            badgeColor,
            rentPerDay,
            floor,
            sold,
          } = vehicle;
          return (
            <div
              className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"
              key={i1}
            >
              <NavLink
                to="/vehicleInfo"
                activeStyle={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.setSelectedVehicle(vehicle);
                }}
              >
                <div className="inventory margin-bottom-20 clearfix scroll_effect fadeIn">
                  {/* <input
                    type="checkbox"
                    name="a"
                    className="checkbox compare_vehicle input-checkbox"
                    id={"vehicle_" + id}
                  />
                  <label htmlFor={"vehicle_" + id}></label> */}
                  {websiteBadge && (
                    <div
                      className={
                        "angled_badge " +
                        (badgeColor ? badgeColor.toLowerCase() : "")
                      }
                    >
                      <span>{websiteBadge}</span>
                    </div>
                  )}
                  <a className="inventory" href="inventory-listing.html">
                    <div className="title">
                      {
                        productionYear +
                          " " +
                          makeName +
                          " " +
                          modelName +
                          " " +
                          (trim || "")
                        // +
                        // " " +
                        // (marketingTrim || "")
                      }
                    </div>
                    <div style={{ position: "relative" }}>
                      <img
                        src={
                          vehiclePictures.length === 0
                            ? comingSoon
                            : vehiclePictures[0]
                        }
                        className="preview"
                        alt="preview"
                      />
                      {sold && (
                        <img
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            zIndex: 999,
                            width: "50%",
                          }}
                          src={soldStamp}
                        />
                      )}
                      {!sold && !floor && (
                        <img
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            zIndex: 999,
                            width: "50%",
                          }}
                          src={comingSoonStamp}
                        />
                      )}
                    </div>
                    <table className="options-primary mb-3">
                      <tr>
                        <td className="option primary">Body Style:</td>
                        <td className="spec">{bodyType} </td>
                      </tr>
                      <tr>
                        <td className="option primary">Drivetrain:</td>
                        <td className="spec">{drivetrainType} </td>
                      </tr>
                      <tr>
                        <td className="option primary">Engine:</td>
                        <td className="spec">{engine ? engine + " L" : ""} </td>
                      </tr>
                      <tr>
                        <td className="option primary">Transmission:</td>
                        <td className="spec">{transmissionType} </td>
                      </tr>
                      <tr>
                        <td className="option primary">Mileage:</td>
                        <td className="spec">
                          {numberWithCommas(mileage) + " km"}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="option secondary">Exterior Color:</td>
                        <td className="spec">{color} </td>
                      </tr>
                      <tr>
                        <td className="option secondary">Stock Number:</td>
                        <td className="spec">{stockNumber} </td>
                      </tr>
                      <tr>
                        <td className="option secondary">VIN Number:</td>
                        <td className="spec">{vin} </td>
                      </tr>
                    </table>
                    {!this.props.rentals && (
                      <img src={carfaxLogo} alt="carfax" className="carfax" />
                    )}
                    <div className="price">
                      {/* <b>Price:</b>
                      <br /> */}
                      <div className="figure">
                        {
                          // sold
                          //   ? "SOLD"
                          //   : !floor
                          //   ? "COMING SOON!"
                          //   :
                          "$" +
                            (numberWithCommas(
                              this.props.rentals
                                ? rentPerDay + "/day"
                                : askingPrice
                            ) || "")
                        }
                        <br />
                      </div>
                      <div className="tax">Plus Tax & Fees</div>
                    </div>
                    {/* <div className="view-details gradient_button">
                      <i className="fa fa-plus-circle"></i> View Details
                    </div> */}
                    <div className="clearfix"></div>
                  </a>
                  {/* <div
                    className="view-video gradient_button"
                    data-youtube-id="3oh7PBc33dk"
                  >
                    <i className="fa fa-video-camera"></i> View Video
                  </div> */}
                </div>
              </NavLink>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult5);
