import React, { Component } from "react";
import { connect } from "react-redux";
import FinanceApplication from "../components/customer/financeApplication";
import { setDealership } from "../redux/settings/action";
import { getDealership } from "../services/dealershipService";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

const mapDispatchToProps = (dispatch) => ({
  setDealership: (dealership) => dispatch(setDealership(dealership)),
});

class CreditApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    if (!this.props.dealership.websiteSettings) {
      const { data: dealership } = await getDealership();
      this.props.setDealership(dealership);
    }
  }

  render() {
    if (!this.props.dealership.websiteSettings) return null;
    return (
      <div>
        <PageMetaTags />
        <section id="secondary-banner" className="dynamic-image-5">
          {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
          <div className="container">
            {/* <div className="row align-items-end"> */}
            {/* <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"> */}
            <h2>Apply For Finance</h2>
            <h4>
              {this.props.dealership.websiteSettings.financeApplicationSubtitle}
            </h4>
            {/* </div> */}
            {/* <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <ul className="breadcrumb">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>Contact</li>
                </ul>
              </div> */}
            {/* </div> */}
          </div>
        </section>
        {/* <!--secondary-banner ends--> */}
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content">
          <div className="container">
            <div className="inner-page">
              <FinanceApplication />
            </div>
          </div>
          {/* <!--container ends--> */}
        </section>
        {/* <!--content ends--> */}
        <div className="clearfix"></div>
        <div className="back_to_top">
          <img src="images/arrow-up.png" alt="scroll up" />
        </div>
      </div>
    );
  }
}

// export default CreditApplication;
export default connect(mapStateToProps, mapDispatchToProps)(CreditApplication);
