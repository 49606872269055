import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
// const today = Datetime.moment();
// var valid = function (current) {
//   return current.isBefore(today);
// };

const MyTimePicker = ({
  name,
  label,
  error,
  // validate,
  labelPosition,
  ...rest
}) => {
  return labelPosition === "left" ? (
    <div className="form-group">
      <InputGroup>
        {label && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="bg-white border-0">
              {label}
            </InputGroupText>
          </InputGroupAddon>
        )}
        <Datetime
          {...rest}
          dateFormat={false}
          timeFormat="HH:mm"
          name={name}
          id={name}
        />
      </InputGroup>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  ) : (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <Datetime
        {...rest}
        dateFormat={false}
        timeFormat="HH:mm"
        name={name}
        id={name}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default MyTimePicker;
