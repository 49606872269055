import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl } from "../config.js";

const apiEndpoint = apiUrl + "/auth";
const tokenKey = "currentUser";

http.setJwt(getJwt());

export async function login(username, password) {
  const { data } = await http.post(apiEndpoint, { username, password });
  localStorage.setItem(tokenKey, data);
  http.setJwt(data);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
  // try {
  // await http.post(apiEndpoint + "/logout");
  localStorage.removeItem(tokenKey);
  // } catch (err) {
  //   if (
  //     err.response.data ===
  //     "Your session has expired. Please logout and login again."
  //   ) {
  //     localStorage.removeItem(tokenKey);
  //     closeWebSockets();
  //   } else throw new Error(err);
  // }
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  const token = localStorage.getItem(tokenKey);
  return token;
}

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
