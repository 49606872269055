import React, { Component } from "react";
import { connect } from "react-redux";
import { saveWebsiteMessage } from "../services/websiteMessageService";
import GoogleMap from "../components/common/googleMap";
import { setDealership } from "../redux/settings/action";
import { getDealership } from "../services/dealershipService";
import SendMessage from "../components/customer/sendMessage";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

const mapDispatchToProps = (dispatch) => ({
  setDealership: (dealership) => dispatch(setDealership(dealership)),
});

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      status: "",
    };
    this.doSubmit = this.doSubmit.bind(this);
  }

  async componentDidMount() {
    if (!this.props.dealership.websiteSettings) {
      const { data: dealership } = await getDealership();
      this.props.setDealership(dealership);
    }
  }

  async doSubmit() {
    try {
      await saveWebsiteMessage(this.state);
      this.setState({
        status: "Message sent successfully.",
        name: "",
        email: "",
        message: "",
      });
    } catch (err) {
      this.setState({ status: err.message });
    }
  }

  render() {
    if (!this.props.dealership.websiteSettings) return null;

    const {
      name,
      address,
      city,
      province,
      country,
      postalCode,
      phoneNumber1,
      url,
      email,
      workingHours,
      websiteSettings,
    } = this.props.dealership;

    return (
      <div>
        <PageMetaTags />
        <section id="secondary-banner" className="dynamic-image-5">
          {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
          <div className="container">
            <h2>Contact Us</h2>
            <h4>{websiteSettings ? websiteSettings.contactUsSubtitle : ""}</h4>
            {/* <ul className="breadcrumb">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>Contact</li>
                </ul> */}
          </div>
        </section>
        {/* <!--secondary-banner ends--> */}
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content">
          <div className="container">
            <div className="inner-page">
              {/* <!--MAP--> */}
              <div className="find_map row clearfix">
                <div className="col-lg-12">
                  <h2 className="margin-bottom-25 margin-top-none">
                    FIND US ON THE MAP
                  </h2>
                  <div className="map margin-vertical-30">
                    <div
                      className="contact"
                      style={{
                        height: "390px",
                      }}
                    >
                      <GoogleMap
                        dealershipName={
                          address +
                          " " +
                          city +
                          " " +
                          province +
                          " " +
                          postalCode +
                          " " +
                          name
                        }
                      />
                    </div>
                  </div>

                  {/* <div className="map margin-vertical-30">
                    <div
                      id="google-map-listing"
                      className="contact"
                      data-longitude="-122.78951"
                      data-latitude="49.26556"
                      data-zoom="12"
                      style="height: 390px"
                    ></div>
                  </div> */}
                </div>
              </div>
              {/* <!--MAP--> */}
              {/* <!--CONTACT INFORMATION--> */}
              <div className="row contacts margin-top-25">
                {/* <!--LEFT INFORMATION--> */}
                <div className="col-md-6 left-information">
                  <div className="contact_information information_head clearfix">
                    <h3 className="margin-bottom-25 margin-top-none">
                      CONTACT INFORMATION
                    </h3>
                    <div className="address clearfix margin-right-25 padding-bottom-40">
                      <div className="icon_address">
                        <p>
                          <i className="fa fa-map-marker"></i>
                          <strong>Address:</strong>
                        </p>
                      </div>
                      <div className="contact_address">
                        <div className="margin-bottom-none">
                          <div id="address1">{address}</div>
                          <div id="address2">{city}</div>
                          <div id="address3">{province}</div>
                          <div id="address4">{postalCode}</div>
                        </div>
                      </div>
                    </div>
                    <div className="address clearfix address_details margin-right-25 padding-bottom-40">
                      <ul className="margin-bottom-none">
                        <li>
                          <i className="fa fa-phone"></i>
                          <strong>Phone:</strong>
                          <span id="phoneNumber">{phoneNumber1}</span>
                        </li>
                        <li>
                          <i className="fa fa-envelope-o"></i>
                          <strong>Email:</strong>
                          {/* <!-- <a href="mailto:sales@company.com">sales@company.com</a> --> */}
                          <span id="email">{email}</span>
                        </li>
                        <li className="padding-bottom-none">
                          <i className="fa fa-laptop"></i>
                          <strong>Web :</strong>
                          {/* <!-- <a href="http://company.com">www.company.com</a> --> */}
                          <span id="url">{"www." + url}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!--INFORMATION HOUR--> */}
                  <div className="information_hour information_head margin-top-30 xs-padding-bottom-40">
                    <h3 className="margin-bottom-10 margin-top-none">
                      BUSINESS HOURS
                    </h3>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="deparment">
                          <table className="table table-bordered no-border font-12px">
                            {/* <thead>
                              <tr>
                                <td colspan="2">
                                  <strong>Sales Department</strong>
                                </td>
                              </tr>
                            </thead> */}
                            <tbody>
                              {weekDays.map((w, i) =>
                                workingHours ? (
                                  <tr key={w}>
                                    <td>{w + ":"}</td>
                                    <td>
                                      {(workingHours[i][0] || "") +
                                        " - " +
                                        (workingHours[i][1] || "")}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        {/* <!-- <div className="deparment">
                      <table className="table table-bordered no-border font-12px">
                        <thead>
                          <tr>
                            <td colspan="2">
                              <strong>Sales Department</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mon:</td>
                            <td id="workingHours0"></td>
                          </tr>
                          <tr>
                            <td>Tue:</td>
                            <td id="workingHours1"></td>
                          </tr>
                          <tr>
                            <td>Wed:</td>
                            <td id="workingHours2"></td>
                          </tr>
                          <tr>
                            <td>Thu:</td>
                            <td id="workingHours3"></td>
                          </tr>
                          <tr>
                            <td>Fri:</td>
                            <td id="workingHours4"></td>
                          </tr>
                          <tr>
                            <td>Sat:</td>
                            <td id="workingHours5"></td>
                          </tr>
                          <tr>
                            <td>Sun:</td>
                            <td id="workingHours6"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div> --> */}
                      </div>
                      <div className="col-sm-4">
                        {/* <!-- <div className="deparment">
                      <table
                        className="table table-bordered no-border font-12px margin-bottom-none"
                      >
                        <thead>
                          <tr>
                            <td colspan="2">
                              <strong>Parts Department</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mon:</td>
                            <td>8:00am - 5:00pm</td>
                          </tr>
                          <tr>
                            <td>Tue:</td>
                            <td>8:00am - 9:00pm</td>
                          </tr>
                          <tr>
                            <td>Wed:</td>
                            <td>8:00am - 5:00pm</td>
                          </tr>
                          <tr>
                            <td>Thu:</td>
                            <td>8:00am - 9:00pm</td>
                          </tr>
                          <tr>
                            <td>Fri:</td>
                            <td>8:00am - 6:00pm</td>
                          </tr>
                          <tr>
                            <td>Sat:</td>
                            <td>9:00am - 5:00pm</td>
                          </tr>
                          <tr>
                            <td>Sun:</td>
                            <td>Closed</td>
                          </tr>
                        </tbody>
                      </table>
                    </div> --> */}
                      </div>
                    </div>
                  </div>
                  {/* <!--INFORMATION HOUR--> */}
                </div>
                {/* <!--LEFT INFORMATION--> */}

                {/* <!--RIGHT INFORMATION--> */}
                <div className="col-md-5 offset-lg-1 offset-md-1 xs-margin-top-30">
                  <div className="contact_wrapper information_head">
                    <h3 className="margin-bottom-25 margin-top-none">
                      CONTACT FORM
                    </h3>
                    <SendMessage />
                    {/* <div className="form_contact margin-bottom-20">
                      <div id="result"></div>
                      <fieldset id="contact_form">
                        <input
                          type="text"
                          className="form-control margin-bottom-25"
                          value={this.state.name}
                          name="name"
                          placeholder="Name"
                          onChange={(e) => {
                            this.setState({ name: e.currentTarget.value });
                          }}
                        />
                        <input
                          type="text"
                          className="form-control margin-bottom-25"
                          value={this.state.email}
                          name="email"
                          placeholder="Email"
                          onChange={(e) => {
                            this.setState({ email: e.currentTarget.value });
                          }}
                        />
                        <textarea
                          name="message"
                          className="form-control margin-bottom-25 contact_textarea"
                          placeholder="Message"
                          value={this.state.message}
                          rows="7"
                          onChange={(e) => {
                            this.setState({ message: e.currentTarget.value });
                          }}
                        ></textarea>
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={this.doSubmit}
                        >
                          Send Message
                        </button>
                        <span className="ml-3">{this.state.status}</span>
                      </fieldset>
                    </div> */}
                  </div>
                </div>
                {/* <!--RIGHT INFORMATION--> */}
              </div>
              {/* <!---CONTACT INFORMATION--> */}

              <div className="clearfix"></div>
            </div>
          </div>
          {/* <!--container ends--> */}
        </section>
        {/* <!--content ends--> */}
        <div className="clearfix"></div>
        <div className="back_to_top">
          <img src="images/arrow-up.png" alt="scroll up" />
        </div>
      </div>
    );
  }
}

// export default Contact;
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
