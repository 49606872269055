import http from "./httpService";
import { apiUrl } from "../config.js";
// const apiEndpoint = apiUrl + "/cruds";
const apiEndpoint2 = apiUrl + "/vehicles";

// function vehicleUrl(id) {
//   return `${apiEndpoint}/vehicle,${id}`;
// }

// export function getVehicle(id) {
//   return http.get(apiEndpoint2 + "/" + id);
// }

// export function getInventory(includeSoldVehicles) {
//   return http.post(apiEndpoint2 + "/inventoryList", {
//     includeSoldVehicles,
//   });
// }

export function getVehicles(searchObject) {
  return http.post(apiEndpoint2 + "/inventorySearch", searchObject);
}

export function getFloorVehiclesWithFirstPicture(t) {
  return http.get(apiEndpoint2 + "/withFirstPicture/floor");
}

export function shareVehicle(shareObject) {
  return http.post(apiEndpoint2 + "/share", shareObject);
}

export function saveWebsiteVisit(vehicle) {
  return http.post(apiEndpoint2 + "/websiteVisit", vehicle);
}

export function getBodyTypes() {
  return http.get(apiEndpoint2 + "/availableBodies");
}

// export async function getInventoryAsItems() {
//   try {
//     const { data: inventory } = await http.post(apiEndpoint + "/vehicle,read", {
//       where: { entityState: { Opne: "Sold" } },
//       order: [["productionYear"]],
//     });
//     // const result = inventory.map(r => {
//     //   const pr = {
//     //     id: r.id,
//     //     name:
//     //       r.productionYear +
//     //       ", " +
//     //       r.makeName +
//     //       ", " +
//     //       r.modelName +
//     //       ", " +
//     //       r.color +
//     //       " - " +
//     //       r.stockNumber
//     //   };
//     //   return pr;
//     // });
//     // const resultObject = { data: result };
//     const resultObject = { data: inventory };
//     return resultObject;
//   } catch (err) {
//     throw new Error(err.message);
//   }
// }

// export function decodeVIN(vin) {
//   return http.get(apiUrl + "/vehicles/vin/" + vin);
// }

// export function saveVehicle(vehicle) {
//   return http.post(apiEndpoint + "/vehicle,create", vehicle);
// }

// export function updateVehicle(vehicle) {
//   console.log(vehicle);
//   return http.put(apiEndpoint + "/vehicle", {
//     columns: { ...vehicle },
//     idArray: [vehicle.id],
//   });
// }

// export function deleteVehicle(vehicleId) {
//   console.log("vehicle: ", vehicleId);
//   return http.delete(vehicleUrl(vehicleId));
// }
