import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import { getGoogleReviews } from "../../services/googleReviewService";
import loader from "../../utils/googleMapLoader";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

let map;

class GoogleReview extends Component {
  state = {
    reviews: [],
  };

  async componentDidMount() {
    const { data: reviews } = await getGoogleReviews();
    this.setState({ reviews });
    // const that = this;
    // const lng = parseFloat(this.props.dealership.websiteSettings.longitude);
    // const lat = parseFloat(this.props.dealership.websiteSettings.latitude);
    // loader.load().then(() => {
    //   map = new window.google.maps.Map(document.getElementById("review"), {
    //     center: { lat, lng },
    //     zoom: this.props.zoom || 15,
    //   });
    //   var request = {
    //     query: this.props.dealership.name,
    //     // fields: ["name", "geometry"],
    //   };
    //   var service = new window.google.maps.places.PlacesService(map);
    //   service.textSearch(request, function (results, status) {
    //     if (status == window.google.maps.places.PlacesServiceStatus.OK) {
    //       const placeId = results[0].place_id;
    //       var request2 = {
    //         placeId,
    //         fields: [
    //           "name",
    //           "rating",
    //           "formatted_phone_number",
    //           "geometry",
    //           "review",
    //         ],
    //       };
    //       var service2 = new window.google.maps.places.PlacesService(map);
    //       service2.getDetails(request2, function ({ reviews }, status) {
    //         if (status == window.google.maps.places.PlacesServiceStatus.OK) {
    //           that.setState({ reviews });
    //         }
    //       });
    //     }
    //   });
    // });
  }
  render() {
    return (
      <div>
        <div id="review" style={{ display: "none" }}></div>
        {/* <div className="recent-vehicles-wrap sm-padding-left-none padding-bottom-10"> */}
        <div className="recent-vehicles xs-padding-bottom-20">
          <h5 className="mb-4">OUR REVIEWS</h5>
          {/* <p>
                {"Browse through the vast selection of vehicles that " +
                  (this.props.recent
                    ? "have recently been added to our inventory."
                    : "are similar to this vehicle.")}
              </p> */}
        </div>
        <div>
          <AliceCarousel
            mouseTracking
            disableDotsControls
            // disableButtonsControls
            // activeIndex={this.state.activeIndex}
            // onSlideChanged={this.syncActiveIndex}
            responsive={{
              0: { items: 1 },
              568: { items: 1 },
              1024: { items: 1 },
              1366: { items: 2 },
              1536: { items: 2 },
              1920: { items: 2 },
            }}
            items={(() => {
              const result = this.state.reviews
                .filter((r) => r.rating > 4)
                .map((review, ind) => {
                  return (
                    <div
                      className="slide"
                      style={{
                        float: "left",
                        listStyle: "none",
                        position: "relative",
                        marginRight: "30px",
                        width: "485px",
                      }}
                      key={ind}
                    >
                      <a
                        href={review.author_url}
                        style={{ color: "unset" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="my-review-block p-5">
                          <h5>
                            <strong>{review.author_name}</strong>
                            <span className="float-right text-warning">
                              <i className="fa fa-lg fa-google text-primary mr-2"></i>
                              {(() => {
                                const stars = [];
                                for (let j = 0; j < review.rating; j++)
                                  stars.push(<i class="fa fa-star"></i>);
                                return stars;
                              })()}
                            </span>
                          </h5>
                          {review.relative_time_description}
                          <p className="mt-3">{review.text}</p>
                        </div>
                      </a>
                    </div>
                  );
                });
              return result;
            })()}
          />
          {/* </div> */}
        </div>
      </div>
    );
  }
}

// export default GoogleReview;
export default connect(mapStateToProps)(GoogleReview);
