export function stringToDate(dateStr) {
  const date = new Date(dateStr);
  return date;
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
}

export function formColor(form, state) {
  // console.log(form, state);
  if (state !== false && !state) return "primary";
  switch (form) {
    case "vehicle":
      switch (state.toLowerCase()) {
        case "floor":
          return "info";
        case "offered":
          return "success";
        case "purchased":
          return "warning";
        case "sold":
          return "danger";
        default:
          return "primary";
      }
    case "customer":
      switch (state.toLowerCase()) {
        case "appointment":
          return "info";
        case "decision made":
          return "success";
        case "in negotiation":
          return "warning";
        case "followup needed":
          return "danger";
        default:
          return "primary";
      }
    case "user":
      return state ? "info" : "danger";
    default:
      return "";
  }
}

export function bootstrapToHex(bootstrapColor) {
  switch (bootstrapColor) {
    case "info":
      return "#0275d8";
    case "warning":
      return "#f0ad4e";
    case "danger":
      return "#d9534f";
    case "success":
      return "#5bc0de";
    case "primary":
      return "#401F7C";
    case "white":
      return "#FFFFFF";
    default:
      return "#0275d8";
  }
}

export function formColorHex(form, state) {
  return bootstrapToHex(formColor(form, state));
}

export function roundCurrency(n) {
  return Math.round(n * 100) / 100;
}

export function insertIntoString(str1, str2, index) {
  return str1.slice(0, index) + str2 + str1.slice(index);
}

export function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

export function colorObjToString(colorObject) {
  const { r, g, b, a } = colorObject;
  return "rgba(" + r + "," + g + "," + b + "," + a + ")";
}
// export let globalState = [];
export function myLocaleDateString(dateStr) {
  const date = dateStr ? new Date(dateStr) : new Date();
  return date.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function myLocaleDateTimeString(dateStr) {
  const date = dateStr ? new Date(dateStr) : new Date();
  return date.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour12: false,
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function myLocaleTimeString(dateStr) {
  const date = dateStr ? new Date(dateStr) : new Date();
  return date.toLocaleTimeString("en-CA", {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function myLocaleTimeStringPlusOne(dateStr) {
  const date = dateStr ? new Date(dateStr) : new Date();
  date.setMinutes(date.getMinutes() + 1);
  return date.toLocaleTimeString("en-CA", {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function toDate(dateString, timeString) {
  return new Date(dateString + " " + timeString);
}

export function daysBetweenDates(firstDate, secondDate) {
  const d1 = new Date(firstDate);
  const d2 = new Date(secondDate);
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((d1 - d2) / oneDay));
}
