import React from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

function PageMetaTags(props) {
  const { name, websiteSettings, websitePages } = props.dealership;
  const pageIndex =
    websitePages &&
    websitePages.findIndex((s) => "/" + s === window.location.pathname);
  const pageTitle = websiteSettings.metaTags[pageIndex][0] || name;
  const pageDescription = websiteSettings.metaTags[pageIndex][1] || name;
  const pageKeywords = websiteSettings.metaTags[pageIndex][2] || name;
  return (
    <MetaTags>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
    </MetaTags>
  );
}

export default connect(mapStateToProps)(PageMetaTags);
