import React, { Component } from "react";
import ChatList from "../../containers/chat/chatList";
import ChatSearch from "../../containers/chat/chatSearch";
import ChatContent from "../../containers/chat/chatContent";
import ChatMsgSend from "../../containers/chat/chatMsgSend";
import ChatStart from "../../components/chat/chatStart";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";

const mapStateToProps = (state) => ({
  ...state,
  chatContent: state.chatReducer.chatContent,
});
class Chat extends Component {
  state = {
    modalOpen: true,
  };
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };
  render() {
    return (
      <div>
        {this.props.chatContent === 0 ? (
          <ChatStart />
        ) : (
          <div className="bg-white chat-list">
            <ChatContent />
            <ChatMsgSend />
          </div>
        )}
      </div>
    );
  }
}

// export default Chat;
export default connect(mapStateToProps)(Chat);
