import React from "react";
import Joi from "joi";
import { CardBody, Row, Col, Form } from "reactstrap";
import myForm from "../../components/common/form";
import { saveCustomerInterest } from "../../services/customerService";
import { saveWebsiteMessage } from "../../services/websiteMessageService";

class RequestInfo extends myForm {
  state = {
    data: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      // customerState: "Followup needed",
      // customerType: "Individual",
    },
    generalError: "",
    loading: false,
    errors: "",
  };
  schemaObj = {
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().allow(null).label("Last Name"),
    phoneNumber: Joi.string().allow(null).label("Phone Number"),
    email: Joi.string().email({ tlds: false }).required().label("Email"),
  };
  schema = Joi.object(this.schemaObj).options({ stripUnknown: true });

  doSubmit = async () => {
    try {
      this.setState({ loading: true });
      await saveWebsiteMessage({
        ...this.state.data,
        vehicleId: this.props.vehicleId,
      });
      this.setState({ loading: false });
      // await saveCustomerInterest({
      //   ...this.state.data,
      //   vehicleId: this.props.vehicleId,
      // });
      this.props.toggleModal();
    } catch (ex) {
      if (ex.response /* && ex.response.status === 400*/) {
        const errors = { ...this.state.errors };
        errors.serverError = ex.response.data;
        this.setState({ errors });
      }
      return ex.response
        ? ex.response.status + " : " + ex.response.data
        : ex.name + " : " + ex.message;
    }
  };

  render() {
    return (
      // <Card body outline color={"info"} className="border">
      // <Card body outline className="border">
      <CardBody>
        <Form className="mt-0 pt-0">
          {this.state.generalError && (
            <Row>
              <Col xs="12">
                <div className="mt-3 text-danger">
                  {this.state.generalError}
                </div>
              </Col>
            </Row>
          )}
          {/* <h4 className="mb-4">Chat with a salesperson</h4> */}
          <Row>
            <Col md="12">
              {/* <div className="form-group"> */}
              {this.renderInput("firstName", "First Name", "text", false)}
              {this.renderInput("lastName", "Last Name", "text", false)}
              {this.renderInputNumber(
                "phoneNumber",
                "Phone Number 1",
                "tel",
                false
              )}
              {this.renderInput(
                "email",
                "Email",
                "text",
                false,
                null,
                null,
                null,
                null,
                null,
                "mb-4"
              )}
              {this.renderButton(
                this.state.loading ? "Sending..." : "Send Request",
                "button",
                null,
                null,
                "theme-bg-color",
                true,
                this.handleSubmit,
                this.state.loading
              )}
            </Col>
          </Row>
        </Form>
      </CardBody>
      // </Card>
    );
  }
}

export default RequestInfo;
// export default connect(mapStateToProps, mapDispatchToProps)(RequestInfo);
