import React, { Component } from "react";
import { connect } from "react-redux";
import image1 from "../images/team1.jpg";
import image2 from "../images/team2.jpg";
import image3 from "../images/team3.jpg";
import { setDealership } from "../redux/settings/action";
import { getDealership } from "../services/dealershipService";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

const mapDispatchToProps = (dispatch) => ({
  setDealership: (dealership) => dispatch(setDealership(dealership)),
});

class Team extends Component {
  state = {};
  async componentDidMount() {
    if (!this.props.dealership.websiteSettings) {
      const { data: dealership } = await getDealership();
      this.props.setDealership(dealership);
    }
  }

  render() {
    if (!this.props.dealership.websiteSettings) return null;

    return (
      <div>
        <PageMetaTags />
        <section id="secondary-banner" className="dynamic-image-11">
          {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
          <div className="container">
            <h2>Our Team</h2>
            <h4>{this.props.dealership.websiteSettings.ourTeamSubtitle}</h4>
            {/* <ul className="breadcrumb">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>Our Team</li>
                </ul> */}
          </div>
        </section>
        {/* <!--secondary-banner ends--> */}
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content">
          <div className="container">
            <div className="inner-page">
              <div className="find_team clearfix">
                <h2 className="margin-top-none">MEET THE MANAGEMENT</h2>
                <div className="people padding-bottom-40 clearfix">
                  <div className="row">
                    {this.props.dealership.websiteSettings.ourTeamMembers.map(
                      (teamMember, i) => (
                        <div
                          className="col-md-4 col-sm-12"
                          key={"teamMember" + i}
                        >
                          {teamMember.name && (
                            <div className="team margin-top-15 xs-margin-bottom-30">
                              <a
                                href="images/team1-lrg.jpg"
                                className="fancybox"
                              >
                                {this.props.dealership[
                                  "teamMemberImage" + (i + 1)
                                ] && (
                                  <img
                                    src={
                                      this.props.dealership[
                                        "teamMemberImage" + (i + 1)
                                      ]
                                    }
                                    className="aligncenter"
                                    alt="team"
                                  />
                                )}
                              </a>
                              <div className="name_post padding-bottom-15">
                                <h4>{teamMember.name}</h4>
                                <p>{teamMember.role}</p>
                              </div>
                              <div className="about_team padding-bottom-10">
                                <p className="margin-vertical-15">
                                  {teamMember.description}
                                </p>
                                <ul>
                                  <li>
                                    <i className="fa fa-phone"></i>
                                    {teamMember.phoneNumber1}
                                  </li>
                                  <li>
                                    <i className="fa fa-mobile"></i>
                                    {teamMember.phoneNumber2}
                                  </li>
                                  <li>
                                    <i className="fa fa-envelope-o"></i>
                                    <a href="#">{teamMember.email}</a>
                                  </li>
                                </ul>
                              </div>
                              <div className="social_team pull-left">
                                <ul className="social margin-bottom-none">
                                  <li className="margin-bottom-none">
                                    <a className="sc-1" href="#"></a>
                                  </li>
                                  <li className="margin-bottom-none">
                                    <a className="sc-2" href="#"></a>
                                  </li>
                                  <li className="margin-bottom-none">
                                    <a className="sc-5" href="#"></a>
                                  </li>
                                  <li className="margin-bottom-none">
                                    <a className="sc-7" href="#"></a>
                                  </li>
                                  <li className="margin-bottom-none">
                                    <a className="sc-9" href="#"></a>
                                  </li>
                                </ul>
                              </div>
                              <div className="clearfix"></div>
                            </div>
                          )}
                        </div>
                      )
                    )}
                    {/* <div className="col-md-4 col-sm-12">
                      <div className="team margin-top-15 xs-margin-bottom-30">
                        <a href="images/team1-lrg.jpg" className="fancybox">
                          <img
                            src={image1}
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>{teamMember.name}</h4>
                          <p>{teamMember.role}</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">{teamMember.description}
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>{teamMember.phoneNumber1}
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>{teamMember.phoneNumber2}
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">{this.props.dealership.websiteSettings.ourTeamMembers[0].email}</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-7" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-9" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="team margin-top-15 xs-margin-bottom-30">
                        <a href="images/team2-lrg.jpg" className="fancybox">
                          <img
                            src={image2}
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>Leah Jennings</h4>
                          <p>Chief Financial Officer / CFO</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor aenean massa.
                            Cum sociis numquasa mode tempora posuere feugiat.
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>1-800-123-4567 -
                              Extension 107
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>1-902-342-0864
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">leah@automotivetemplate.com</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="team margin-top-15">
                        <a href="images/team3-lrg.jpg" className="fancybox">
                          <img
                            src={image3}
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>Zachary Hale</h4>
                          <p>Lead Sales Manager</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing
                            elit. Aenean commodo ligula eget dolor aenean massa.
                            Cum sociis numquasa mode tempora posuere feugiat.
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>1-800-123-4567 -
                              Extension 119
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>1-902-832-3702
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">zachary@automotivetemplate.com</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-9" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="find_team padding-bottom-40 sales_team margin-top-30 clearfix">
                <h2 className="margin-top-none">MEET OUR SALES TEAM</h2>
                <div className="people clearfix">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 scroll_effect fadeInUp">
                      <div className="team margin-top-15 xs-margin-bottom-30">
                        <a href="images/team4-lrg.jpg" className="fancybox">
                          <img
                            src="images/team4.jpg"
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>Luca Sanderson</h4>
                          <p>Sales Representative</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">
                            Lorem ipsum dolor sit amet, paleotousia consectetuer
                            adipiscing elit. Aenean com.
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>1-800-123-4567 -
                              Extension 105
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>1-902-544-4415
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">luca@automotivetemplate.com</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-9" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 col-sm-12 scroll_effect fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="team margin-top-15 xs-margin-bottom-30">
                        <a href="images/team5-lrg.jpg" className="fancybox">
                          <img
                            src="images/team5.jpg"
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>Abby Myers</h4>
                          <p>Sales Representative</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">
                            Lorem ipsum dolor sit amet, paleotousia consectetuer
                            adipiscing elit. Aenean com.
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>1-800-123-4567 -
                              Extension 123
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>1-902-361-7267
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">abby@automotivetemplate.com</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-7" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-9" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 col-sm-12 scroll_effect fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="team margin-top-15 xs-margin-bottom-30">
                        <a href="images/team6-lrg.jpg" className="fancybox">
                          <img
                            src="images/team6.jpg"
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>Connor Wyatt</h4>
                          <p>Sales Representative</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">
                            Lorem ipsum dolor sit amet, paleotousia consectetuer
                            adipiscing elit. Aenean com.
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>1-800-123-4567 -
                              Extension 111
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>1-902-544-4415
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">connor@automotivetemplate.com</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-7" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 col-sm-3 scroll_effect fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <div className="team margin-top-15">
                        <a href="images/team7-lrg.jpg" className="fancybox">
                          <img
                            src="images/team7.jpg"
                            className="aligncenter"
                            alt="team"
                          />
                        </a>
                        <div className="name_post padding-bottom-15">
                          <h4>Sarah Thomas</h4>
                          <p>Sales Representative</p>
                        </div>
                        <div className="about_team padding-bottom-10">
                          <p className="margin-vertical-15">
                            Lorem ipsum dolor sit amet, paleotousia consectetuer
                            adipiscing elit. Aenean com.
                          </p>
                          <ul>
                            <li>
                              <i className="fa fa-phone"></i>1-800-123-4567 -
                              Extension 108
                            </li>
                            <li>
                              <i className="fa fa-mobile"></i>1-902-544-4415
                            </li>
                            <li>
                              <i className="fa fa-envelope-o"></i>
                              <a href="#">sarah@automotivetemplate.com</a>
                            </li>
                          </ul>
                        </div>
                        <div className="social_team pull-left">
                          <ul className="social margin-bottom-none">
                            <li className="margin-bottom-none">
                              <a className="sc-1" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-2" href="#"></a>
                            </li>
                            <li className="margin-bottom-none">
                              <a className="sc-5" href="#"></a>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="our_inventory margin-top-30 scroll_effect bounceIn">
                <h4>
                  <span>SEARCH</span> OUR INVENTORY
                </h4>
                <div className="make_inventory clearfix margin-vertical-25 padding-top-25 padding-horizontal-20 padding-bottom-30">
                  <form method="post" action="#">
                    <div className="select-wrapper row clearfix">
                      <div className="col-md-3">
                        <div className="my-dropdown make-dropdown">
                          <select
                            name="make"
                            className="css-dropdowns"
                            tabindex="1"
                          >
                            <option value="">Make</option>
                            <option>Lorem</option>
                            <option>ipsum</option>
                            <option>dolor</option>
                            <option>sit</option>
                            <option>amet</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="min-price select-dropdown">
                          <div className="my-dropdown min-price-dropdown min-dropdown">
                            <select
                              name="min-price"
                              className="css-dropdowns"
                              tabindex="1"
                            >
                              <option value="">Min Year</option>
                              <option>2015</option>
                              <option>2014</option>
                              <option>2013</option>
                              <option>2012</option>
                              <option>2011</option>
                              <option>2010</option>
                              <option>2009</option>
                              <option>2008</option>
                              <option>2007</option>
                              <option>2006</option>
                              <option>2005</option>
                              <option>2004</option>
                            </select>
                          </div>
                          <span className="my-dropdown">to</span>
                          <div className="my-dropdown max-price-dropdown min-dropdown">
                            <select
                              name="max-price"
                              className="css-dropdowns"
                              tabindex="1"
                            >
                              <option value="">Max Year</option>
                              <option>2015</option>
                              <option>2014</option>
                              <option>2013</option>
                              <option>2012</option>
                              <option>2011</option>
                              <option>2010</option>
                              <option>2009</option>
                              <option>2008</option>
                              <option>2007</option>
                              <option>2006</option>
                              <option>2005</option>
                              <option>2004</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="my-dropdown make-dropdown">
                          <select
                            name="body_style"
                            className="css-dropdowns"
                            tabindex="1"
                          >
                            <option value="">Body Style</option>
                            <option>Cargo</option>
                            <option>Compact</option>
                            <option>Convertible</option>
                            <option>Coupe</option>
                            <option>Hatchback</option>
                            <option>Minivan</option>
                            <option>Sedan</option>
                            <option>SUV</option>
                            <option>Truck</option>
                            <option>Van</option>
                            <option>Wagon</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="my-dropdown make-dropdown">
                          <select
                            name="transmission"
                            className="css-dropdowns"
                            tabindex="1"
                          >
                            <option value="">Transmission</option>
                            <option>Automatic</option>
                            <option>Manual</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="my-dropdown make-dropdown">
                          <select
                            name="model"
                            className="css-dropdowns"
                            tabindex="1"
                          >
                            <option value="">Model</option>
                            <option>Lorem</option>
                            <option>ipsum</option>
                            <option>dolor</option>
                            <option>sit</option>
                            <option>amet</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="min-price select-dropdown">
                          <div className="my-dropdown min-price-dropdown min-dropdown">
                            <select
                              name="min-price"
                              className="css-dropdowns"
                              tabindex="1"
                            >
                              <option value="">Min Price</option>
                              <option>&lt; $1,000</option>
                              <option>&lt; $10,000</option>
                              <option>&lt; $20,000</option>
                              <option>&lt; $30,000</option>
                              <option>&lt; $40,000</option>
                              <option>&lt; $50,000</option>
                              <option>&lt; $60,000</option>
                              <option>&lt; $70,000</option>
                              <option>&lt; $80,000</option>
                              <option>&lt; $90,000</option>
                              <option>&lt; $100,000</option>
                            </select>
                          </div>
                          <span className="my-dropdown">to</span>
                          <div className="my-dropdown max-price-dropdown min-dropdown">
                            <select
                              name="max-price"
                              className="css-dropdowns"
                              tabindex="1"
                            >
                              <option value="">Max Price</option>
                              <option>&lt; $1,000</option>
                              <option>&lt; $10,000</option>
                              <option>&lt; $20,000</option>
                              <option>&lt; $30,000</option>
                              <option>&lt; $40,000</option>
                              <option>&lt; $50,000</option>
                              <option>&lt; $60,000</option>
                              <option>&lt; $70,000</option>
                              <option>&lt; $80,000</option>
                              <option>&lt; $90,000</option>
                              <option>&lt; $100,000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="min-price select-dropdown">
                          <div className="my-dropdown min-price-dropdown min-dropdown">
                            <select
                              name="min-mileage"
                              className="css-dropdowns"
                              tabindex="1"
                            >
                              <option value="">Min Mileage</option>
                              <option>0</option>
                              <option>&lt; 10,000</option>
                              <option>&lt; 20,000</option>
                              <option>&lt; 30,000</option>
                              <option>&lt; 40,000</option>
                              <option>&lt; 50,000</option>
                              <option>&lt; 60,000</option>
                              <option>&lt; 70,000</option>
                              <option>&lt; 80,000</option>
                              <option>&lt; 90,000</option>
                              <option>&lt; 100,000</option>
                              <option>&lt; 120,000</option>
                              <option>&lt; 150,000</option>
                            </select>
                          </div>
                          <span className="my-dropdown">to</span>
                          <div className="my-dropdown max-price-dropdown min-dropdown">
                            <select
                              name="max-mileage"
                              className="css-dropdowns"
                              tabindex="1"
                            >
                              <option value="">Max Mileage</option>
                              <option>0</option>
                              <option>&lt; 10,000</option>
                              <option>&lt; 20,000</option>
                              <option>&lt; 30,000</option>
                              <option>&lt; 40,000</option>
                              <option>&lt; 50,000</option>
                              <option>&lt; 60,000</option>
                              <option>&lt; 70,000</option>
                              <option>&lt; 80,000</option>
                              <option>&lt; 90,000</option>
                              <option>&lt; 100,000</option>
                              <option>&lt; 120,000</option>
                              <option>&lt; 150,000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="my-dropdown">
                          <input
                            className="full-width"
                            type="search"
                            value=""
                            placeholder="Refine with keywords"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="select-wrapper row clearfix">
                    <div className="form-element clearfix">
                      <input type="checkbox" id="check" />
                      <label for="check">Certified</label>
                    </div>
                    <div className="form-element">
                      <input type="checkbox" id="check2" />
                      <label for="check2">CARFAX® Verified</label>
                    </div>
                    <div className="form-element">
                      <input type="checkbox" id="check3" />
                      <label for="check3">Brand New</label>
                    </div>
                    <div className="form-element pull-right">
                      <input type="submit" value="Find My New Vehicle" />
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <!--container ends--> */}
        </section>
        {/* <!--content ends--> */}
        <div className="clearfix"></div>
      </div>
    );
  }
}

// export default Team;
export default connect(mapStateToProps, mapDispatchToProps)(Team);
