import React, { Component } from "react";
import Calculator from "../components/common/financingCalculator";
import { Row, Col } from "reactstrap";
import PageMetaTags from "../components/common/pageMetaTags";

class FinancingCalculator extends Component {
  state = {};
  render() {
    return (
      <div>
        <PageMetaTags />
        <section id="secondary-banner" className="dynamic-image-5">
          {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <h2>Apply For Finance</h2>
                {/* <!-- <h4>We are conveniently located just off Santa Monica Blvd</h4> --> */}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                {/* <ul className="breadcrumb">
            <li>
              <a href="#">Home</a>
            </li>
            <li>Contact</li>
          </ul> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!--secondary-banner ends--> */}
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content">
          <div className="container">
            <div className="inner-page">
              <Row>
                <Col xs="3"></Col>
                <Col xs="6">
                  <div className="financing_calculator margin-top-40">
                    <h3 className="mb-5 theme-color">FINANCING CALCULATOR</h3>
                    <Calculator />
                  </div>
                </Col>
                <Col xs="3"></Col>
              </Row>
            </div>
          </div>
          {/* <!--container ends--> */}
        </section>
        {/* <!--content ends--> */}
        <div className="clearfix"></div>
        <div className="back_to_top">
          <img src="images/arrow-up.png" alt="scroll up" />
        </div>
      </div>
    );
  }
}

export default FinancingCalculator;
