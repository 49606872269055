import React, { Component } from "react";
import Joi from "joi";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { webSocket } from "../../utils/globalHandlers";
import { setupChatWebSocket, closeChatWebSockets } from "../../utils/webSocket";
import {
  startChat,
  receiveMsg,
  openChat,
  removeChat,
  offlineChat,
  sendMsg,
} from "../../redux/chat/action";
import { connect } from "react-redux";
import { getDealership } from "../../services/dealershipService";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { bootstrapToHex } from "../../utils/utils";
import NumberFormat from "react-number-format";
import { closeChat2 } from "../../services/chatService";

const mapStateToProps = (state) => ({
  ...state,
  chatContent: state.chatReducer.chatContent,
  chatDetails: state.chatReducer.chats[0],
  // id: state.chatReducer.chatContent,
});

const mapDispatchToProps = (dispatch) => ({
  startChat: (chatObject) => dispatch(startChat(chatObject)),
  openChat: (id) => dispatch(openChat(id)),
  receiveMessage: (id, chatMsg) => dispatch(receiveMsg(id, chatMsg)),
  removeChat: (id) => dispatch(removeChat(id)),
  offlineChat: (id) => dispatch(offlineChat(id)),
  sendMsg: (id, chatMsg) => dispatch(sendMsg(id, chatMsg)),
});

class ChatStart extends Component {
  state = {
    data: { firstName: null, lastName: null, phoneNumber: null, email: null },
    generalError: "",
    loading: false,
    failed: false,
  };
  schemaObj = {
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().allow(null).label("Last Name"),
    phoneNumber: Joi.string().allow(null).label("Phone Number"),
    email: Joi.string().email({ tlds: false }).required().label("Email"),
  };
  schema = Joi.object(this.schemaObj).options({ stripUnknown: true });

  render() {
    return (
      // <Card body outline color={"info"} className="border">
      // <Card body outline className="border">
      <CardBody>
        <Form className="mt-0 pt-0" id="chatform">
          {this.state.generalError && (
            <Row className="mt-3 mb-3 text-danger">
              <Col xs="12">
                <div>{this.state.generalError}</div>
              </Col>
            </Row>
          )}
          <h4 className="mb-4">
            Chat with a salesperson
            {/* <i className="fa fa-wechat ml-2"></i> */}
          </h4>
          <Row>
            <Col md="12">
              {/* <div className="form-group"> */}
              <FormGroup>
                {/* <Label style={{ all: "initial" }} htmlFor="firstName">
                    First Name
                  </Label> */}
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={this.state.data.firstName}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      data: { ...this.state.data, firstName: e.target.value },
                    })
                  }
                  required={true}
                  className="form-control mb-4"
                  placeholder="First Name"
                />
              </FormGroup>

              {/* </div> */}
              <FormGroup>
                {/* <div className="form-group"> */}
                <Input
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={this.state.data.lastName}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      data: { ...this.state.data, lastName: e.target.value },
                    })
                  }
                  required={true}
                  className="form-control mb-4"
                />
                {/* </div> */}
              </FormGroup>
              <div className="form-group">
                <NumberFormat
                  placeholder="Phone Number"
                  customInput={Input}
                  thousandSeparator={false}
                  prefix=""
                  isNumericString={false}
                  name="phoneNumber"
                  id="phoneNumber"
                  className="form-control mb-4"
                  format="(###) ###-####"
                  mask="_"
                  value={this.state.data.phoneNumber}
                  onValueChange={(e) =>
                    this.setState({
                      ...this.state,
                      data: {
                        ...this.state.data,
                        phoneNumber: e.formattedValue,
                      },
                    })
                  }
                  required={true}
                />
              </div>
              <div className="form-group">
                <Input
                  placeholder="Email"
                  type="text"
                  name="email"
                  id="email"
                  value={this.state.data.email}
                  onChange={(e) =>
                    this.setState({
                      ...this.state,
                      data: { ...this.state.data, email: e.target.value },
                    })
                  }
                  required={true}
                  className="form-control mb-4"
                />
              </div>
              <Button
                disabled={this.state.loading || this.state.failed}
                type="button"
                color="info"
                // size={size}
                className="default-btn"
                // className="theme-bg-color"
                block={true}
                onClick={async () => {
                  try {
                    const { error } = this.schema.validate(this.state.data, {
                      abortEarly: false,
                    });
                    if (error) {
                      let s = "";
                      for (let item of error.details) {
                        s += item.message + "\n";
                      }
                      this.setState({ ...this.state, generalError: s });
                      return;
                    }
                    this.setState({ loading: true });
                    const { data: dealership } = await getDealership();
                    webSocket[0] = setupChatWebSocket(
                      JSON.stringify({
                        data: {
                          ...this.state.data,
                          dealershipId: dealership.id,
                        },
                        messageType: "Start Chat",
                      })
                    );
                    setTimeout(() => {
                      if (this.state.loading) {
                        closeChatWebSockets();
                        this.setState({
                          failed: true,
                          loading: false,
                          generalError:
                            "Sorry, no one is available at the moment. We will get back to you soon.",
                        });
                      }
                    }, 30000);
                    webSocket[0].onmessage = (e) => {
                      // console.log(e.data);
                      const { messageType, data } = JSON.parse(e.data);
                      const { id, message } = data;
                      switch (messageType) {
                        case "Start Chat":
                          this.setState({ loading: false });
                          this.props.startChat({
                            id,
                            name: "Dealership",
                            status: "online",
                            thumb: null,
                            recent: null,
                            excerpt: "",
                            newChat: true,
                            chatHistory: [{ from: [message], to: [] }],
                          });
                          this.props.openChat(id);
                          break;
                        case "Continue Chat":
                          this.props.receiveMessage(id, message);
                          break;
                        case "Close Chat":
                          this.props.receiveMessage(id, message);
                          // this.props.removeChat(this.props.id);
                          this.props.offlineChat(id);
                          closeChat2(id, this.props.chatDetails.chatHistory);
                          // closeChatWebSockets();
                          break;
                      }
                    };
                    // this.props.toggleModal();
                  } catch (err) {
                    this.setState({
                      generalError:
                        "Sorry, something went wrong. Please, try againg later.",
                      failed: true,
                    });
                  }
                }}
              >
                {this.state.loading ? (
                  <div>
                    <span>Waiting for a salesperson</span>
                    <Loader
                      type="ThreeDots"
                      // type="Watch"
                      // color="#00BFFF"
                      color={bootstrapToHex("white")}
                      height={30}
                      width={30}
                    />
                  </div>
                ) : (
                  "Start"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
      // </Card>
    );
  }
}

// export default ChatStart;
export default connect(mapStateToProps, mapDispatchToProps)(ChatStart);
