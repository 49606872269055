import { wsUrl } from "../config.js";
import { webSocket } from "./globalHandlers.js";

export function setupChatWebSocket(firstMessage) {
  const wsConnection = new WebSocket(wsUrl);

  wsConnection.onopen = () => {
    wsConnection.send(firstMessage);
    console.log("first message sent");
  };

  wsConnection.onerror = (error) => {
    console.log(`WebSocket error: ${error}`);
  };

  return wsConnection;
}

export function closeChatWebSockets() {
  if (webSocket[0]) webSocket[0].close();
  webSocket.splice(0, webSocket.length);
}
