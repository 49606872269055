import http from "./httpService";
import { apiUrl } from "../config.js";
const apiEndpoint = apiUrl + "/makes";

function theUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMakes() {
  return http.get(apiEndpoint);
}

export function getMakesAsListItems() {
  return http.get(apiEndpoint + "/availables");
}

export function saveMake(make) {
  if (make) return http.post(apiEndpoint, make);
}

export function updateMake(make) {
  if (make) return http.put(apiEndpoint, make);
}

export function deleteMake(makeId) {
  if (makeId) return http.delete(theUrl(makeId));
}

export function getMakeById(makeId) {
  if (makeId) return http.get(theUrl(makeId));
}

export function getMakeByName(makeName) {
  if (makeName) return http.get(apiEndpoint + "/names/" + makeName);
}
