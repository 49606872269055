import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { setShowingVehicles } from "../../redux/vehicles/action";
import Calculator from "../common/financingCalculator";

const mapStateToProps = (state) => ({
  filteredVehicles: state.vehiclesReducer.filteredVehicles,
  showingVehicles: state.vehiclesReducer.showingVehicles,
});
const mapDispatchToProps = (dispatch) => ({
  setShowingVehicles: (vehicles) => dispatch(setShowingVehicles(vehicles)),
  // setFilteredVehicles: (vehicles) => dispatch(setFilteredVehicles(vehicles)),
});
const mileageOptions = [
  "< 10,000",
  "< 20,000",
  "< 40,000",
  "< 60,000",
  "< 80,000",
  "< 100,000",
  "< 120,000",
  "< 150,000",
  "< 200,000",
];
const priceOptions = [
  "< $5,000",
  "< $10,000",
  "< $15,000",
  "< $20,000",
  "< $25,000",
  "< $30,000",
  "< $35,000",
  "< $40,000",
  "< $45,000",
  "< $50,000",
];

class InventorySidebar extends Component {
  state = {};
  render() {
    if (this.props.filteredVehicles.length === 0) return null;
    const { filteredVehicles, showingVehicles } = this.props;
    return (
      <div className="left_inventory">
        <h3 className="margin-bottom-25">SEARCH OUR INVENTORY</h3>
        <form className="clearfix select-form padding-bottom-50">
          <div className="my-dropdown min-years-dropdown max-dropdown">
            <select
              name="year"
              className="css-dropdowns"
              tabIndex="1"
              onChange={(e) => {
                this.props.setShowingVehicles(
                  e.currentTarget.value
                    ? _.filter(
                        [...filteredVehicles],
                        ["productionYear", parseInt(e.currentTarget.value)]
                      )
                    : [...filteredVehicles]
                );
              }}
            >
              <option value="">All Years</option>
              {_.orderBy(_.uniqBy(filteredVehicles, "productionYear"), [
                "productionYear",
              ]).map((v) => {
                return (
                  <option value={v.productionYear}>{v.productionYear}</option>
                );
              })}
            </select>
          </div>
          <div className="my-dropdown min-years-dropdown max-dropdown">
            <select
              name="make"
              className="css-dropdowns"
              tabIndex="1"
              onChange={(e) => {
                this.props.setShowingVehicles(
                  e.currentTarget.value
                    ? _.filter(
                        [...filteredVehicles],
                        ["makeName", e.currentTarget.value]
                      )
                    : [...filteredVehicles]
                );
              }}
            >
              <option value="">All Makes</option>
              {_.orderBy(_.uniqBy(filteredVehicles, "makeName"), [
                "makeName",
              ]).map((v) => {
                return <option value={v.makeName}>{v.makeName}</option>;
              })}
            </select>
          </div>
          <div className="my-dropdown min-years-dropdown max-dropdown">
            <select
              name="body_style"
              className="css-dropdowns"
              tabIndex="1"
              onChange={(e) => {
                this.props.setShowingVehicles(
                  e.currentTarget.value
                    ? _.filter(
                        [...filteredVehicles],
                        ["bodyType", e.currentTarget.value]
                      )
                    : [...filteredVehicles]
                );
              }}
            >
              <option value="">All Body Styles</option>
              {_.orderBy(_.uniqBy(filteredVehicles, "bodyType"), [
                "bodyType",
              ]).map((v) => {
                return <option value={v.bodyType}>{v.bodyType}</option>;
              })}
            </select>
          </div>
          <div className="my-dropdown min-years-dropdown max-dropdown">
            <select
              name="mileage"
              className="css-dropdowns"
              tabIndex="1"
              onChange={(e) => {
                this.props.setShowingVehicles(
                  e.currentTarget.value
                    ? _.filter(
                        [...filteredVehicles],
                        (v) =>
                          v.mileage <=
                          parseInt(
                            e.currentTarget.value
                              .replace("< ", "")
                              .replace(",", "")
                          )
                      )
                    : [...filteredVehicles]
                );
              }}
            >
              <option value="">All Mileage</option>
              {mileageOptions.map((m) => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
            </select>
          </div>
          <div className="my-dropdown min-years-dropdown max-dropdown">
            <select
              name="transmission"
              className="css-dropdowns"
              tabIndex="1"
              onChange={(e) => {
                this.props.setShowingVehicles(
                  e.currentTarget.value
                    ? _.filter(
                        [...filteredVehicles],
                        ["transmissionType", e.currentTarget.value]
                      )
                    : [...filteredVehicles]
                );
              }}
            >
              <option value="">All Transmissions</option>
              {_.orderBy(_.uniqBy(filteredVehicles, "transmissionType"), [
                "transmissionType",
              ]).map((v) => {
                return (
                  <option value={v.transmissionType}>
                    {v.transmissionType}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <div className="my-dropdown min-years-dropdown max-dropdown">
          <select
            name="fuel_economy"
            className="css-dropdowns"
            tabindex="1"
          >
            <option value="">Search by Fuel Economy</option>
            <option>10-20 MPG</option>
            <option>20-30 MPG</option>
            <option>30-40 MPG</option>
            <option>40-50 MPG</option>
            <option>50-60 MPG</option>
          </select>
        </div> */}
          <div className="my-dropdown min-years-dropdown max-dropdown">
            <select
              name="price"
              className="css-dropdowns"
              tabIndex="1"
              onChange={(e) => {
                this.props.setShowingVehicles(
                  e.currentTarget.value
                    ? _.filter(
                        [...filteredVehicles],
                        (v) =>
                          v.askingPrice <=
                          parseInt(
                            e.currentTarget.value
                              .replace("< $", "")
                              .replace(",", "")
                          )
                      )
                    : [...filteredVehicles]
                );
              }}
            >
              <option value="">All Prices</option>
              {priceOptions.map((m) => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
            </select>
          </div>
          {/* <input
          type="reset"
          value="Reset Search Filters"
          className="pull-left btn-inventory margin-bottom-none md-button"
        /> */}
        </form>
        <div className="side-content row">
          {/* <div className="list col-md-12 col-sm-3 padding-bottom-50">
          <h3 className="margin-bottom-25">YEAR</h3>
          <ul>
            <li>
              <a href="#">
                <span>
                  2021 <strong>(28)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2020 <strong>(172)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2019 <strong>(102)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2018 <strong>(98)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2017 <strong>(91)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2016 <strong>(27)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2015 <strong>(25)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2014 <strong>(15)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2013 <strong>(9)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  2012 <strong>(3)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">View More...</a>
            </li>
          </ul>
        </div>
        <div className="list col-md-12 col-sm-3 padding-bottom-50">
          <h3 className="margin-bottom-25">MAKE</h3>
          <ul>
            <li>
              <a href="#">
                <span>
                  Acura <strong>(2)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  BMW <strong>(4)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Buick <strong>(1)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Cadillac <strong>(6)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Chevrolet <strong>(19)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Chrysler <strong>(7)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Dodge <strong>(14)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Ford <strong>(15)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  GMC <strong>(9)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">
                <span>
                  Hummer <strong>(3)</strong>
                </span>
              </a>
            </li>
            <li>
              <a href="#">View More...</a>
            </li>
          </ul>
        </div> */}
          <div className="financing_calculator col-md-12 col-sm-6 padding-bottom-50">
            <h3 className="margin-bottom-25">FINANCING CALCULATOR</h3>
            <Calculator />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventorySidebar);
