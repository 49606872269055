// SETTINGS CONSTANTS
export const SET_DEALERSHIP = "SET_DEALERSHIP";
// export const LOGO_BG = "LOGO_BG";
// export const NAVBAR_BG = "NAVBAR_BG";
// export const SIDEBAR_BG = "SIDEBAR_BG";
// export const THEME = "THEME";
// export const DIRECTION = "DIRECTION";
// export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
// export const HEADER_POSITION = "HEADER_POSITION";
// export const LAYOUT = "LAYOUT";
// export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

// CHAT CONSTANTS
export const START_CHAT = "START_CHAT";
export const SELECTED_CHAT = "SELECTED_CHAT";
export const SEARCH_USER = "SEARCH_USER";
export const MSG_SUBMIT = "MSG_SUBMIT";
export const MSG_RECEIVE = "MSG_RECEIVE";
export const REMOVE_CHAT = "REMOVE_CHAT";
export const OFFLINE_CHAT = "OFFLINE_CHAT";

// VEHICLES CONSTANTS
export const SET_VEHICLES = "SET_VEHICLES";
export const SET_FILTERED_VEHICLES = "SET_FILTERED_VEHICLES";
export const SET_SELECTED_VEHICLE = "SET_SELECTED_VEHICLE";
export const SET_SHOWING_VEHICLES = "SET_SHOWING_VEHICLES";
// export const UPDATE_CONTACT = "UPDATE_CONTACT";
// export const CONTACT_DETAILS = "CONTACT_DETAILS";
// export const EDIT_CONTACT = "EDIT_CONTACT";
// export const SET_VISIBILITY_FILTER = "SET_VISIBILITY_FILTER";
// export const FILTER_CONTACT = "FILTER_CONTACT";
// export const TOGGLE_STARRED_CONTACT = "TOGGLE_STARRED_CONTACT";
// export const DELETE_CONTACT = "DELETE_CONTACT";
