import React from "react";
import JoiBase from "joi";
import JoiDate from "@joi/date";
import { Card, CardBody, Row, Col, Form } from "reactstrap";
import myForm from "../../components/common/form";
import { getListItemByName } from "../../services/listItemService";
import "../../assets/scss/all/custom/dms.css";
import _ from "lodash";
import { saveCustomer } from "../../services/customerService";
import {
  saveFinanceApplication,
  saveWebsiteMessage,
} from "../../services/websiteMessageService";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class FinanceApplication extends myForm {
  state = {
    data: {
      firstName: this.props.firstName || null,
      lastName: this.props.lastName || null,
      address1: this.props.address1 || null,
      address2: this.props.address2 || null,
      city: this.props.city || null,
      province: this.props.province || null,
      postalCode: this.props.postalCode || null,
      phoneNumber1: this.props.phoneNumber1 || null,
      email: this.props.email || null,
      dLNo: this.props.dLNo || null,
      sex: this.props.sex || null,
      customerType: "individual",
      customerState: "Finance Applicant",
      dateOfBirth: this.props.dateOfBirth || null,
      dependantsCount: null,
      sin: null,
      homeResidenceType: null,
      formerAddress: null,
      formerCity: null,
      formerProvince: null,
      employerName: null,
      employerAddress: null,
      businessPhoneNumber: null,
      businessNature: null,
      employmentYears: null,
      employmentMonths: null,
      occupation: null,
      monthlyIncome: null,
      formerEmployerName: null,
      coSignerFirstName: null,
      coSignerLastName: null,
      coSignerSin: null,
      coSignerEmployerName: null,
      coSignerEmployerAddress: null,
      coSignerBusinessPhoneNumber: null,
      coSignerBusinessNature: null,
      coSignerEmploymentYears: null,
      coSignerEmploymentMonths: null,
      coSignerOccupation: null,
      coSignerMonthlyIncome: null,
      rentOrMortgageAmount: null,
      coSignerType: null,
      coSignerDateOfBirth: null,
    },
    lists: {
      provinces: [],
      cities: [],
    },
    errors: this.props.errors || {},
    saving: false,
    saved: false,
  };
  schemaObj = {
    firstName: Joi.string().allow(null).allow("").label("First Name"),
    lastName: Joi.string().allow(null).allow("").label("Last Name"),
    address1: Joi.string().allow(null).allow("").label("Address 1"),
    address2: Joi.string().allow(null).allow("").label("Address 2"),
    city: Joi.string().allow(null).allow("").label("City"),
    province: Joi.string().allow(null).allow("").label("Province"),
    postalCode: Joi.string()
      .min(6)
      .max(7)
      .allow(null)
      .allow("")
      .label("Postal Code"),
    phoneNumber1: Joi.string().allow(null).allow("").label("Phone Number 1"),
    email: Joi.string()
      .email({ tlds: false })
      .allow(null)
      .allow("")
      .label("Email"),
    dLNo: Joi.string().allow(null).allow("").label("Drivers License Number"),
    sex: Joi.string().allow(null).allow("").label("Sex"),
    customerType: Joi.string().required().label("Customer Type"),
    dateOfBirth: Joi.date()
      .format("YYYY-MM-DD")
      .min("1900-01-01")
      .max("now")
      .allow(null)
      .allow("")
      .label("Date of Birth"),
    dependantsCount: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Number of Dependants"),
    sin: Joi.string()
      .length(9)
      .pattern(/^[0-9]+$/)
      .allow(null)
      .allow("")
      .label("Applicant's Social Insurance Number")
      .messages({
        "string.pattern.base": `Only digits are acceptable in this field`,
      }),
    homeResidenceType: Joi.string()
      .allow(null)
      .allow("")
      .label("Home Residence Type"),
    formerAddress: Joi.string().allow(null).allow("").label("Former Address"),
    formerCity: Joi.string().allow(null).allow("").label("Former City"),
    formerProvince: Joi.string().allow(null).allow("").label("Former Province"),
    employerName: Joi.string()
      .allow(null)
      .allow("")
      .label("Present Employer Name"),
    employerAddress: Joi.string()
      .allow(null)
      .allow("")
      .label("Employer Address"),
    businessPhoneNumber: Joi.string()
      .allow(null)
      .allow("")
      .label("Business Phone Number"),
    businessNature: Joi.string()
      .allow(null)
      .allow("")
      .label("Nature of Business"),
    employmentYears: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Current Employment Years"),
    employmentMonths: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Current Employnent Months"),
    occupation: Joi.string().allow(null).allow("").label("Occupation"),
    monthlyIncome: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Gross Monthy Income"),
    formerEmployerName: Joi.string()
      .allow(null)
      .allow("")
      .label("Former Employer Name"),
    coSignerFirstName: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant First Name"),
    coSignerLastName: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant Last Name"),
    coSignerSin: Joi.string()
      .length(9)
      .pattern(/^[0-9]+$/)
      .allow(null)
      .allow("")
      .label("Co-Applicant Social Insurance Number")
      .messages({
        "string.pattern.base": `Only digits are acceptable in this field`,
      }),
    coSignerEmployerName: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant Employer Name"),
    coSignerEmployerAddress: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant Employer Address"),
    coSignerBusinessPhoneNumber: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant Business Phone Number"),
    coSignerBusinessNature: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant Business Nature"),
    coSignerEmploymentYears: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Co-Applicant Employment Years"),
    coSignerEmploymentMonths: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Co-Applicant Employment Months"),
    coSignerOccupation: Joi.string()
      .allow(null)
      .allow("")
      .label("Co-Applicant Occupation"),
    coSignerMonthlyIncome: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Co-Applicant Gross Monthly Income"),
    rentOrMortgageAmount: Joi.number()
      .integer()
      .allow(null)
      .allow("")
      .label("Rent/Mortgage Amount"),
    coSignerType: Joi.string().allow(null).allow("").label("Co-Applicant Type"),
    coSignerDateOfBirth: Joi.date()
      .format("YYYY-MM-DD")
      .min("1900-01-01")
      .max("now")
      .allow(null)
      .allow("")
      .label("Co-Applicant Birth Date"),
  };
  schema = Joi.object(this.schemaObj).options({ stripUnknown: true });

  doSubmit = async () => {
    try {
      this.setState({ saving: true });
      await saveFinanceApplication({
        ...this.state.data,
        message:
          "I have filled the finance application form in your website. Would you please contact me?",
      });
      // await saveCustomer(
      //   _.omit(this.state.data, ["id", "createdAt", "createdBy"])
      // );
      // await saveWebsiteMessage({
      //   name: this.state.data.firstName + " " + this.state.data.lastName,
      //   firstName: this.state.data.firstName,
      //   lastName: this.state.data.lastName,
      //   phoneNumber: this.state.data.phoneNumber1,
      //   email: this.state.data.email,
      //   message:
      //     "I have filled the finance application form in your website. Would you please contact me?",
      // });
      this.setState({ saved: true, saving: false });
    } catch (ex) {
      this.setState({ saving: false });
      if (ex.response /* && ex.response.status === 400*/) {
        const errors = { ...this.state.errors };
        errors.serverError = ex.response.data;
        this.setState({ errors });
      }
      return ex.response
        ? ex.response.status + " : " + ex.response.data
        : ex.name + " : " + ex.message;
    }
  };

  async componentDidMount() {
    try {
      this.initialData = { ...this.state.data };
      const { data: provinces } = await getListItemByName("province");
      const { data: cities } = await getListItemByName("city");
      const lists = {
        provinces,
        cities,
      };
      this.setState({
        lists,
      });
    } catch (ex) {
      console.error(ex);
      if (ex.response /* && ex.response.status === 400*/) {
        const errors = { ...this.state.errors };
        errors.serverError = ex.response.data;
        this.setState({ errors });
      }
      const generalError = ex.response
        ? ex.response.status + " : " + ex.response.data
        : ex.name + " : " + ex.message;
      this.setState({ generalError });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.generalError &&
      prevState.generalError === this.state.generalError
    )
      this.setState({ generalError: "" });
  }

  render() {
    // const themeColor = "info";
    const formerCitiesList = this.state.lists.cities.filter(
      (c) => c.province === this.state.data.formerProvince
    );
    // console.log(formerCitiesList);
    return (
      // <Col sm="12">
      <Card body outline className="border theme-color">
        <CardBody>
          {this.state.saved ? (
            <h3>
              Application form submitted successfully. We will get back to you
              soon.
            </h3>
          ) : (
            <Form
              style={{ color: "gray" }}
              className="mt-0 pt-0"
              id="inventoryform"
            >
              <Row className="pb-4 theme-border-color theme-color border-bottom">
                {/* <Col xs="6"> */}
                <span className="h2">Credit Application</span>
                {/* </Col> */}
                {/* <Col xs="6" className="center-block text-right">
                  {this.renderButton(
                    "Submit",
                    "submit",
                    null,
                    "md",
                    "text-uppercase pl-3 pr-3 theme-bg-color",
                    false,
                    this.handleSubmit
                  )}
                </Col> */}
              </Row>
              {this.state.generalError && (
                <Row>
                  <Col xs="12">
                    <div className="mt-3 text-danger">
                      {this.state.generalError}
                    </div>
                  </Col>
                </Row>
              )}

              {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
              <h4 className="mt-4">Applicant Information</h4>
              <Row className="mt-4 mb-4">
                <Col md="12" lg="6">
                  {this.renderInput("firstName", "First Name", "text", false)}
                  {this.renderInput("lastName", "Last Name", "text", false)}
                  {this.renderInputNumber(
                    "phoneNumber1",
                    "Phone Number 1",
                    "tel",
                    false
                  )}
                  {this.renderInput("email", "Email", "text", false)}
                  {this.renderSelect("sex", "Sex", [
                    { id: 1, name: "Male" },
                    { id: 2, name: "Female" },
                    { id: 2, name: "N/A" },
                  ])}
                  {this.renderInput(
                    "dateOfBirth",
                    "Date of Birth",
                    "text",
                    false,
                    null,
                    null,
                    "YYYY-MM-DD"
                  )}
                  {this.renderInput("address1", "Address 1", "text", false)}
                  {this.renderInput("address2", "Address 2", "text", false)}
                  {this.renderSelect(
                    "province",
                    "Province",
                    this.state.lists.provinces
                  )}
                  {this.renderInputSelect(
                    "city",
                    "City",
                    this.state.lists.cities.filter(
                      (c) => c.province === this.state.data.province
                    )
                  )}
                  {this.renderInput(
                    "postalCode",
                    "Postal Code",
                    "text",
                    false,
                    true
                  )}
                  {this.renderInput(
                    "dLNo",
                    "Drivers Licence Number",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "dependantsCount",
                    "Number of Dependants",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "sin",
                    "Social Insurance Number",
                    "text",
                    false
                  )}
                </Col>
                <Col md="12" lg="6">
                  {this.renderSelect(
                    "homeResidenceType",
                    "Home Residence Type",
                    [
                      { id: 1, name: "Rent" },
                      { id: 2, name: "Own" },
                      { id: 3, name: "Room & Board" },
                    ]
                  )}
                  {this.renderInputNumber(
                    "rentOrMortgageAmount",
                    "Rent/Mortgage Amount",
                    "text",
                    false,
                    "$"
                  )}
                  {this.renderInput(
                    "formerAddress",
                    "Former Address (if less than 2 years at present)",
                    "text",
                    false
                  )}
                  {this.renderSelect(
                    "formerProvince",
                    "Former Province",
                    this.state.lists.provinces
                  )}
                  {this.renderInputSelect(
                    "formerCity",
                    "Former City",
                    formerCitiesList
                  )}
                  {this.renderInput(
                    "employerName",
                    "Present Employer Name",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "employerAddress",
                    "Present Employer Address",
                    "text",
                    false
                  )}
                  {this.renderInputNumber(
                    "businessPhoneNumber",
                    "Business Phone Number",
                    "tel",
                    false
                  )}
                  {this.renderInput(
                    "businessNature",
                    "Nature of Business",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "employmentYears",
                    "Current Employment Years",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "employmentMonths",
                    "Current Employment Months",
                    "text",
                    false
                  )}
                  {this.renderInput("occupation", "Occupation", "text", false)}
                  {this.renderInputNumber(
                    "monthlyIncome",
                    "Gross Monthly Income",
                    "text",
                    false,
                    "$"
                  )}
                  {this.renderInput(
                    "formerEmployerName",
                    "Former Employer Name (if less than 2 years at present)",
                    "text",
                    false
                  )}
                </Col>
              </Row>
              <h4>Co-Applicant Information</h4>
              <Row className="mt-4">
                <Col md="12" lg="6">
                  {this.renderSelect("coSignerType", "Co-Applicant Type", [
                    { id: 1, name: "Spouse" },
                    { id: 2, name: "Other" },
                  ])}

                  {this.renderInput(
                    "coSignerFirstName",
                    "First Name",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerLastName",
                    "Last Name",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerSin",
                    "Social Insurance Number",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerDateOfBirth",
                    "Birth Date",
                    "text",
                    false,
                    null,
                    null,
                    "YYYY-MM-DD"
                  )}
                  {this.renderInput(
                    "coSignerEmployerName",
                    "Employer Name",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerEmployerAddress",
                    "Employer Address",
                    "text",
                    false
                  )}
                </Col>
                <Col md="12" lg="6">
                  {this.renderInputNumber(
                    "coSignerBusinessPhoneNumber",
                    "Business Phone Number",
                    "tel",
                    false
                  )}
                  {this.renderInput(
                    "coSignerBusinessNature",
                    "Nature of Business",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerEmploymentYears",
                    "Current Employment Years",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerEmploymentMonths",
                    "Current Employment Months",
                    "text",
                    false
                  )}
                  {this.renderInput(
                    "coSignerOccupation",
                    "Occupation",
                    "text",
                    false
                  )}
                  {this.renderInputNumber(
                    "coSignerMonthlyIncome",
                    "Gross Monthly Income",
                    "text",
                    false,
                    "$"
                  )}
                  {this.renderButton(
                    this.state.saving ? "Submitting ..." : "Submit",
                    "submit",
                    null,
                    "md",
                    "text-uppercase pl-3 pr-3 theme-bg-color d-inline-block mt-4",
                    true,
                    this.handleSubmit,
                    this.state.saving
                  )}
                  {this.state.generalError && (
                    <Row>
                      <Col xs="12">
                        <div className="mt-3 text-danger">
                          {this.state.generalError}
                        </div>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </CardBody>
      </Card>
      // </Col>
    );
  }
}

export default FinanceApplication;
