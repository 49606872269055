import React, { Component } from "react";
import { connect } from "react-redux";
import image1 from "../images/faq-img1.jpg";
import { setDealership } from "../redux/settings/action";
import { getDealership } from "../services/dealershipService";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

const mapDispatchToProps = (dispatch) => ({
  setDealership: (dealership) => dispatch(setDealership(dealership)),
});
class Faq extends Component {
  state = {};
  async componentDidMount() {
    if (!this.props.dealership.websiteSettings) {
      const { data: dealership } = await getDealership();
      this.props.setDealership(dealership);
    }
  }

  render() {
    if (!this.props.dealership.websiteSettings) return null;

    return (
      <div>
        <PageMetaTags />
        <section id="secondary-banner" className="dynamic-image-6">
          {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
          <div className="container">
            <h2>FAQ</h2>
            <h4>Frequently Asked Questions</h4>
            {/* <ul className="breadcrumb">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>FAQ</li>
                </ul> */}
          </div>
        </section>
        {/* <!--secondary-banner ends--> */}
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content faq">
          <div className="container">
            <div className="inner-page">
              <div className="row">
                {/* <!--FAQ LEFT--> */}
                {/* <div className="col-md-9 xs-padding-bottom-40"> */}
                <div className="xs-padding-bottom-40">
                  {/* <div className="list_faq clearfix">
                    <h5>Sort FAQ By:</h5>
                    <ul>
                      <li>
                        <a href="#All" data-action="sort">
                          All
                        </a>
                      </li>
                      <li>
                        <a href="#Electrical" data-action="sort">
                          Electrical
                        </a>
                      </li>
                      <li>
                        <a href="#Engine" data-action="sort">
                          Engine
                        </a>
                      </li>
                      <li>
                        <a href="#Mechanical" data-action="sort">
                          Mechanical
                        </a>
                      </li>
                      <li>
                        <a href="#Navigation" data-action="sort">
                          Navigation
                        </a>
                      </li>
                      <li>
                        <a href="#Sunroof" data-action="sort">
                          Sunroof
                        </a>
                      </li>
                      <li>
                        <a href="#Stereo" data-action="sort">
                          Stereo
                        </a>
                      </li>
                      <li>
                        <a href="#Wiring" data-action="sort">
                          Wiring
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <div className="accodian_panel margin-top-30">
                    <div
                      className="panel-group description-accordion faq-sort margin-bottom-none"
                      id="accordion"
                    >
                      {this.props.dealership.websiteSettings.faq.map(
                        (item, i) => (
                          <div
                            key={"faq" + i}
                            className="panel panel-default padding-top-20 padding-bottom-15"
                            data-categories="Electrical,Navigational,Wiring"
                          >
                            <div className="panel-heading padding-vertical-10 padding-horizontal-15">
                              <h4 className="panel-title padding-left-30">
                                <a
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  href={"#collapse" + i}
                                  className="collapsed"
                                >
                                  {item.question}
                                </a>
                              </h4>
                            </div>
                            <div
                              id={"collapse" + i}
                              className="panel-collapse collapse"
                              style={{ height: "0px" }}
                            >
                              <div className="panel-body">
                                {/* <!--Panel_body--> */}
                                <div className="faq_post padding-left-40">
                                  <div className="post-entry clearfix margin-top-10">
                                    <img
                                      src={image1}
                                      alt=""
                                      className="alignleft"
                                    />
                                    <p className="text-justify">
                                      {item.answer}
                                    </p>
                                  </div>
                                </div>
                                {/* <!--Panel_body--> */}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {/* <!--FAQ LEFT--> */}
                {/* <!--FAQ LEFT--> */}
                {/* <div className="col-md-3 xs-margin-top-30">
                  <div className="right_faq">
                    <div className="side-widget side-content">
                      <form
                        className="search-box padding-bottom-50"
                        role="search"
                      >
                        <h3>SEARCH</h3>
                        <div className="form-group">
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search for..."
                          />
                        </div>
                        <input
                          type="submit"
                          value="Search"
                          className="margin-top-10 margin-bottom-none md-button"
                        />
                      </form>
                      <div className="recent_posts_container padding-bottom-50">
                        <h3 className="recent_posts">RECENT POSTS</h3>
                        <div className="side-blog">
                          <img
                            src="images/blog-thumb-1.jpg"
                            className="alignleft"
                            alt=""
                          />
                          <strong>Most recent post</strong>
                          <p>
                            Lorem ipsum dolor sit amet, id melius accusam mea
                            ...
                          </p>
                        </div>
                        <div className="side-blog">
                          <img
                            src="images/blog-thumb-2.jpg"
                            className="alignleft"
                            alt=""
                          />
                          <strong>Second most recent post</strong>
                          <p>Oblique copiosae dissentiunt ea vim, ius ea ...</p>
                        </div>
                        <div className="side-blog">
                          <img
                            src="images/blog-thumb-3.jpg"
                            className="alignleft"
                            alt=""
                          />
                          <strong>Third most recent post</strong>
                          <p>Eos ad errem virtute convenire, ex sea hinc ...</p>
                        </div>
                        <div className="side-blog">
                          <img
                            src="images/blog-thumb-4.jpg"
                            className="alignleft"
                            alt=""
                          />
                          <strong>Fourth most recent post</strong>
                          <p>
                            Ea vis gloriatur efficiantur intellegebat, id ...
                          </p>
                        </div>
                        <div className="side-blog">
                          <img
                            src="images/blog-thumb-5.jpg"
                            className="alignleft"
                            alt=""
                          />
                          <strong>Fifth most recent post</strong>
                          <p>Sea ea semper eripuit inermis, nec ut elit ...</p>
                        </div>
                      </div>
                      <div className="list testimonial_div padding-bottom-50 xs-padding-bottom-none">
                        <h3>TESTIMONIALS</h3>
                        <div className="testimonial">
                          <ul className="testimonial_slider">
                            <li>
                              <blockquote className="style1">
                                <span>
                                  Happiness does not come from doing easy work
                                  but from the afterglow of satisfaction that
                                  comes after the achievement of a difficult
                                  task that demanded our best.
                                </span>
                                <strong>Theodore Isaac Rubin</strong>
                              </blockquote>
                            </li>
                            <li>
                              <blockquote className="style1">
                                <span>
                                  Happiness does not come from doing easy work
                                  but from the afterglow of satisfaction that
                                  comes after the achievement of a difficult
                                  task that demanded our best.
                                </span>
                                <strong>Theodore Isaac Rubin</strong>
                              </blockquote>
                            </li>
                            <li>
                              <blockquote className="style1">
                                <span>
                                  Happiness does not come from doing easy work
                                  but from the afterglow of satisfaction that
                                  comes after the achievement of a difficult
                                  task that demanded our best.
                                </span>
                                <strong>Theodore Isaac Rubin</strong>
                              </blockquote>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <!--FAQ RIGHT--> */}
          </div>
          {/* <!--container ends--> */}
        </section>
      </div>
    );
  }
}

// export default Faq;
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
