import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
const today = Datetime.moment();
var valid = function (current) {
  return current.isBefore(today);
};

const MyDatePicker = ({
  name,
  label,
  error,
  validate,
  labelPosition,
  showTime,
  placeholder,
  ...rest
}) => {
  let inputProps = {
    placeholder,
  };
  return labelPosition === "left" ? (
    <div className="form-group">
      <InputGroup>
        {label && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="bg-white border-0">
              {label}
            </InputGroupText>
          </InputGroupAddon>
        )}
        <Datetime
          {...rest}
          inputProps={inputProps}
          locale="en-ca"
          timeFormat={showTime === true ? "HH:mm" : false}
          name={name}
          id={name}
          dateFormat="YYYY-MM-DD"
          isValidDate={validate ? valid : undefined}
          closeOnSelect={true}
        />
      </InputGroup>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  ) : (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <Datetime
        {...rest}
        inputProps={inputProps}
        locale="en-ca"
        timeFormat={showTime === true ? "HH:mm" : false}
        name={name}
        id={name}
        dateFormat="YYYY-MM-DD"
        isValidDate={validate ? valid : undefined}
        closeOnSelect={true}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default MyDatePicker;
