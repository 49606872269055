import React, { Component } from "react";
import { connect } from "react-redux";
import GoogleMap from "../components/common/googleMap";
import GoogleReview from "../components/common/googleReview";
import image1 from "../images/img-display.jpg";
import { setDealership } from "../redux/settings/action";
import { getDealership } from "../services/dealershipService";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  dealership: state.settings.dealership,
});

const mapDispatchToProps = (dispatch) => ({
  setDealership: (dealership) => dispatch(setDealership(dealership)),
});

class About extends Component {
  state = {};
  async componentDidMount() {
    if (!this.props.dealership.websiteSettings) {
      const { data: dealership } = await getDealership();
      this.props.setDealership(dealership);
    }
  }

  render() {
    if (!this.props.dealership.websiteSettings) return null;
    return (
      <div>
        <PageMetaTags />
        <section id="secondary-banner" className="dynamic-image-3">
          {/* <!--for other images just change the class name of this section block like, className="dynamic-image-2" and add css for the changed class--> */}
          <div className="container">
            <h2>About Us</h2>
            <h4>
              {this.props.dealership.websiteSettings
                ? this.props.dealership.websiteSettings.aboutUsSubtitle
                : ""}
            </h4>
          </div>
        </section>

        {/* <!--secondary-banner ends--> */}
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content">
          <div className="container">
            <div className="inner-page about-us row">
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                <h3 className="margin-bottom-25">OUR MISSION IS SIMPLE</h3>
                <p className="text-justify padding-right-40">
                  <img
                    className="alignleft margin-top-10 margin-bottom-20 margin-right-25 margin-left-none"
                    src={image1}
                    alt="automotive"
                  />
                  <span className="firstcharacter">
                    {this.props.dealership.websiteSettings.ourMission.substring(
                      0,
                      1
                    )}
                  </span>
                  {this.props.dealership.websiteSettings.ourMission.substring(
                    1
                  )}
                </p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 padding-bottom-40">
                <div className="right-container">
                  <h3 className="margin-bottom-25">WHY CHOOSE US?</h3>
                  <div className="choose-list">
                    <ul>
                      {this.props.dealership.websiteSettings.whyChooseUs
                        .filter((w) => w)
                        .map((w, i) => (
                          <li key={"whyChoose" + i}>{w}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <GoogleReview /> */}

              <div className="clearfix"></div>
            </div>
          </div>
          <div className="clearfix"></div>
          {/* <!--container ends--> */}
        </section>
        {/* <!--content ends--> */}
        <div className="clearfix"></div>
        {/* <GoogleReview /> */}

        {/* <!-- Footer Map --> */}
        <div
          className="fullwidth_element"
          style={{
            // height: "390px",
            width: "100vw",
            marginLeft: "calc(-50vw + 50%)",
          }}
        >
          <GoogleReview />
        </div>
        <div
          className="fullwidth_element"
          style={{
            height: "390px",
            width: "100vw",
            marginLeft: "calc(-50vw + 50%)",
          }}
        >
          <GoogleMap />
        </div>
      </div>
    );
  }
}

// export default About;
export default connect(mapStateToProps, mapDispatchToProps)(About);
