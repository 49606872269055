import React from "react";
import Vehicles from "./vehicles";
// import PageMetaTags from "../components/common/pageMetaTags";

class Rentals extends React.Component {
  render() {
    return (
      <div>
        {/* <PageMetaTags /> */}
        <Vehicles rentals={true} />;
      </div>
    );
  }
}

export default Rentals;
