import { webSocket } from "../utils/globalHandlers";
import { closeChatWebSockets } from "../utils/webSocket";

export function sendMessage(id, message) {
  if (webSocket[0])
    webSocket[0].send(messageString("Continue Chat", message, id));
}

export function messageString(messageType, message, id) {
  return JSON.stringify({ messageType, data: { id, message } });
}

export function closeChat(id, message) {
  webSocket[0].send(messageString("Close Chat", message, id));
  closeChatWebSockets();
}

export function closeChat2(id, message) {
  webSocket[0].send(messageString("Chat Closed", message, id));
  closeChatWebSockets();
}
