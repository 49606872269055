import { connect } from "react-redux";
import chatContent from "../../components/chat/chatContent";

const mapStateToProps = (state) => ({
  chatDetails: state.chatReducer.chats.find(
    (o) => o.id === state.chatReducer.chatContent
  ),
  // [state.chatReducer.chatContent - 1]
});

export default connect(mapStateToProps)(chatContent);
