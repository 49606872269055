import React, { Component } from "react";
import Input from "./input";
import _ from "lodash";
import { Button } from "reactstrap";
// import JoiBase from "@hapi/joi";
// import JoiDate from "@hapi/joi-date";
import InputNumber from "./inputNumber";
import Select from "./select";
// import ReactSelect from "./reactSelect";
import InputSelect from "./inputSelect";
// const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date
import Joi from "joi";
import ButtonWithConfirm from "./buttonWithConfirm";
import Datetime from "./datePicker";
import Time from "./timePicker";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    generalError: "",
    readOnly: false,
    resetFormAfterSubmit: false,
    fileInputKey: "",
    selectedPhotos: [],
  };

  initialData = {};

  validate = () => {
    // //console.log(">>>>>>>>>>>>>>>> validate >>>>>>>>>>>>>>>>>>>>>>>>>>");
    const options = { abortEarly: false };
    const { error } = this.schema.validate(
      _.omit(this.state.data, [
        "id",
        "createdAt",
        "createdBy",
        "carproofImage",
        "carproofFile",
        "receiptImage",
        "receiptFile",
      ]),
      options
    );
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  validateProperty = ({ name, value }) => {
    try {
      const obj = { [name]: value };
      // //console.log("obj:", obj);
      // //console.log("schema:", { [name]: this.schemaObj[name] });
      const schema = Joi.object({ [name]: this.schemaObj[name] });
      const { error } = schema.validate(obj);
      return error ? error.details[0].message : null;
    } catch (err) {
      return err.message;
    }
  };

  handleSubmit = async () => {
    const errors = this.validate();
    console.log("----------- error --------> ", errors);
    this.setState({ errors: errors || {} });
    if (errors) return;
    try {
      const result = await this.doSubmit();
      if (result) {
        this.setState({
          generalError: result,
        });
        return result;
      } else {
        if (this.state.resetFormAfterSubmit) this.resetForm();
        return null;
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  handleChange = async (e) => {
    const input = e.currentTarget || e;
    input.value =
      input.type === "datePicker" && _.isObject(input.value)
        ? input.value.format("YYYY-MM-DD")
        : input.type === "timePicker" && _.isObject(input.value)
        ? input.value.format("HH:mm")
        : input.value;
    const errors = { ...this.state.errors };
    if (input.type === "checkbox") input.value = input.checked;
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    if (input.type === "checkbox")
      data[input.name] = input.value === "true" ? true : false;
    else
      data[input.name] = e.capitalize ? input.value.toUpperCase() : input.value;
    if (e.handleSlaves) await e.handleSlaves(data);
    // //console.log(input.name, this.state.data);
    if (data[input.name] === "") data[input.name] = null;
    this.setState({ data, errors });
  };

  handleChange2 = (e) => {
    // console.log("---->e:", e);
    const input = {
      ...e,
      value:
        e.type === "tel"
          ? e.formattedValue
          : e.floatValue !== undefined
          ? e.floatValue
          : null,
    };
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage && input.value) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.handleSlaves) input.handleSlaves(data);
    // console.log(input.name, ":", input.value);
    this.setState({ data, errors });
  };

  resetForm = async () => {
    const r = await this.setState({
      data: this.initialData,
      errors: {},
      generalError: "",
    });
    return r;
  };

  renderButton(
    label,
    type,
    color,
    size,
    className = "btn",
    block,
    onClick,
    disabled
  ) {
    return (
      <Button
        // disabled={type === "submit" && this.validate()}
        type={type === "cancel" || type === "submit" ? "button" : type}
        color={color}
        disabled={disabled}
        size={size}
        className={className}
        block={block}
        onClick={
          type === "cancel"
            ? this.resetForm
            : type === "submit"
            ? this.handleSubmit
            : onClick
        }
      >
        {label}
      </Button>
    );
  }

  // renderSelect(
  //   name,
  //   label,
  //   options,
  //   color,
  //   returnsId,
  //   handleSlaves,
  //   className,
  //   readOnly,
  //   backgroundColor,
  //   autoFocus
  // ) {
  //   const { data, errors } = this.state;

  //   return (
  //     <ReactSelect
  //       name={name}
  //       value={
  //         options &&
  //         data[name] &&
  //         (returnsId
  //           ? options.filter(({ id }) => id == data[name])
  //           : options.filter(
  //               ({ name: optionName }) => optionName === data[name]
  //             ))
  //       }
  //       getOptionLabel={({ name }) => name}
  //       getOptionValue={returnsId ? ({ id }) => id : ({ name }) => name}
  //       label={label}
  //       options={options}
  //       onChange={(e, { name }) => {
  //         this.handleChange({
  //           name,
  //           value: e && (returnsId ? e.id : e.name),
  //           handleSlaves: handleSlaves,
  //         });
  //       }}
  //       error={errors[name]}
  //       color={color}
  //       backgroundColor={backgroundColor}
  //       returnsId={returnsId}
  //       className={className}
  //       isDisabled={
  //         readOnly === false ? false : this.state.readOnly || readOnly
  //       }
  //       autoFocus={autoFocus}
  //     />
  //   );
  // }
  renderSelect(
    name,
    label,
    options,
    defaultFakeOption,
    color,
    returnsId,
    handleSlaves,
    className,
    readOnly
  ) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        options={options}
        defaultFakeOption={defaultFakeOption}
        onChange={(e) =>
          this.handleChange({ ...e, handleSlaves: handleSlaves })
        }
        // error={errors[name]}
        color={color}
        returnsId={returnsId}
        className={className}
        disabled={this.state.readOnly || readOnly}
      />
    );
  }

  renderInput(
    name,
    label,
    type = "text",
    required = false,
    capitalize,
    handleSlaves,
    placeholder,
    bsSize,
    labelPosition,
    className,
    readOnly,
    rows,
    autoComplete
  ) {
    const { data, errors } = this.state;
    // //console.log("data[name];", name, data[name], this.state.data.password);
    return (
      <Input
        type={type}
        name={name}
        id={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        placeholder={placeholder}
        bsSize={bsSize}
        onChange={(e) =>
          this.handleChange({
            ...e,
            capitalize: capitalize,
            handleSlaves: handleSlaves,
          })
        }
        error={errors[name]}
        required={required}
        labelPosition={labelPosition}
        className={className}
        readOnly={this.state.readOnly || readOnly}
        rows={rows}
        autoComplete={autoComplete}
      />
    );
  }

  renderInputSelect(
    name,
    label,
    options,
    type = "text",
    required = false,
    capitalize,
    handleSlaves,
    placeholder,
    bsSize,
    labelPosition,
    className,
    readOnly,
    rows,
    autoComplete
  ) {
    const { data, errors } = this.state;
    // //console.log("data[name];", name, data[name], this.state.data.password);
    return (
      <InputSelect
        type={type}
        name={name}
        id={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        placeholder={placeholder}
        bsSize={bsSize}
        onChange={(e) =>
          this.handleChange({
            ...e,
            capitalize: capitalize,
            handleSlaves: handleSlaves,
          })
        }
        error={errors[name]}
        required={required}
        labelPosition={labelPosition}
        className={className}
        readOnly={this.state.readOnly || readOnly}
        rows={rows}
        autoComplete={autoComplete}
        options={options}
      />
    );
  }

  renderInputNumber(
    name,
    label,
    type = "text",
    required,
    prefix = "",
    handleSlaves,
    placeholder,
    bsSize,
    labelPosition,
    readOnly,
    color
  ) {
    const { data, errors } = this.state;
    return (
      <InputNumber
        type={type}
        name={name}
        id={name}
        value={data[name] === null ? "" : data[name]}
        label={label}
        placeholder={placeholder}
        bsSize={bsSize}
        onValueChange={(value) =>
          this.handleChange2({
            ...value,
            name: name,
            type: type, //"inputNumber",
            handleSlaves: handleSlaves,
          })
        }
        error={errors[name]}
        required={required}
        labelPosition={labelPosition}
        prefix={prefix}
        readOnly={readOnly === false ? false : this.state.readOnly || readOnly}
        color={color}
      />
    );
  }

  renderButtonWithConfirm(
    label,
    type,
    color,
    size,
    buttonClassName = "btn",
    block = false,
    messageBody,
    handleYes,
    disabled
    // printingComponent
  ) {
    // //console.log("renderButtonWithConfirm : " + label);
    return (
      <ButtonWithConfirm
        disabled={
          disabled === true || disabled === false
            ? disabled
            : type === "submit" &&
              (_.isEqual(this.state.data, this.initialData) ||
                _.isEqual(this.state.data, this.state.initialData))
            ? true
            : false
        }
        label={label}
        type={type}
        color={color}
        size={size}
        buttonClassName={buttonClassName}
        block={block}
        // printingComponent={printingComponent}
        messageBody={
          type === "cancel"
            ? "Are you sure you want to cancel?"
            : type === "submit" //|| type === "save&print"
            ? "Do you want to save the changes?"
            : messageBody
        }
        handleYes={
          type === "cancel"
            ? async () => {
                // await this.resetForm();
                if (handleYes) {
                  await handleYes();
                  return true;
                } else return true;
              }
            : type === "submit" //|| type === "save&print"
            ? async () => {
                const err = await this.handleSubmit();
                if (!err && _.isEmpty(this.state.errors)) {
                  if (handleYes) {
                    await handleYes();
                    return true;
                  } else return true;
                } else return false;
                // this.handleSubmit().then(err => {
                //   if (!err && _.isEmpty(this.state.errors)) {
                //     if (handleYes) handleYes();
                //     //console.log(!err && _.isEmpty(this.state.errors));
                //     return true;
                //   } else return false;
                // });
              }
            : handleYes
        }
      />
    );
  }

  renderDatePicker(
    name,
    label,
    validate,
    readOnly,
    labelPosition,
    showTime,
    placeholder
  ) {
    const { data, errors } = this.state;
    return (
      <Datetime
        name={name}
        label={label}
        id={name}
        value={data[name] === null ? "" : data[name]}
        placeholder={placeholder}
        showTime={showTime}
        error={errors[name]}
        validate={validate}
        readOnly={this.state.readOnly || readOnly}
        labelPosition={labelPosition}
        onChange={(value) =>
          this.handleChange({ name, value, type: "datePicker" })
        }
        renderInput={(props) => {
          return <input {...props} value={data[name] ? props.value : ""} />;
        }}
      />
    );
  }

  renderTimePicker(name, label, validate, readOnly, labelPosition) {
    const { data, errors } = this.state;
    return (
      <Time
        name={name}
        label={label}
        id={name}
        value={data[name] === null ? "" : data[name]}
        error={errors[name]}
        // validate={validate}
        readOnly={this.state.readOnly || readOnly}
        labelPosition={labelPosition}
        onChange={(value) => {
          this.handleChange({
            name,
            value,
            type: "timePicker",
          });
        }}
      />
    );
  }
}

export default Form;
