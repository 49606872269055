import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyAmOd_F1nRRQLdwesVMnNO84_XouEvpauY",
  version: "weekly",
  libraries: ["places"],
  // ...additionalOptions,
});

export default loader;
