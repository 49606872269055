import { combineReducers } from "redux";
import settings from "./settings/reducer";
import chatReducer from "./chat/reducer";
import vehiclesReducer from "./vehicles/reducer";

const reducers = combineReducers({
  settings,
  chatReducer,
  vehiclesReducer,
});

export default reducers;
