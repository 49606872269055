import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import "./css/bootstrap.min.css";
import "./css/carousel.css";
import "./css/my.css";
import "font-awesome/css/font-awesome.min.css";
import "./css/flexslider.css";
import "./css/jquery.bxslider.css";
import "./css/jquery.fancybox.css";
import "./css/jquery.selectbox.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
