import React from "react";
import AliceCarousel from "react-alice-carousel";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
// import "react-alice-carousel/lib/alice-carousel.css";
import {
  setFilteredVehicles,
  setSelectedVehicle,
  setShowingVehicles,
} from "../../redux/vehicles/action";
import { numberWithCommas } from "../../utils/utils";
import soldStamp from "../../images/sold.png";
import comingSoonStamp from "../../images/comingSoon.png";
import comingSoon from "../../images/comingSoon.jpg";

const mapStateToProps = (state) => ({
  ...state,
  vehicles: state.vehiclesReducer.vehicles,
  selectedVehicle: state.vehiclesReducer.selectedVehicle,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedVehicle: (vehicle) => dispatch(setSelectedVehicle(vehicle)),
  setFilteredVehicles: (vehicle) => dispatch(setFilteredVehicles(vehicle)),
  setShowingVehicles: (vehicle) => dispatch(setShowingVehicles(vehicle)),
});

class VehicleCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedVehicles: [],
      // activeIndex: 0
    };
  }
  // slidePrev = () => {
  //   this.setState({ activeIndex: this.state.activeIndex - 1 });
  // };
  // slideNext = () => {
  //   this.setState({ activeIndex: this.state.activeIndex + 1 });
  // };
  // syncActiveIndex = ({ item }) => {
  //   this.setState({ activeIndex: item });
  // };
  componentDidMount() {
    const sortedVehicles = this.props.recent
      ? _.orderBy([...this.props.vehicles], ["floorDate"], ["desc"])
      : _.orderBy(
          this.props.vehicles.filter(
            (v) => v.bodyType === this.props.selectedVehicle.bodyType
          ),
          ["floorDate"],
          ["desc"]
        );
    this.setState({ sortedVehicles });
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.vehicles) !==
        JSON.stringify(prevProps.vehicles) ||
      JSON.stringify(this.props.selectedVehicle) !==
        JSON.stringify(prevProps.selectedVehicle)
    ) {
      const sortedVehicles = this.props.recent
        ? _.orderBy([...this.props.vehicles], ["floorDate"], ["desc"])
        : _.orderBy(
            this.props.vehicles.filter(
              (v) => v.bodyType === this.props.selectedVehicle.bodyType
            ),
            ["floorDate"],
            ["desc"]
          );
      this.setState({ sortedVehicles });
    }
  }
  render() {
    // console.log("this.state.vehicles:", this.state.vehicles);
    // if (!this.state.vehicles) return null;
    return (
      <div className="recent-vehicles-wrap margin-top-30 sm-padding-left-none padding-bottom-10">
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 recent-vehicles xs-padding-bottom-20">
            <h5 className="margin-top-none">
              {(this.props.recent ? "Recent" : "Similar") + " Vehicles"}
            </h5>
            <p>
              {"Browse through the vast selection of vehicles that " +
                (this.props.recent
                  ? "have recently been added to our inventory."
                  : "are similar to this vehicle.")}
            </p>
          </div>
          <div className="col-lg-10 col-md-8 col-sm-8">
            <AliceCarousel
              mouseTracking
              disableDotsControls
              // disableButtonsControls
              // activeIndex={this.state.activeIndex}
              // onSlideChanged={this.syncActiveIndex}
              responsive={{
                0: { items: 1 },
                568: { items: 2 },
                1024: { items: 4 },
                1366: { items: 4 },
                1536: { items: 5 },
                1920: { items: 5 },
              }}
              items={(() => {
                const result = this.state.sortedVehicles.map((vehicle, ind) => {
                  return (
                    <div
                      className="slide"
                      style={{
                        float: "left",
                        listStyle: "none",
                        position: "relative",
                        marginRight: "30px",
                        width: "170px",
                      }}
                      key={ind}
                    >
                      {vehicle.websiteBadge && (
                        <div
                          className={
                            "angled_badge2 " +
                            (vehicle.badgeColor
                              ? vehicle.badgeColor.toLowerCase()
                              : "")
                          }
                        >
                          <span>{vehicle.websiteBadge}</span>
                        </div>
                      )}
                      <div className="car-block">
                        <div className="img-flex">
                          {/* {this.props.recent ? ( */}
                          <NavLink
                            to="/vehicleInfo"
                            activeStyle={{ cursor: "pointer" }}
                            onClick={() => {
                              this.props.setFilteredVehicles([
                                ...this.state.sortedVehicles,
                              ]);
                              this.props.setShowingVehicles([
                                ...this.state.sortedVehicles,
                              ]);
                              this.props.setSelectedVehicle(vehicle);
                            }}
                          >
                            <span className="align-center">
                              <i className="fa fa-3x fa-plus-square-o"></i>
                            </span>
                            <div style={{ position: "relative" }}>
                              <img
                                src={
                                  vehicle.vehiclePictures.length > 0
                                    ? vehicle.vehiclePictures[0]
                                    : comingSoon
                                }
                                alt=""
                                className="img-responsive"
                              />
                              {vehicle.sold && (
                                <img
                                  style={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10,
                                    zIndex: 999,
                                    width: "50%",
                                  }}
                                  src={soldStamp}
                                />
                              )}
                              {!vehicle.sold && !vehicle.floor && (
                                <img
                                  style={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10,
                                    zIndex: 999,
                                    width: "50%",
                                  }}
                                  src={comingSoonStamp}
                                />
                              )}
                            </div>

                            <div
                              className="car-block-bottom"
                              style={{ color: "rgb(54,69,79)" }}
                            >
                              <h6>
                                <strong>
                                  {vehicle.productionYear +
                                    " " +
                                    vehicle.makeName +
                                    " " +
                                    vehicle.modelName}
                                </strong>
                              </h6>
                              <h6>
                                {numberWithCommas(vehicle.mileage) +
                                  " kilometers"}
                              </h6>
                              <h5>
                                {"$" + numberWithCommas(vehicle.askingPrice)}
                              </h5>
                            </div>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  );
                });
                return result;
              })()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleCarousel);
