import http from "./httpService";
import { apiUrl } from "../config.js";
const apiEndpoint = apiUrl + "/customers/";

function theUrl(id) {
  return `${apiEndpoint}${id}`;
}

// export function getCustomers() {
//   return http.get(apiEndpoint);
// }

// export async function getCustomersAsItems() {
//   return http.get(apiEndpoint + "asListItems");
// }

// export function saveCustomer(customer) {
//   return http.post(apiEndpoint, customer);
// }

export function saveCustomerInterest(interest) {
  return http.post(apiEndpoint + "interest", interest);
}

// export function updateCustomer(customer) {
//   return http.put(apiEndpoint, customer);
// }

// export function deleteCustomer(customerId) {
//   return http.delete(theUrl(customerId));
// }

// export function getCustomerById(customerId) {
//   return http.get(theUrl(customerId));
// }

// export function getCustomersByType(customerType, customerIds) {
//   return http.get(
//     apiEndpoint +
//       "type/" +
//       customerType +
//       "," +
//       JSON.stringify(customerIds || [])
//   );
// }

// export function getCustomerByEmail(email) {
//   if (email) return http.get(apiEndpoint + "email/" + email);
//   else return { data: [] };
// }

// export async function getCustomerByPhoneNumber(phoneNumber) {
//   if (phoneNumber) return http.get(apiEndpoint + "phoneNumber/" + phoneNumber);
//   else return { data: [] };
// }

// export function getCustomerPlus(customerId) {
//   return http.get(apiEndpoint + "plus/" + customerId);
// }
