import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import reducers from "./reducers";

const logger = createLogger();

export function configureStore(initialState) {
  const middlewares =
    process.env.NODE_ENV === "production"
      ? [thunkMiddleware]
      : [logger, thunkMiddleware];
  const store = createStore(
    reducers,
    initialState,
    applyMiddleware(...middlewares)
  );

  return store;
}
