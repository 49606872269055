import http from "./httpService";
import { apiUrl } from "../config.js";
const apiEndpoint = apiUrl + "/models";

function theUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getModels(makeName) {
  if (makeName) return http.get(apiEndpoint + "/availables/" + makeName);
}

export function getModelsAsListItems(makeName) {
  if (makeName) return http.get(apiEndpoint + "/asListItems/" + makeName);
}

export function saveModel(model) {
  if (model) return http.post(apiEndpoint, model);
}

export function updateModel(model) {
  if (model) return http.put(apiEndpoint, model);
}

export function deleteModel(modelId) {
  if (modelId) return http.delete(theUrl(modelId));
}

export function getModelById(modelId) {
  if (modelId) return http.get(theUrl(modelId));
}

export function getModelByName(makeName, modelName) {
  if (makeName && modelName)
    return http.get(apiEndpoint + "/names/" + makeName + "," + modelName);
}
