import http from "./httpService";
import { apiUrl } from "../config.js";

const apiEndpoint = apiUrl + "/dealerships";

export function getDealership() {
  return http.get(apiEndpoint);
}

// export function saveDealership(dealership) {
//   return http.post(apiEndpoint, dealership);
// }

export function updateDealership(dealership) {
  return http.put(apiEndpoint, dealership);
}
