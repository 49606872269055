import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { setSelectedVehicle } from "../redux/vehicles/action";
import carfaxLogo from "../images/carfax.png";
import { numberWithCommas } from "../utils/utils";
import { Row, Col } from "reactstrap";
import soldStamp from "../images/sold.png";
import comingSoonStamp from "../images/comingSoon.png";
import comingSoon from "../images/comingSoon.jpg";

const mapStateToProps = (state) => ({
  ...state,
  showingVehicles: state.vehiclesReducer.showingVehicles,
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedVehicle: (vehicle) => dispatch(setSelectedVehicle(vehicle)),
});

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = { vehicles: [] };
  }

  render() {
    return (
      <div className="content-wrap car_listings">
        <div className="row car-listings-row car-listings-row-fullwidth">
          {this.props.showingVehicles.map((vehicle) => {
            const {
              askingPrice,
              bodyType,
              color,
              engine,
              id,
              makeName,
              mileage,
              modelName,
              productionYear,
              stockNumber,
              vehiclePictures,
              vin,
              drivetrainType,
              transmissionType,
              trim,
              marketingTrim,
              websiteBadge,
              badgeColor,
              rentPerDay,
              floor,
              sold,
            } = vehicle;
            return (
              <div
                className="inventory col-lg-12 margin-bottom-20 clearfix scroll_effect fadeIn"
                key={id}
              >
                <NavLink
                  to="/vehicleInfo"
                  activeStyle={{ cursor: "pointer" }}
                  onClick={() => {
                    this.props.setSelectedVehicle(vehicle);
                  }}
                >
                  {websiteBadge && (
                    <div
                      className={
                        "angled_badge " +
                        (badgeColor ? badgeColor.toLowerCase() : "")
                      }
                    >
                      <span>{websiteBadge}</span>
                    </div>
                  )}
                  <div>
                    <Row>
                      <Col xs="12" sm="3" className="text-center p-0">
                        <div style={{ position: "relative" }}>
                          <img
                            // src="images/car-1-200x150.jpg"
                            src={
                              vehiclePictures.length === 0
                                ? comingSoon
                                : vehiclePictures[0]
                            }
                            // className="preview"
                            alt="preview"
                          />
                          {sold && (
                            <img
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 10,
                                zIndex: 999,
                                width: "50%",
                              }}
                              src={soldStamp}
                            />
                          )}
                          {!sold && !floor && (
                            <img
                              style={{
                                position: "absolute",
                                right: 10,
                                top: 10,
                                zIndex: 999,
                                width: "50%",
                              }}
                              src={comingSoonStamp}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs="12" sm="9" className="pb-2">
                        <div className="title">
                          {productionYear +
                            " " +
                            makeName +
                            " " +
                            modelName +
                            " " +
                            (trim || "") +
                            " " +
                            (marketingTrim || "")}
                        </div>
                        <Row>
                          <Col xs="8" sm="8" lg="9" className="pl-0">
                            <Row>
                              <Col xs="12" lg="6" className="pl-0">
                                <table>
                                  <tr>
                                    <td className="option primary">
                                      Body Style:
                                    </td>
                                    <td className="spec">{bodyType}</td>
                                  </tr>
                                  <tr>
                                    <td className="option primary">
                                      Drivetrain:
                                    </td>
                                    <td className="spec">{drivetrainType} </td>
                                  </tr>
                                  <tr>
                                    <td className="option primary">Engine:</td>
                                    <td className="spec">
                                      {engine ? engine + " L" : ""}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="option primary">
                                      Transmission:
                                    </td>
                                    <td className="spec">
                                      {transmissionType}{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="option primary">Mileage:</td>
                                    <td className="spec">
                                      {numberWithCommas(mileage) + " km"}{" "}
                                    </td>
                                  </tr>
                                </table>
                              </Col>
                              <Col xs="12" lg="6" className="pl-0">
                                <table>
                                  <tr>
                                    <td className="option secondary">
                                      Exterior Color:
                                    </td>
                                    <td className="spec">{color} </td>
                                  </tr>
                                  {/* <tr>
                                    <td className="option secondary">
                                      Interior Color:
                                    </td>
                                    <td className="spec"></td>
                                  </tr> */}
                                  {/* <tr>
                                    <td className="option secondary">MPG:</td>
                                    <td className="spec"></td>
                                  </tr> */}
                                  <tr>
                                    <td className="option secondary">
                                      Stock Number:
                                    </td>
                                    <td className="spec">{stockNumber} </td>
                                  </tr>
                                  <tr>
                                    <td className="option secondary">
                                      VIN Number:
                                    </td>
                                    <td className="spec">{vin} </td>
                                  </tr>
                                </table>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xs="4"
                            sm="4"
                            lg="3"
                            style={{ paddingLeft: "0", paddingRight: "0" }}
                          >
                            <Row>
                              <Col xs="2"></Col>

                              <Col xs="8" className="p-0">
                                <Row>
                                  {!this.props.rentals && (
                                    <Col
                                      xs="12"
                                      className="p-0 text-center mt-3 mb-3"
                                    >
                                      <img
                                        src={carfaxLogo}
                                        alt="carfax"
                                        style={{
                                          display: "block",
                                        }}
                                      />
                                    </Col>
                                  )}
                                  <Col
                                    xs="12"
                                    // md="8"
                                    // lg="7"
                                    className="p-0 pt-2 text-center"
                                  >
                                    <div className="price">
                                      {/* <b>Price:</b>
                                  <br /> */}
                                      <div className="figure">
                                        {
                                          // sold
                                          //   ? ""
                                          //   : !floor
                                          //   ? ""
                                          //   :
                                          "$" +
                                            (numberWithCommas(
                                              this.props.rentals
                                                ? rentPerDay + "/day"
                                                : askingPrice
                                            ) || "")
                                        }
                                        <br />
                                      </div>
                                      <div className="tax">Plus Tax & Fees</div>
                                      {/* <div className="view-details gradient_button">
                                    <i className="fa fa-plus-circle"></i> View
                                    Details
                                  </div> */}
                                    </div>
                                    {/* <div className="clearfix"></div> */}
                                    {/* </a> */}
                                    {/* <div
                              className="view-video gradient_button"
                              data-youtube-id="3oh7PBc33dk"
                            >
                              <i className="fa fa-video-camera"></i> View Video
                            </div> */}
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs="2"></Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* <img
                      // src="images/car-1-200x150.jpg"
                      src={
                        vehiclePictures.length === 0 ? "" : vehiclePictures[0]
                      }
                      className="preview"
                      alt="preview"
                    /> */}
                      </Col>
                    </Row>
                  </div>
                </NavLink>
                <input
                  type="checkbox"
                  name="a"
                  className="checkbox compare_vehicle input-checkbox"
                  id={"vehicle_" + id}
                />
                <label htmlFor={"vehicle_" + id}></label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
