import _ from "lodash";
import {
  SET_VEHICLES,
  SET_FILTERED_VEHICLES,
  SET_SHOWING_VEHICLES,
  SET_SELECTED_VEHICLE,
} from "../constants/";
// import chatData from "../../data/chat/chatData";
// const vehicles = [];

const INIT_STATE = {
  // chatContent: 0,
  // chatSearch: "",
  vehicles: [],
  filteredVehicles: [],
  showingVehicles: [],
  selectedVehicle: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_VEHICLES:
      return {
        ...state,
        vehicles: action.vehicles,
      };
    case SET_FILTERED_VEHICLES:
      return {
        ...state,
        filteredVehicles: _.orderBy(action.vehicles, ["makeName"], ["asc"]),
      };
    case SET_SHOWING_VEHICLES:
      return {
        ...state,
        showingVehicles: action.vehicles,
      };
    case SET_SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicle: action.vehicle,
      };
    // case REMOVE_CHAT:
    //   return {
    //     ...state,
    //     chats: state.chats.filter((chat) => chat.id !== action.id),
    //     chatContent: 0,
    //   };
    // case OFFLINE_CHAT:
    //   return {
    //     ...state,
    //     chats: state.chats.map((chat) => {
    //       return chat.id === action.id
    //         ? Object.assign({}, chat, { status: "offline" })
    //         : chat;
    //     }),
    //   };
    // case MSG_SUBMIT:
    //   return {
    //     ...state,
    //     chats: state.chats.map((chat) => {
    //       return chat.id === action.id
    //         ? Object.assign(
    //             {},
    //             chat,
    //             chat.chatHistory[chat.chatHistory.length - 1]["to"].push(
    //               action.chatMsg
    //             )
    //           )
    //         : chat;
    //     }),
    //   };
    // case MSG_RECEIVE:
    //   return {
    //     ...state,
    //     chats: state.chats.map((chat) => {
    //       return chat.id === action.id
    //         ? Object.assign(
    //             {},
    //             chat,
    //             chat.chatHistory[chat.chatHistory.length - 1]["to"].length === 0
    //               ? chat.chatHistory[chat.chatHistory.length - 1]["from"].push(
    //                   action.chatMsg
    //                 )
    //               : chat.chatHistory.push({ from: [action.chatMsg], to: [] })
    //           )
    //         : chat;
    //     }),
    //   };
    // case START_CHAT:
    //   return {
    //     ...state,
    //     chats: [...state.chats, action.chatObject],
    //   };
    // case SEARCH_USER:
    //   return {
    //     ...state,
    //     chatSearch: action.searchTerm,
    //   };
    default:
      return state;
  }
};
