import React from "react";
import { connect } from "react-redux";
// import { Navbar } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Chat from "../../../views/chat/chat";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

import { setFilteredVehicles } from "../../../redux/vehicles/action";
import { removeChat } from "../../../redux/chat/action";
import { closeChat } from "../../../services/chatService";
import MyModal from "../../../components/common/modal";
import { colorObjToString } from "../../../utils/utils";

const mapStateToProps = (state) => ({
  ...state,
  dealership: state.settings.dealership,
  vehicles: state.vehiclesReducer.vehicles,
  chatDetails: state.chatReducer.chats.find(
    (o) => o.id === state.chatReducer.chatContent
  ),
  id: state.chatReducer.chatContent,
});

const mapDispatchToProps = (dispatch) => ({
  setFilteredVehicles: (vehicles) => dispatch(setFilteredVehicles(vehicles)),
  removeChat: (id) => dispatch(removeChat(id)),
});

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const base64File = (src) => {
  return src
    ? "data:" + src.type + ";base64," + arrayBufferToBase64(src.content.data)
    : "";
};

const Header = withRouter((props) => <MyHeader {...props} />);

class MyHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chatModalOpen: false, isOpen: false };
  }

  currentPage() {
    const { pathname } = this.props.location;
    return pathname;
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeModal = () => {
    if (this.props.chatDetails && this.props.chatDetails.status !== "offline")
      closeChat(this.props.id, this.props.chatDetails.chatHistory);
    this.props.removeChat(this.props.id);
    this.setState({ chatModalOpen: false });
  };

  render() {
    const currentPage = this.currentPage();
    const rentals = this.props.vehicles.filter((vehicle) => vehicle.rentable);
    const {
      name,
      address,
      phoneNumber1,
      phoneNumber2,
      postalCode,
      city,
      province,
      navbarBgColor,
      logoImage,
      websiteSettings,
    } = this.props.dealership;

    return (
      <div>
        <MyModal
          isOpen={this.state.chatModalOpen}
          toggle={this.closeModal}
          component={Chat}
          className="chat-popup"
        ></MyModal>
        {address && (
          <header
            data-spy="affix"
            data-offset-top="1"
            className="clearfix"
            style={
              websiteSettings
                ? {
                    backgroundColor: colorObjToString(
                      websiteSettings.navbarBgColor
                    ),
                  }
                : {}
            }
          >
            <section id="topHeader" className="toolbar">
              <div className="container">
                <div className="row">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-phone"></i>
                        <span id="phoneNumber_container">{phoneNumber1}</span>
                      </a>
                    </li>
                    {phoneNumber2 && (
                      <li>
                        <a href="#">
                          <i className="mdi mdi-cellphone"></i>
                          {/* <i className="mdi mdi-message-text"></i> */}
                          <span id="phoneNumber_container">{phoneNumber2}</span>
                        </a>
                      </li>
                    )}
                    <li className="address">
                      <a href="contact.html">
                        <i className="fa fa-map-marker"></i>
                        <span id="address_container">
                          {
                            address + " " + city + " " + province
                            // +
                            // " " +
                            // postalCode
                          }
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <div className="bottom-header">
              <div className="container">
                <Navbar style={{ padding: "0" }} expand="md">
                  <NavbarBrand href="/" className="p-0 mr-5">
                    {logoImage && (
                      <img
                        src={base64File(logoImage.filesArray[0])}
                        style={{
                          position: "absolute",
                          top: "0px",
                        }}
                      />
                    )}
                  </NavbarBrand>
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                      <NavItem
                        className={
                          "my-navbar-item " +
                          (currentPage === "/home" ? "my-active" : "")
                        }
                      >
                        <NavLink href="/">Home</NavLink>
                      </NavItem>
                      <NavItem
                        className={
                          "my-navbar-item " +
                          (currentPage === "/inventory" ? "my-active" : "")
                        }
                      >
                        <Link
                          to="/inventory"
                          onClick={() => {
                            this.props.setFilteredVehicles(
                              this.props.vehicles
                                ? [...this.props.vehicles]
                                : []
                            );
                            this.setState({ isOpen: false });
                          }}
                        >
                          Inventory
                        </Link>
                      </NavItem>
                      {rentals.length > 0 && (
                        <NavItem
                          className={
                            "my-navbar-item " +
                            (currentPage === "/rentals" ? "my-active" : "")
                          }
                        >
                          <Link
                            to="/rentals"
                            onClick={() => {
                              this.props.setFilteredVehicles(
                                this.props.vehicles ? [...rentals] : []
                              );
                              this.setState({ isOpen: false });
                            }}
                          >
                            Rentals
                          </Link>
                        </NavItem>
                      )}
                      <UncontrolledDropdown
                        nav
                        inNavbar
                        className={
                          "my-navbar-item " +
                          (currentPage === "/creditApplication" ||
                          currentPage === "/calculator"
                            ? "my-active"
                            : "")
                        }
                      >
                        <DropdownToggle nav caret>
                          Finance
                        </DropdownToggle>
                        <DropdownMenu left>
                          <DropdownItem>
                            <Link
                              to="/creditApplication"
                              onClick={() => this.setState({ isOpen: false })}
                            >
                              Application Form
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link
                              to="/calculator"
                              onClick={() => this.setState({ isOpen: false })}
                            >
                              Finance Calculator
                            </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown
                        nav
                        inNavbar
                        className={
                          "my-navbar-item " +
                          (currentPage === "/about" ||
                          currentPage === "/faq" ||
                          currentPage === "/team" ||
                          (rentals.length > 0 && currentPage === "/contact")
                            ? "my-active"
                            : "")
                        }
                      >
                        <DropdownToggle nav caret>
                          Dealership
                        </DropdownToggle>
                        <DropdownMenu left>
                          <DropdownItem>
                            <Link
                              to="/about"
                              onClick={() => this.setState({ isOpen: false })}
                            >
                              About Us
                            </Link>
                          </DropdownItem>
                          {rentals.length > 0 && (
                            <DropdownItem>
                              <Link
                                to="/contact"
                                onClick={() => this.setState({ isOpen: false })}
                              >
                                Contact Us
                              </Link>
                            </DropdownItem>
                          )}
                          <DropdownItem>
                            <Link
                              to="/team"
                              onClick={() => this.setState({ isOpen: false })}
                            >
                              Our Team
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link
                              to="/faq"
                              onClick={() => this.setState({ isOpen: false })}
                            >
                              FAQ
                            </Link>
                          </DropdownItem>
                          {/* <DropdownItem>
                            <a href="/js/autoTraderCashOffer.html">
                              Cash Offer
                            </a>
                          </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {rentals.length === 0 && (
                        <NavItem
                          className={
                            "my-navbar-item " +
                            (currentPage === "/contact" ? "my-active" : "")
                          }
                        >
                          <Link
                            to="/contact"
                            onClick={() => this.setState({ isOpen: false })}
                          >
                            Contact Us
                          </Link>
                        </NavItem>
                      )}
                      <NavItem className="my-navbar-item">
                        <Link
                          onClick={() =>
                            this.setState({
                              chatModalOpen: true,
                              isOpen: false,
                            })
                          }
                        >
                          Online Chat
                        </Link>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>
            </div>
          </header>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
