import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Footer from "./layout-components/footer/footer";
import ThemeRoutes from "../routes/router";
import { getDealership } from "../services/dealershipService";
import { connect } from "react-redux";
import { removeChat } from "../redux/chat/action";
import PrivateRoute from "../routes/PrivateRoutes";
import Header from "./layout-components/header/header";
import { setVehicles } from "../redux/vehicles/action";
import { setDealership } from "../redux/settings/action";
import { getVehicles } from "../services/vehicleService";

const mapStateToProps = (state) => ({
  chatDetails: state.chatReducer.chats.find(
    (o) => o.id === state.chatReducer.chatContent
  ),
  id: state.chatReducer.chatContent,
  dealership: state.settings.dealership,
});

const mapDispatchToProps = (dispatch) => ({
  removeChat: (id) => dispatch(removeChat(id)),
  setDealership: (dealership) => dispatch(setDealership(dealership)),
  setVehicles: (vehicle) => dispatch(setVehicles(vehicle)),
});

class Fulllayout extends Component {
  state = {
    dealership: {},
    topHeaderHeight: null,
    // chatModalOpen: false
  };
  headerHeight = (topHeaderHeight) =>
    topHeaderHeight ? "calc(1vw + 80px + " + topHeaderHeight + "px)" : 0;
  async componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState({
        topHeaderHeight: document.getElementById("topHeader")
          ? document.getElementById("topHeader").offsetHeight
          : 0,
      });
    });
    window.addEventListener(
      "click",
      () => {
        this.setState({
          topHeaderHeight: document.getElementById("topHeader")
            ? document.getElementById("topHeader").offsetHeight
            : 0,
        });
      },
      { once: true }
    );
    const { data: dealership } = await getDealership();
    const { data: vehicles } = await getVehicles({});
    this.props.setDealership(dealership);
    this.props.setVehicles(vehicles);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.topHeaderHeight)
      this.setState({
        topHeaderHeight: document.getElementById("topHeader")
          ? document.getElementById("topHeader").offsetHeight
          : 0,
      });
  }
  render() {
    // console.log("====================================");
    // console.log(
    //   document.getElementById("topHeader") &&
    //     document.getElementById("topHeader").offsetHeight
    // );
    // console.log("====================================");
    // if (
    //   document.getElementById("topHeader") &&
    //   document.getElementById("topHeader").offsetHeight !==
    //     this.state.topHeaderHeight
    // )
    //   this.setState({
    //     topHeaderHeight: document.getElementById("topHeader").offsetHeight,
    //   });
    return (
      <div
        style={{
          paddingTop: this.headerHeight(this.state.topHeaderHeight),
        }}
      >
        <link
          rel="stylesheet"
          type="text/css"
          href={
            "./css/style" +
            (this.props.dealership.websiteSettings &&
              (this.props.dealership.websiteSettings.colorScheme || "")) +
            ".css"
          }
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="./css/settings.css"
          media="screen"
        />
        <link href="./css/animate.min.css" rel="stylesheet" />
        <link href="./css/ts.css" type="text/css" rel="stylesheet" />

        <div className="my-navbar">
          <Header />
        </div>
        <div>
          <Switch>
            {ThemeRoutes.map((prop, key) => {
              if (prop.navlabel) {
                return null;
              } else if (prop.collapse) {
                return prop.child.map((prop2, key2) => {
                  if (prop2.collapse) {
                    return prop2.subchild.map((prop3, key3) => {
                      return (
                        <Route
                          path={prop3.path}
                          component={prop3.component}
                          key={key3}
                        />
                      );
                    });
                  }
                  return (
                    <Route
                      path={prop2.path}
                      component={prop2.component}
                      key={key2}
                    />
                  );
                });
              } else if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              } else {
                return (
                  <PrivateRoute
                    exact
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              }
            })}
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fulllayout);
