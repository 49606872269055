import React from "react";
import JoiBase from "joi";
import JoiDate from "@joi/date";
import { Card, CardBody, Row, Col, Form } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  daysBetweenDates,
  myLocaleDateString,
  myLocaleTimeString,
  toDate,
} from "../../utils/utils";
import { saveRentalCalendarEvent } from "../../services/rentalCalendarService";
import MyForm from "../common/form";
const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class CalendarEvent extends MyForm {
  state = {
    loading: false,
    data: {
      id: this.props.id || null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      startDate: myLocaleDateString(this.props.start),
      startTime: myLocaleTimeString(this.props.start),
      endDate: myLocaleDateString(this.props.end),
      endTime: myLocaleTimeString(this.props.end),
    },
    errors: {},
    generalError: "",
  };
  schemaObj = {
    firstName: Joi.string().required().label("First Name"),
    lastName: Joi.string().required().label("Last Name"),
    phoneNumber: Joi.string().allow(null).allow("").label("Phone Number"),
    email: Joi.string()
      .email({ tlds: false })
      .allow(null)
      .allow("")
      .label("Email"),
    startDate: Joi.date().required().label("Start Date"),
    startTime: Joi.string()
      .pattern(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/)
      .required()
      .length(5)
      .label("Start Time")
      .messages({
        "string.pattern.base": `Time should be in the format HH:mm`,
      }),
    endDate: Joi.date().required().label("End Date"),
    endTime: Joi.string()
      .pattern(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/)
      .required()
      .length(5)
      .label("End Time")
      .messages({
        "string.pattern.base": `Time should be in the format HH:mm`,
      }),
  };
  schema = Joi.object(this.schemaObj).options({ stripUnknown: true });

  savableData = (data) => {
    const { startTime, startDate, endTime, endDate } = data;
    const start = toDate(startDate, startTime);
    const end = toDate(endDate, endTime);
    return {
      ...this.state.data,
      start,
      end,
      vehicleId: this.props.vehicleId,
    };
  };

  doSubmit = async () => {
    try {
      const { startTime, startDate, endTime, endDate } = this.state.data;
      const start = toDate(startDate, startTime);
      const end = toDate(endDate, endTime);
      if (start <= new Date())
        throw new Error("Start moment should be after current moment");
      if (start >= end)
        throw new Error("End moment should be after start moment");
      this.setState({ loading: true });
      const { data } = this.state;
      const op = this.props.id ? "update" : "create";
      switch (op) {
        case "create":
          // if (data.type === "Reminder") {
          //   data.endDate = data.startDate;
          //   data.endTime = data.startTime;
          // }
          const { data: result } = await saveRentalCalendarEvent(
            this.savableData(data)
          );
          await this.props.completeSubmit(result);
          break;
        case "update":
          // await updateCalendarEvent(this.savableData(data));
          // await this.props.completeSubmit([this.savableData(data)]);
          break;
        default:
          throw new Error("Op is not valid");
      }
      this.setState({ loading: false });
    } catch (ex) {
      this.setState({ loading: false });
      if (ex.response /* && ex.response.status === 400*/) {
        const errors = { ...this.state.errors };
        errors.serverError = ex.response.data;
        this.setState({ errors });
      }
      return ex.response
        ? ex.response.status + " : " + ex.response.data
        : ex.name + " : " + ex.message;
    }
  };

  // async deleteEvent(recurring = false) {
  //   try {
  //     if (recurring) {
  //       const { data: ids } = await deleteRecurringCalendarEvent(
  //         this.state.data.id
  //       );
  //       this.props.completeSubmit(ids);
  //     } else {
  //       await deleteCalendarEvent(this.state.data.id);
  //       this.props.completeSubmit([{ id: this.state.data.id }]);
  //     }
  //     this.props.toggleModal();
  //     return true;
  //   } catch (ex) {
  //     if (ex.response /* && ex.response.status === 400*/) {
  //       const errors = { ...this.state.errors };
  //       errors.serverError = ex.response.data;
  //       this.setState({ errors });
  //     }
  //     const generalError = ex.response
  //       ? ex.response.status + " : " + ex.response.data
  //       : ex.name + " : " + ex.message;
  //     this.setState({ generalError });
  //     return false;
  //   }
  // }

  isCreating = () => {
    return !this.props.id;
  };

  render() {
    const themeColor = "info";
    return (
      <Card body outline color={themeColor} className="border m-2">
        <CardBody>
          <Form className="mt-0 pt-0" id="inventoryform">
            <Row className={"mb-0 pb-4 border-bottom border-" + themeColor}>
              <Col xs="8">
                <h2 className="font-medium">Rent Vehicle</h2>
              </Col>
              <Col xs="4" className="pl-5 d-flex align-items-right">
                {this.renderButtonWithConfirm(
                  "Save",
                  "submit",
                  // themeColor,
                  "info",
                  "md",
                  "btn text-uppercase pl-3 pr-3 ml-1",
                  false,
                  null,
                  () => this.props.toggleModal()
                )}
                {this.renderButtonWithConfirm(
                  "Cancel",
                  "cancel",
                  "secondary",
                  "md",
                  "btn text-uppercase pl-2 pr-2 ml-1",
                  false,
                  null,
                  () => this.props.toggleModal()
                )}
              </Col>
            </Row>
            {this.state.generalError && (
              <Row>
                <Col xs="12">
                  <div className="mt-3 text-danger">
                    {this.state.generalError}
                  </div>
                </Col>
              </Row>
            )}
            <Row className="pt-3">
              <Col xs="6">
                {this.renderDatePicker("startDate", "Start Date", false)}
              </Col>
              <Col xs="6">
                {this.renderTimePicker("startTime", "Start Time", true)}
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                {this.renderDatePicker("endDate", "End Date", false)}
              </Col>
              <Col xs="6">
                {this.renderTimePicker("endTime", "End Time", true)}
              </Col>
            </Row>
            <Row>
              <Col xs="6">{this.renderInput("firstName", "First Name")}</Col>
              <Col xs="6">{this.renderInput("lastName", "Last Name")}</Col>
            </Row>
            <Row>
              <Col xs="6">
                {this.renderInputNumber(
                  "phoneNumber",
                  "Phone Number",
                  "tel",
                  false
                )}
              </Col>
              <Col xs="6">
                {this.renderInput("email", "Email", "text", false)}
              </Col>
            </Row>
            <Row className="pt-4">
              <Col xs="6">
                <h5>{"Rental Per Day: $" + this.props.rentPerDay}</h5>
              </Col>
              <Col xs="6">
                <h5>
                  {"Rental For " +
                    daysBetweenDates(
                      this.state.data.endDate,
                      this.state.data.startDate
                    ) +
                    " Days: $" +
                    daysBetweenDates(
                      this.state.data.endDate,
                      this.state.data.startDate
                    ) *
                      this.props.rentPerDay}
                </h5>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      // </Col>
    );
  }
}

export default CalendarEvent;
