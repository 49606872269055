import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "../../css/react-big-calendar.css";
// import {
//   getCalendarEventById,
//   getCalendarEvents,
// } from "../../services/calendarEventService";
// import { tomorrow } from "../../utils/utils";
// import { todayEvents } from "../../utils/globalHandlers";
import {
  getRentalCalendarEventById,
  getRentalCalendarEvents,
} from "../../services/rentalCalendarService";
import CalendarEvent from "./calendarEvent";
// import "./Calendar.css";

//BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const localizer = momentLocalizer(moment);

const RentVehicle = (props) => {
  const [calEvents, setCalEvents] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [eventInfo, setEventInfo] = useState({});
  useEffect(async () => {
    const { data } = await getRentalCalendarEvents(props.vehicleId);
    const calEvents = data.map((d) => ({
      ...d,
      start: new Date(d.start),
      end: new Date(d.end),
    }));
    setCalEvents(calEvents);
  }, []);

  const editSelectedEvent = async (event) => {
    const { data: eventObj } = await getRentalCalendarEventById(event.id);
    setEventInfo({
      ...eventObj,
    });
    setModalState(true);
  };

  const completeSubmit = (m) => {
    const events = [...calEvents];
    // for (const m of modifiedDataArray) {
    const modifiedData = { ...m };
    modifiedData.start = new Date(m.start);
    modifiedData.end = new Date(m.end);
    if (modifiedData.id && !modifiedData.start) {
      // this is in the case of delete event
      const i = events.findIndex((c) => c.id === modifiedData.id);
      // const ev = [...events];
      // ev.splice(i, 1);
      events.splice(i, 1);
      // setCalEvents(ev);
      return;
    }
    const i = events.findIndex((c) => c.id === modifiedData.id);
    if (i === -1) {
      // console.log(modifiedData);
      events.push(modifiedData);
      // setCalEvents(events);
      // setCalEvents([...events, modifiedData]);
    } else {
      // const ev = [...events];
      // ev[i] = modifiedData;
      events[i] = modifiedData;
      // setCalEvents(ev);
    }
    // }
    setCalEvents(events);
  };

  const addNewEventAlert = (slotInfo) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (slotInfo.start < today) return;
    setEventInfo({
      start: slotInfo.start,
      end: slotInfo.end,
    });
    setModalState(true);
  };

  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color.toLowerCase())
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  const toggleModal = () => {
    setModalState(!modalState);
  };

  // console.log(todayEvents);
  return (
    <div>
      <Modal
        isOpen={modalState}
        toggle={toggleModal}
        size="lg"
        style={{
          position: "relative",
          margin: "0px auto",
          top: "15%",
          zIndex: 99999,
        }}
      >
        <ModalHeader toggle={toggleModal}>
          Request For Renting The Vehicle
        </ModalHeader>
        <ModalBody>
          <CalendarEvent
            {...eventInfo}
            completeSubmit={completeSubmit}
            toggleModal={toggleModal}
            vehicleId={props.vehicleId}
            rentPerDay={props.rentPerDay}
          />
        </ModalBody>
      </Modal>

      <h5 className="mb-4">Calendar Page</h5>
      <Row>
        <Col xs={12} md={12} className="ml-auto mr-auto">
          <Card className="card-calendar">
            <CardBody>
              <Calendar
                selectable
                events={calEvents}
                defaultView="month"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                localizer={localizer}
                onSelectEvent={(event) => editSelectedEvent(event)}
                onSelectSlot={(slotInfo) => addNewEventAlert(slotInfo)}
                eventPropGetter={(event) => eventColors(event)}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RentVehicle;
