import Chat from "../views/chat/chat";
// import AuthRoutes from "./authroutes";
import FirstPage from "../views/firstPage";
import VehicleInfo from "../views/vehicleInfo";
import Vehicles from "../views/vehicles";
import Contact from "../views/contact";
import About from "../views/about";
import Team from "../views/team";
import Faq from "../views/faq";
import CreditApplication from "../views/creditApplication";
import FinancingCalculator from "../views/financingCalculator";
import Rentals from "../views/rentals";

// var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
  // {
  //   path: "/chat",
  //   name: "Chat",
  //   icon: "mdi mdi-comment-multiple-outline",
  //   component: Chat,
  // },
  {
    path: "/home",
    name: "Home",
    icon: "",
    component: FirstPage,
  },
  {
    path: "/inventory",
    name: "Inventory",
    icon: "",
    component: Vehicles,
  },
  {
    path: "/rentals",
    name: "Rentals",
    icon: "",
    component: Rentals,
  },
  {
    path: "/vehicleInfo",
    name: "VehicleInfo",
    icon: "",
    component: VehicleInfo,
  },
  {
    path: "/contact",
    name: "Contact",
    icon: "",
    component: Contact,
  },
  {
    path: "/about",
    name: "About",
    icon: "",
    component: About,
  },
  {
    path: "/team",
    name: "Team",
    icon: "",
    component: Team,
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "",
    component: Faq,
  },
  {
    path: "/creditApplication",
    name: "Credit Application",
    icon: "",
    component: CreditApplication,
  },
  {
    path: "/calculator",
    name: "Finance Calculator",
    icon: "",
    component: FinancingCalculator,
  },
  {
    path: "/",
    pathTo: "/home",
    name: "Home",
    redirect: true,
  },
];
export default ThemeRoutes;
