import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  setShowingVehicles,
  setFilteredVehicles,
  setVehicles,
} from "../redux/vehicles/action";
import SearchResult from "./searchResult";
import { Row, Col, Label, Input, FormGroup } from "reactstrap";
import SearchResult2 from "./searchResult2";
import SearchResult4 from "./searchResult4";
import SearchResult5 from "./searchResult5";
import InventorySidebar from "../components/vehicle/inventorySidebar";
import { getVehicles } from "../services/vehicleService";
import { setDealership } from "../redux/settings/action";
import { getDealership } from "../services/dealershipService";
import PageMetaTags from "../components/common/pageMetaTags";

const mapStateToProps = (state) => ({
  filteredVehicles: state.vehiclesReducer.filteredVehicles,
  showingVehicles: state.vehiclesReducer.showingVehicles,
  dealership: state.settings.dealership,
});
const mapDispatchToProps = (dispatch) => ({
  setDealership: (dealership) => dispatch(setDealership(dealership)),
  setVehicles: (vehicle) => dispatch(setVehicles(vehicle)),
  setShowingVehicles: (vehicles) => dispatch(setShowingVehicles(vehicles)),
  setFilteredVehicles: (vehicles) => dispatch(setFilteredVehicles(vehicles)),
});
const sortOptions = [
  "Make Ascending",
  "Make Descending",
  "Price Ascending",
  "Price Descending",
  "Year Ascending",
  "Year Descending",
  "Mileage Ascending",
  "Mileage Descending",
  "Recently Arrived",
];
const mileageOptions = [
  "< 10,000",
  "< 20,000",
  "< 40,000",
  "< 60,000",
  "< 80,000",
  "< 100,000",
  "< 120,000",
  "< 150,000",
  "< 200,000",
];
const priceOptions = [
  "< $5,000",
  "< $10,000",
  "< $15,000",
  "< $20,000",
  "< $25,000",
  "< $30,000",
  "< $35,000",
  "< $40,000",
  "< $45,000",
  "< $50,000",
];
class Vehicles extends Component {
  state = { tabIndex: 1 };
  async componentDidMount() {
    if (!this.props.dealership.websiteSettings) {
      const { data: dealership } = await getDealership();
      this.props.setDealership(dealership);
    }
    if (
      !this.props.filteredVehicles ||
      this.props.filteredVehicles.length === 0
    ) {
      const { data: vehicles } = await getVehicles({});
      this.props.setVehicles(vehicles);
      this.props.setFilteredVehicles(vehicles);
    }
    this.props.setShowingVehicles([...this.props.filteredVehicles]);
    // this.props.setShowingVehicles(
    //   _.orderBy([...this.props.filteredVehicles], ["makeName"], ["asc"])
    // );
  }
  render() {
    if (this.props.filteredVehicles.length === 0) return null;
    const { filteredVehicles, showingVehicles } = this.props;
    const { tabIndex } = this.state;
    return (
      <div>
        <PageMetaTags />
        <div className="clearfix"></div>
        <section id="secondary-banner" className="dynamic-image-1">
          <div className="container">
            <h2>Inventory</h2>
            <h4>
              {this.props.dealership &&
                this.props.dealership.websiteSettings.inventorySubtitle}
            </h4>
          </div>
        </section>
        <div className="message-shadow"></div>
        <div className="clearfix"></div>
        <section className="content">
          <div className="container-lg">
            <div className="inner-page row">
              <div className="listing-view margin-bottom-20 col-sm-12">
                <div className="row">
                  <div className="col-lg-7 col-md-6 col-sm-6 col-xs-12">
                    <span className="ribbon">
                      <strong>
                        {showingVehicles.length + " Vehicles Matching"}
                      </strong>
                    </span>
                    {/* <span className="ribbon-item">All Listings New</span> */}
                  </div>
                  {window.innerWidth > 767 && (
                    <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 pull-right select_view">
                      <span>Select View:</span>
                      <ul className="page-view nav nav-tabs" id="myTab">
                        <li className={tabIndex === 1 ? "active" : ""}>
                          <a
                            href="#full-width"
                            data-toggle="tab"
                            onClick={() => {
                              this.setState({ tabIndex: 1 });
                            }}
                          >
                            <i className="fa fa-align-left"></i>
                          </a>
                        </li>
                        <li className={tabIndex === 2 ? "active" : ""}>
                          <a
                            href="#list-left-sidebar"
                            data-toggle="tab"
                            onClick={() => {
                              this.setState({ tabIndex: 2 });
                            }}
                          >
                            <i className="fa"></i>
                          </a>
                        </li>
                        <li className={tabIndex === 3 ? "active" : ""}>
                          <a
                            href="#list-right-sidebar"
                            data-toggle="tab"
                            onClick={() => {
                              this.setState({ tabIndex: 3 });
                            }}
                          >
                            <i className="fa"></i>
                          </a>
                        </li>
                        <li className={tabIndex === 4 ? "active" : ""}>
                          <a
                            href="#box-full-width"
                            data-toggle="tab"
                            onClick={() => {
                              this.setState({ tabIndex: 4 });
                            }}
                          >
                            <i className="fa"></i>
                          </a>
                        </li>
                        <li className={tabIndex === 5 ? "active" : ""}>
                          <a
                            href="#left-box-fullwidth"
                            data-toggle="tab"
                            onClick={() => {
                              this.setState({ tabIndex: 5 });
                            }}
                          >
                            <i className="fa"></i>
                          </a>
                        </li>
                        <li className={tabIndex === 6 ? "active" : ""}>
                          <a
                            href="#right-box-fullwidth"
                            data-toggle="tab"
                            onClick={() => {
                              this.setState({ tabIndex: 6 });
                            }}
                          >
                            <i className="fa"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <form method="post" action="#" className="listing_sort">
                {window.innerWidth > 767 && (
                  <div className="select-wrapper listing_select clearfix margin-top-none col-sm-12">
                    <Row>
                      <Col xs="6" md="4" lg="2">
                        <div className="my-dropdown">
                          <select
                            name="year"
                            className="css-dropdowns"
                            tabIndex="1"
                            onChange={(e) => {
                              this.props.setShowingVehicles(
                                e.currentTarget.value
                                  ? _.filter(
                                      [...filteredVehicles],
                                      [
                                        "productionYear",
                                        parseInt(e.currentTarget.value),
                                      ]
                                    )
                                  : [...filteredVehicles]
                              );
                            }}
                          >
                            <option value="">All Years</option>
                            {_.orderBy(
                              _.uniqBy(filteredVehicles, "productionYear"),
                              ["productionYear"]
                            ).map((v) => {
                              return (
                                <option
                                  value={v.productionYear}
                                  key={v.productionYear}
                                >
                                  {v.productionYear}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="2">
                        <div className="my-dropdown">
                          <select
                            name="make"
                            className="css-dropdowns"
                            tabIndex="1"
                            onChange={(e) => {
                              this.props.setShowingVehicles(
                                e.currentTarget.value
                                  ? _.filter(
                                      [...filteredVehicles],
                                      ["makeName", e.currentTarget.value]
                                    )
                                  : [...filteredVehicles]
                              );
                            }}
                          >
                            <option value="">All Makes</option>
                            {_.orderBy(_.uniqBy(filteredVehicles, "makeName"), [
                              "makeName",
                            ]).map((v) => {
                              return (
                                <option value={v.makeName} key={v.makeName}>
                                  {v.makeName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="2">
                        <div className="my-dropdown">
                          <select
                            name="body_style"
                            className="css-dropdowns"
                            tabIndex="1"
                            onChange={(e) => {
                              this.props.setShowingVehicles(
                                e.currentTarget.value
                                  ? _.filter(
                                      [...filteredVehicles],
                                      ["bodyType", e.currentTarget.value]
                                    )
                                  : [...filteredVehicles]
                              );
                            }}
                          >
                            <option value="">All Body Styles</option>
                            {_.orderBy(_.uniqBy(filteredVehicles, "bodyType"), [
                              "bodyType",
                            ]).map((v) => {
                              return (
                                <option value={v.bodyType} key={v.bodyType}>
                                  {v.bodyType}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="2">
                        <div className="my-dropdown">
                          <select
                            name="mileage"
                            className="css-dropdowns"
                            tabIndex="1"
                            onChange={(e) => {
                              this.props.setShowingVehicles(
                                e.currentTarget.value
                                  ? _.filter(
                                      [...filteredVehicles],
                                      (v) =>
                                        v.mileage <=
                                        parseInt(
                                          e.currentTarget.value
                                            .replace("< ", "")
                                            .replace(",", "")
                                        )
                                    )
                                  : [...filteredVehicles]
                              );
                            }}
                          >
                            <option value="">All Mileage</option>
                            {mileageOptions.map((m) => (
                              <option key={m} value={m}>
                                {m}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="2">
                        <div className="my-dropdown">
                          <select
                            name="transmission"
                            className="css-dropdowns"
                            tabIndex="1"
                            onChange={(e) => {
                              this.props.setShowingVehicles(
                                e.currentTarget.value
                                  ? _.filter(
                                      [...filteredVehicles],
                                      [
                                        "transmissionType",
                                        e.currentTarget.value,
                                      ]
                                    )
                                  : [...filteredVehicles]
                              );
                            }}
                          >
                            <option value="">All Transmissions</option>
                            {_.orderBy(
                              _.uniqBy(filteredVehicles, "transmissionType"),
                              ["transmissionType"]
                            ).map((v) => {
                              return (
                                <option
                                  value={v.transmissionType}
                                  key={v.transmissionType}
                                >
                                  {v.transmissionType}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col xs="6" md="4" lg="2">
                        <div className="my-dropdown">
                          <select
                            name="price"
                            className="css-dropdowns"
                            tabIndex="1"
                            onChange={(e) => {
                              this.props.setShowingVehicles(
                                e.currentTarget.value
                                  ? _.filter(
                                      [...filteredVehicles],
                                      (v) =>
                                        v.askingPrice <=
                                        parseInt(
                                          e.currentTarget.value
                                            .replace("< $", "")
                                            .replace(",", "")
                                        )
                                    )
                                  : [...filteredVehicles]
                              );
                            }}
                          >
                            <option value="">All Prices</option>
                            {priceOptions.map((m) => (
                              <option key={m} value={m}>
                                {m}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <div className="select-wrapper pagination clearfix">
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12 sort-by-menu"
                      style={{
                        textAlign: "left",
                        paddingLeft: window.innerWidth > 767 ? "none" : "0px",
                      }}
                    >
                      {/* <span className="sort-by">Sort By:</span> */}
                      <div>
                        <select
                          name="price"
                          className="css-dropdowns"
                          tabIndex="1"
                          onChange={(e) => {
                            switch (e.currentTarget.value) {
                              case "Price Ascending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["askingPrice"],
                                    ["asc"]
                                  )
                                );
                                break;
                              case "Price Descending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["askingPrice"],
                                    ["desc"]
                                  )
                                );
                                break;
                              case "Year Ascending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["productionYear"],
                                    ["asc"]
                                  )
                                );
                                break;
                              case "Year Descending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["productionYear"],
                                    ["desc"]
                                  )
                                );
                                break;
                              case "Mileage Ascending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["mileage"],
                                    ["asc"]
                                  )
                                );
                                break;
                              case "Mileage Descending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["mileage"],
                                    ["desc"]
                                  )
                                );
                                break;
                              case "Make Ascending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["makeName"],
                                    ["asc"]
                                  )
                                );
                                break;
                              case "Make Descending":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["makeName"],
                                    ["desc"]
                                  )
                                );
                                break;
                              case "Recently Arrived":
                                this.props.setShowingVehicles(
                                  _.orderBy(
                                    [...showingVehicles],
                                    ["floorDate"],
                                    ["desc"]
                                  )
                                );
                                break;
                            }
                          }}
                        >
                          {sortOptions.map((s) => (
                            <option key={s} value={s}>
                              {"Sort by : " + s}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 offset-lg-1">
                      <div className="controls full">
                        <a href="#" className="left-arrow">
                          <i className="fa fa-angle-left"></i>
                        </a>
                        <span>Page 1 of 4</span>
                        <a href="#" className="right-arrow">
                          <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pull-right"> */}
                    {/* <ul className="form-links top_buttons">
                        <li>
                          <a href="#" className="gradient_button">
                            Reset Filters
                          </a>
                        </li>
                        <li>
                          <a href="#" className="gradient_button">
                            Deselect All
                          </a>
                        </li>
                        <li>
                          <a href="#" className="gradient_button">
                            Compare 0 Vehicles
                          </a>
                        </li>
                      </ul> */}
                    {/* </div> */}
                  </div>
                </div>
                <div className="mt-2 ml-3">
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      onChange={(e) => {
                        this.props.setShowingVehicles(
                          e.target.checked
                            ? _.filter([...filteredVehicles], ["sold", false])
                            : [...filteredVehicles]
                        );
                      }}
                    />{" "}
                    <Label check>Do Not Show Sold Vehicles</Label>
                  </FormGroup>
                </div>
              </form>
              <div className="clearfix"></div>
              <div
                id="myTabContent"
                className="tab-content"
                style={{ width: "100%" }}
              >
                {/* ----------------------------------------------------------------------------------------- */}
                {/* -------------------------------------------1--------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------- */}
                <div className="tab-pane active in" id="full-width">
                  <SearchResult rentals={this.props.rentals} />
                  <div className="clearfix"></div>
                </div>
                {/* ----------------------------------------------------------------------------------------- */}
                {/* -------------------------------------------2--------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------- */}

                <div className="tab-pane fade" id="list-left-sidebar">
                  {/* <Row className="car-listings-row">
                    <Col sm="3" className="left-sidebar order-xs-2">
                      <InventorySidebar />
                    </Col>
                    <Col
                      sm="9"
                      className="inventory-wide-sidebar-left order-xs-1"
                    >
                      <SearchResult2 />
                    </Col>
                  </Row> */}
                  <div className="row car-listings-row">
                    <div className="col-md-9 inventory-wide-sidebar-left">
                      <SearchResult2 rentals={this.props.rentals} />
                    </div>
                    <div className="col-md-3 order-md-first left-sidebar">
                      <InventorySidebar />
                    </div>
                  </div>
                </div>
                {/* ----------------------------------------------------------------------------------------- */}
                {/* -------------------------------------------3--------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------- */}

                <div className="tab-pane fade" id="list-right-sidebar">
                  <div className="row car-listings-row">
                    <div className="col-md-9 inventory-wide-sidebar-right">
                      {/* <div id="searchResult3_container"></div> */}
                      <SearchResult2 rentals={this.props.rentals} />
                    </div>
                    <div className="inventory-sidebar col-md-3">
                      <InventorySidebar />
                    </div>
                  </div>
                </div>
                {/* ----------------------------------------------------------------------------------------- */}
                {/* -------------------------------------------4--------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------- */}
                <div className="tab-pane fade" id="box-full-width">
                  {/* <div id="searchResult4_container"></div> */}
                  <SearchResult4 rentals={this.props.rentals} />
                  <div className="col-lg-12 inventory_pagination">
                    <ul className="pagination margin-bottom-none margin-top-25 bottom_pagination">
                      <li className="disabled">
                        <a href="#" className="disabled">
                          <i className="fa fa-angle-left"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">1</a>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-angle-right"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* ----------------------------------------------------------------------------------------- */}
                {/* -------------------------------------------5--------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------- */}
                <div className="tab-pane fade" id="left-box-fullwidth">
                  <div className="car-listings-row row">
                    <div className="car_listings boxed boxed_left col-lg-9 col-md-12 margin-top-20">
                      {/* <div id="searchResult5_container"></div> */}
                      <SearchResult5 rentals={this.props.rentals} />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 order-lg-first left-sidebar">
                      <InventorySidebar />
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                {/* ----------------------------------------------------------------------------------------- */}
                {/* -------------------------------------------6--------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------- */}
                <div className="tab-pane fade" id="right-box-fullwidth">
                  <div className="car-listings-row row">
                    <div className="car_listings boxed boxed_right col-lg-9 col-md-12 margin-top-20">
                      {/* <div id="searchResult6_container"></div> */}
                      <SearchResult5 rentals={this.props.rentals} />
                    </div>
                    <div className="inventory-sidebar col-lg-3 col-md-12">
                      <InventorySidebar />
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix"></div>
        {/* <div id="youtube_video">
          <iframe
            width="560"
            height="315"
            src="#"
            allowFullScreen
            style={{ width: "560px", height: "315px", border: "0" }}
          ></iframe>
        </div> */}
      </div>
    );
  }
}

// export default Vehicles;
export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
