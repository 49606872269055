import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Avatar, { ConfigProvider } from "react-avatar";

class ChatContent extends Component {
  updateScrollbar = () => {
    const ctn = document.getElementById("chat-list");
    // console.log("----------->>> ctn:", ctn);
    setTimeout(() => {
      ctn.scrollTo(0, ctn.scrollHeight);
    }, 300);
  };
  componentDidUpdate() {
    // console.log("Updated................................");
    this.updateScrollbar();
  }

  render() {
    let chatDetails = this.props.chatDetails;
    // console.log("chatDetails :", chatDetails);
    if (!chatDetails) return null;
    return (
      <div>
        <div className="d-flex align-items-center p-3 border-bottom">
          <div className="mr-3">
            {chatDetails.thumb ? (
              <img
                src={chatDetails.thumb}
                alt="user"
                className="rounded-circle"
                width="50"
              />
            ) : (
              <span>
                <ConfigProvider
                  colors={["red", "green", "blue", "purple", "orange"]}
                >
                  <Avatar name={chatDetails.name} size={40} round={true} />
                </ConfigProvider>
              </span>
            )}
          </div>
          <div>
            <h5 className="message-title mb-0">{chatDetails.name}</h5>
            <p className="mb-0">{chatDetails.status}</p>
          </div>
          <div className="ml-auto">
            <i className="fas fa-ellipsis-v"></i>
          </div>
        </div>

        <PerfectScrollbar
          containerRef={(ref) => {
            this.chatScrollHandler = ref;
          }}
        >
          <ul
            id="chat-list"
            className="chat-list p-4"
            style={{ height: "calc(100vh - 334px)" }}
          >
            {chatDetails.chatHistory.map((chat, i) => {
              // console.log("chat: ", chat);
              return Object.keys(chat).map((k) => {
                // console.log(
                //   "chat: ",
                //   chat,
                //   " k: ",
                //   k,
                //   " -> ",
                //   chatDetails.chatHistory[i][k]
                // );
                return k === "from" ? (
                  <li className="chat-item d-flex" key={k}>
                    <div className="chat-img">
                      {chatDetails.thumb ? (
                        <img src={chatDetails.thumb} alt={chatDetails.name} />
                      ) : (
                        <span>
                          <ConfigProvider
                            colors={[
                              "red",
                              "green",
                              "blue",
                              "purple",
                              "orange",
                            ]}
                          >
                            <Avatar
                              name={chatDetails.name}
                              size={40}
                              round={true}
                            />
                          </ConfigProvider>
                        </span>
                      )}
                    </div>
                    <div className="pl-3">
                      {chatDetails.chatHistory[i][k].map((v, i) => (
                        <div className="chat-content" key={v + i}>
                          <div className="box bg-light-info">{v}</div>
                        </div>
                      ))}
                    </div>
                  </li>
                ) : (
                  <li className="chat-item odd" key={k}>
                    {chatDetails.chatHistory[i][k].map((v, i) => (
                      <div className="chat-content" key={v + i}>
                        <div className="box bg-light-info">{v}</div>
                      </div>
                    ))}
                  </li>
                );
              });
            })}
            {/* {Object.keys(chatDetails.chatHistory).map((chat) =>
              Object.keys(chatDetails.chatHistory[chat]).map((key) =>
                Object.keys(chatDetails.chatHistory[chat][key]).map((k) =>
                  k === "from" ? (
                    <li className="chat-item d-flex" key={k}>
                      <div className="chat-img">
                        <img src={chatDetails.thumb} alt={chatDetails.name} />
                      </div>
                      <div className="pl-3">
                        {chatDetails.chatHistory[chat][key][k].map((v) => (
                          <div className="chat-content" key={v}>
                            <div className="box bg-light-info">{v}</div>
                          </div>
                        ))}
                      </div>
                    </li>
                  ) : (
                    <li className="chat-item odd" key={k}>
                      {chatDetails.chatHistory[chat][key][k].map((v) => (
                        <div className="chat-content" key={v}>
                          <div className="box bg-light-info">{v}</div>
                        </div>
                      ))}
                    </li>
                  )
                )
              )
            )} */}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default ChatContent;

ChatContent.propTypes = {
  chatDetails: PropTypes.object.isRequired,
};
